import React from 'react';
import { progressBarPanel } from './Card.stylesheet';
import ProgressRing from '@hig/progress-ring';

export const CardDataLoadingProgress: React.FC = () => {
  return (
    <div style={progressBarPanel}>
      <ProgressRing size="s" />
    </div>
  );
};
