import { IndustryStandardValue } from "./metrics";


export const BASE_RUN_ID = "baseRun";
export const NOT_COMPUTED_RUN_ID = 'NOT_COMPUTED';
export const BASE_RUN_FACTOR_NAME = 'BIM Base Run';

export type AnalysisRun = {
  id: number;
  isBaseRun: boolean;
  info: Info;
  inputs: JobParameter[];
}

export type JobParameter = {
  type: string;
  industryStandardValue?: IndustryStandardValue;
  imperialStandardValue?: IndustryStandardValue;
  parameterId: string;
};

export type Info = {
  jobId: string;
  name: string;
  start: string;
  end: string;
  success: boolean;
  engineVersion: string;
}
