import React from 'react'

const FactorsIcon = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>((props, ref) => (
  <svg data-testid='factors-icon' { ...props } ref={ref} style={{ flexShrink: 0, cursor: 'default' }} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_2064_14113)">
      <rect x="7" width="2" height="2" fill="#178FE6"/>
      <rect x="7" y="14" width="2" height="2" fill="#178FE6"/>
      <rect y="3" width="2" height="2" fill="#178FE6"/>
      <rect y="11" width="2" height="2" fill="#178FE6"/>
      <rect x="14" y="3" width="2" height="2" fill="#178FE6"/>
      <rect x="14" y="11" width="2" height="2" fill="#178FE6"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M12 5.28571L8 3L4 5.28571L8 7.57143L12 5.28571Z" fill="#F3F3F3"/>
      <path d="M8 12L4 9.65986V5.28564L8 7.57136V12Z" fill="#D9D9D9"/>
      <path d="M8 12L12 9.65986V5.28564L8 7.57136V12Z" fill="#BBBBBB"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M8 4.15175L5.00005 5.86601L5.00001 9.08634L8 10.8414L11 9.08634L10.9999 5.86601L8 4.15175ZM11.9999 5.28568L8 3L4.00006 5.28568L4 9.65986L8 12L12 9.65986L11.9999 5.28568Z" fill="#666666"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M6 1.14282L3 2.8571V4.00884L6 2.29457V1.14282ZM10 2.29457V1.14283L13 2.8571V4.00885L10 2.29457ZM14 5.99995V9.99993H15V5.99995H14ZM13 13.0747V11.9162L10 13.6713V14.8298L13 13.0747ZM6 14.8298V13.6713L3 11.9162V13.0747L6 14.8298ZM1 9.99993H2V5.99995H1V9.99993Z" fill="#666666"/>
    </g>
    <defs>
      <clipPath id="clip0_2064_14113">
        <rect width="16" height="16" fill="white"/>
      </clipPath>
    </defs>
  </svg>
));

export default FactorsIcon;
