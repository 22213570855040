import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Typography, { typographyVariants } from '@weave-mui/typography';
import CardMedia, { cardMediaRatio } from '@weave-mui/card-media';
import Menulist from '@weave-mui/menu-list';
import MenuItem from '@weave-mui/menu-item';
import Button from '@weave-mui/button';
import Popper from '@weave-mui/popper';
import { popperPlacement } from '@weave-mui/enums';
import { CaretDownSUI, SyncM } from '@weave-mui/icons-weave';
import { useDispatch } from 'react-redux';
import { loadEcPage } from '../analysis/EmbodiedCarbon/actions';
import { setCurrentModelId } from '../state/slice/application-data-slice';
import { useCurrentThumbnailData } from '../layout/hooks/useCurrentThumbnailData';
import { useGetModelQuery, useGetProjectModelsQuery } from '../state/api/project-model-data-api';
import {
  cardMediaStyles,
  menuItemStyles,
  menuListStyles,
  modelSubTitleStyles,
  modelTitleStyles,
} from './DropdownModelSelector.stylesheet';
import Tooltip, { tooltipPlacement, tooltipSize } from '@weave-mui/tooltip';
import Box from '@weave-mui/box';
import useBreakpoints from '../shared/BreakPoints/useBreakPoints';
import { BASE_RUN_ID } from '../types/jobs';
import { useSetAnalysisRunV2 } from '../layout/hooks/v2/useAnalysisRunV2';

const DropdownModelSelector: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const setCurrentAnalysisRun = useSetAnalysisRunV2();
  const { modelId } = useParams<{ modelId: string }>();
  const { projectId } = useParams<{ projectId: string }>();
  const { data: model } = useGetModelQuery(modelId, { skip: !modelId });
  const { data: projectModels } = useGetProjectModelsQuery(projectId, { skip: !projectId });
  const anchorElRef = useRef(null);
  const selectedItemRef = useRef<HTMLLIElement>(null);

  const [tooltipEnabledIndex, setTooltipEnabledIndex] = useState(null);
  const { isXs, isSm, isMd, isLg, isXxs, isXxxs } = useBreakpoints();

  const showToolTip = (e: any, index) => {
    if (e.target.scrollWidth > e.target.clientWidth) {
      setTooltipEnabledIndex(index);
    }
  };

  const goToFlexibleLayout = (id: string) => {
    setIsOpen(false);
    dispatch(setCurrentModelId(id));
    setCurrentAnalysisRun(BASE_RUN_ID); // default to base run on fresh model selection
    dispatch(loadEcPage(id, projectId));
    navigate(`/projects/${projectId}/model/${id}/layouts`);
  };

  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen((prev) => !prev);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen && selectedItemRef.current) {
      selectedItemRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  }, [isOpen]);

  return (
    <>
      <Button
        onClick={handleClick}
        ref={anchorElRef}
        aria-haspopup="true"
        aria-expanded={isOpen && 'true'}
        variant="text"
        sx={{
          color: 'rgba(0, 0, 0, 0.6)',
          fontWeight: 'none',
          padding: '0',
          '& svg': {
            marginLeft: '7px',
            fill: '#808080 !important',
            transform: isOpen ? 'rotate(180deg)' : 'none',
          },
          '& p': {
            fontWeight: 'bold',
          },
          '&:hover': {
            boxShadow: 'none',
          },
        }}
      >
        <Tooltip
          title={''}
          description={
            <Box>
              <div style={{ marginTop: '1px' }}></div>
              <Typography variant="caption">{model?.name}</Typography>
            </Box>
          }
          arrow
          placement={tooltipPlacement.BOTTOM}
          size={tooltipSize.MEDIUM}
          enterDelay={500}
          open={tooltipEnabledIndex === -1}
          onClose={() => setTooltipEnabledIndex(null)}
        >
          <Typography
            onMouseEnter={(e) => showToolTip(e, -1)}
            sx={{
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              display: 'inline-block !important',
              maxWidth: isLg
                ? '260px'
                : isMd
                ? '210px'
                : isSm
                ? '145px'
                : isXs
                ? '260px'
                : isXxs
                ? '140px'
                : isXxxs
                ? '40px'
                : '40px',
              float: 'left',
            }}
          >
            {model?.name}
          </Typography>
        </Tooltip>
        <CaretDownSUI />
      </Button>
      <Popper
        arrow={true}
        open={isOpen}
        onClose={handleClose}
        placement={popperPlacement.BOTTOM_END}
        disablePortal
        anchorEl={anchorElRef.current}
      >
        <Menulist sx={menuListStyles}>
          {projectModels?.map((item, index) => (
            <MenuItem
              sx={menuItemStyles}
              asset={<CustomMenuItem key={1} urn={item?.urn} lmvStatus={item?.lmvStatus} />}
              selected={item?.id === modelId}
              onClick={(e) => goToFlexibleLayout(item?.id)}
              ref={item?.id === modelId ? selectedItemRef : null}
            >
              <Tooltip
                title={''}
                description={
                  <Box>
                    <div style={{ marginTop: '1px' }}></div>
                    <Typography variant="caption">{item?.name}</Typography>
                  </Box>
                }
                arrow
                placement={tooltipPlacement.BOTTOM}
                size={tooltipSize.MEDIUM}
                enterDelay={500}
                open={tooltipEnabledIndex === index}
                onClose={() => setTooltipEnabledIndex(null)}
              >
                <Typography
                  density={'high'}
                  align={'left'}
                  variant={typographyVariants.BODY_BOLD}
                  sx={modelTitleStyles}
                  onMouseEnter={(e) => showToolTip(e, index)}
                >
                  {item?.name}
                </Typography>
              </Tooltip>
              <Typography
                align={'left'}
                variant={typographyVariants.CAPTION_REGULAR}
                sx={modelSubTitleStyles}
              >
                {item?.createdAt}
              </Typography>
            </MenuItem>
          ))}
        </Menulist>
      </Popper>
    </>
  );
};

export default DropdownModelSelector;

const CustomMenuItem: React.FC<{ urn: string; lmvStatus: string }> = (props) => {
  const { urn, lmvStatus } = props;
  const { data: thumbnail, isLoading, isSuccess } = useCurrentThumbnailData(urn, lmvStatus);
  const imgObject = thumbnail?.base64;

  return imgObject && !isLoading && isSuccess ? (
    <CardMedia sx={cardMediaStyles} radio={cardMediaRatio.RATIO3_2} image={imgObject} />
  ) : (
    <SyncM sx={{ ...cardMediaStyles }} />
  );
};
