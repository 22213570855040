import thunkMiddleware from 'redux-thunk';
import modelReducer from '../model/reducer';
import { notificationReducer, currencyReducer } from '../shared/reducers';
import runsReducer from '../run/reducer';
import embodiedCarbonReducer from '../analysis/EmbodiedCarbon/reducerEcPage';
import dashboardReducer from '../analysis/reducer';
import { crashReporter } from '../shared/application/middlewares/errorHandlers.middlewares';
import ecDefinitionsReducer from '../analysis/EmbodiedCarbon/reducer';
import { applicationReducer } from '../shared/application/reducer';
import { notificationsMiddleware } from '../shared/application/middlewares/notifications.middleware';

// Redux toolkit
import {MiddlewareArray, configureStore, StateFromReducersMapObject} from '@reduxjs/toolkit';
import { userDataApi } from './api/user-data-api';
import { featureFlagSlice } from './temp-reducers';
import { featureFlagDataApi } from './api/feature-flag-data-api';
import { currencyDataApi } from './api/currency-data-api';
import { forgeUnitsDataApi } from './api/forge-units-data-api';
import { projectModelDataApi } from './api/project-model-data-api';
import { thumbnailDataApi } from './api/thumbnail-data-api';
import { viewerTokenDataApi } from '../state/api/viewer-token-data-api';
import {dataPointsServiceApi, dataServiceFactorsApi, dataServiceUnitsApi, dataServiceBenchmarksApi, dataServiceAdvancedFactorsApi} from './api/data-service-api';
import { taskServiceApi } from './api/task-service-api';
import { simulationDataApi } from './api/simulation-data-api';
import { adpWebSdkApi } from './api/adp-web-sdk-api';
import { analyticsMiddleware } from './api/analytics-middleware';

//Slice
import { userSlice } from './slice/user-slice';
import { applicationDataSlice } from './slice/application-data-slice';
import { embodiedCarbonDataServiceApi } from './api/ec-data-api';
import { dashboardServiceApi } from './api/dashboard-api';
import { factorDataSlice } from './slice/factor-data-slice';
import { auth0Slice } from './slice/auth0-data-slice';
import { errorNotificationsMiddleware } from './api/error-notifications-middleware';

import { notificationSlice } from './slice/notifications-slice';
import {ToolkitStore} from "@reduxjs/toolkit/dist/configureStore";
import { reportingDataApi } from './api/reporting-data-api';
import { locationDataApi } from './api/location-data-api';
const reducer = {
  [userDataApi.reducerPath]: userDataApi.reducer,
  [featureFlagDataApi.reducerPath]: featureFlagDataApi.reducer,
  [currencyDataApi.reducerPath]: currencyDataApi.reducer,
  [forgeUnitsDataApi.reducerPath]: forgeUnitsDataApi.reducer,
  [adpWebSdkApi.reducerPath]: adpWebSdkApi.reducer,
  [projectModelDataApi.reducerPath]: projectModelDataApi.reducer,
  [thumbnailDataApi.reducerPath]: thumbnailDataApi.reducer,
  [viewerTokenDataApi.reducerPath]: viewerTokenDataApi.reducer,
  [simulationDataApi.reducerPath]: simulationDataApi.reducer,
  [dataPointsServiceApi.reducerPath]: dataPointsServiceApi.reducer,
  [dataServiceUnitsApi.reducerPath]: dataServiceUnitsApi.reducer,
  [dataServiceFactorsApi.reducerPath]: dataServiceFactorsApi.reducer,
  [dataServiceBenchmarksApi.reducerPath]: dataServiceBenchmarksApi.reducer,
  [dataServiceAdvancedFactorsApi.reducerPath]: dataServiceAdvancedFactorsApi.reducer,
  [taskServiceApi.reducerPath]: taskServiceApi.reducer,
  [embodiedCarbonDataServiceApi.reducerPath]: embodiedCarbonDataServiceApi.reducer,
  [dashboardServiceApi.reducerPath]: dashboardServiceApi.reducer,
  [reportingDataApi.reducerPath]: reportingDataApi.reducer,
  [locationDataApi.reducerPath]: locationDataApi.reducer,
  userState: userSlice.reducer,
  auth0State: auth0Slice.reducer,
  featureFlagsState: featureFlagSlice.reducer,
  applicationDataState: applicationDataSlice.reducer,
  factorDataState: factorDataSlice.reducer,
  modelState: modelReducer,
  runState: runsReducer,
  notificationState: notificationReducer,
  dashboardState: dashboardReducer,
  embodiedCarbonState: embodiedCarbonReducer,
  currencyState: currencyReducer,
  ecDefinitionsState: ecDefinitionsReducer,
  applicationState: applicationReducer, // todo - can we remove or refactor this?
  notifications: notificationSlice.reducer,
};

export const setupStore = (preloadedState?: Partial<any>): ToolkitStore  => {
  return configureStore({
    reducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: new MiddlewareArray().concat(
      crashReporter,
      notificationsMiddleware,
      thunkMiddleware,
      userDataApi.middleware,
      featureFlagDataApi.middleware,
      currencyDataApi.middleware,
      forgeUnitsDataApi.middleware,
      adpWebSdkApi.middleware,
      projectModelDataApi.middleware,
      thumbnailDataApi.middleware,
      viewerTokenDataApi.middleware,
      simulationDataApi.middleware,
      dataPointsServiceApi.middleware,
      dataServiceUnitsApi.middleware,
      dataServiceFactorsApi.middleware,
      dataServiceAdvancedFactorsApi.middleware,
      dataServiceBenchmarksApi.middleware,
      taskServiceApi.middleware,
      embodiedCarbonDataServiceApi.middleware,
      dashboardServiceApi.middleware,
      reportingDataApi.middleware,
      locationDataApi.middleware,
      errorNotificationsMiddleware,
      analyticsMiddleware,
    ),
    preloadedState: preloadedState,
  });
}

export type RootState = StateFromReducersMapObject<typeof reducer>;
type Store = ReturnType<typeof setupStore>;
export type AppDispatch = Store['dispatch'];
