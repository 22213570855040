import { Dashboard } from '../types/layout';
import store from '../state/init-store';
import { RootState } from '../state/store';
import { isDataPointGlobal } from './dashboard';
import { CreateBenchmarkRequest } from '../types/requests/benchmarks';
import {
  DataPoints,
  DataPointsDefinitionsResult,
  FactorDefinition,
  MetricDefinition,
} from '../types/metrics';

/**
 * Counts the number of cards in a dashboard based on their types.
 * @param data - The dashboard object containing the cards and other information.
 * @returns An object with the count of each card type in the dashboard.
 */
export function countCardsInDashboard(data: Dashboard) {
  const cardTypes = ['factor', 'modelView', 'freeEntryText', 'metric', 'dataDisplay'];

  const result = {
    dashboardId: data.dashboardId,
    dashboardName: data.name,
    totalNumberOfCards: data.cards.length.toString(),
  };

  cardTypes.forEach((type) => {
    const cardCount = data.cards.filter((card) => card.data.type === type).length;
    if (cardCount > 0) {
      result[`numberOf${type.charAt(0).toUpperCase() + type.slice(1)}Cards`] = cardCount.toString();
    }
    countMetricsAndFactors(data, result, type);
  });
  return result as Record<string, string>;
}

const countMetricsAndFactors = (data: Dashboard, result: Record<string, string>, type: string) => {
  let factorsData: FactorDefinition[] = [];
  let metricsData: MetricDefinition[] = [];
  const state = store.getState() as RootState;
  const modelId = state.applicationDataState.currentModelId;
  const datapointState = state.dataPointsApi;
  const queries = datapointState.queries;
  const getDataPointsQuery =
    queries[`getDataPoints("${modelId}")`] || queries[`getDataPointsDefinitions(undefined)`];
  if (getDataPointsQuery?.status === 'fulfilled') {
    factorsData =
      (getDataPointsQuery.data as DataPoints)?.factorsData ||
      (getDataPointsQuery.data as DataPointsDefinitionsResult)?.definitions.factors;
    metricsData =
      (getDataPointsQuery.data as DataPoints)?.metricsData ||
      (getDataPointsQuery.data as DataPointsDefinitionsResult)?.definitions.metrics;
  }

  let countDefault: number = 0;
  if (type === 'factor') {
    const factorCards = data.cards.filter((card) => card.data.type === type);
    factorCards.forEach((card) => {
      if (isDataPointGlobal(card.data.parameterId, factorsData)) {
        countDefault++;
      }
    });
    result[`numberOf${type.charAt(0).toUpperCase() + type.slice(1)}DefaultCards`] =
      countDefault.toString();
    result[`numberOf${type.charAt(0).toUpperCase() + type.slice(1)}CustomCards`] = (
      factorCards.length - countDefault
    ).toString();
  }
  if (type === 'metric') {
    const metricCards = data.cards.filter((card) => card.data.type === type);
    metricCards.forEach((card) => {
      if (isDataPointGlobal(card.data.parameterId, metricsData)) {
        countDefault++;
      }
    });
    result[`numberOf${type.charAt(0).toUpperCase() + type.slice(1)}DefaultCards`] =
      countDefault.toString();
    result[`numberOf${type.charAt(0).toUpperCase() + type.slice(1)}CustomCards`] = (
      metricCards.length - countDefault
    ).toString();
  }
};

export const benchmarkAnalytic = (benchmark: CreateBenchmarkRequest): Record<string, string> => {
  const state = store.getState() as RootState;
  let metricsData: MetricDefinition[] = [];
  if (!state) {
    return {};
  }
  const modelId = state.applicationDataState.currentModelId;
  const datapointState = state.dataPointsApi;
  const queries = datapointState.queries;
  const getDataPointsQuery =
    queries[`getDataPoints("${modelId}")`] || queries[`getDataPointsDefinitions(undefined)`];
  if (getDataPointsQuery?.status !== 'fulfilled') {
    return {};
  }
  metricsData =
    (getDataPointsQuery.data as DataPoints)?.metricsData ||
    (getDataPointsQuery.data as DataPointsDefinitionsResult)?.definitions.metrics;
  const result = {
    benchmarkValue: benchmark.value.toString(),
  };
  let numberOfDefaultMetrics: number = 0;
  benchmark.metrics.forEach((metric) => {
    if (isDataPointGlobal(metric, metricsData)) {
      numberOfDefaultMetrics++;
    }
  });
  result['numberOfDefaultMetrics'] = numberOfDefaultMetrics.toString();
  result['numberOfCustomMetrics'] = (benchmark.metrics.length - numberOfDefaultMetrics).toString();

  return result as Record<string, string>;
};
