import React, { useCallback, useEffect, useMemo, useState } from 'react';
import CategoryTreeItem from './components/CategoryTreeItem';
import TreeView, { indicatorTreeView } from '@weave-mui/tree-view';
import Box from '@weave-mui/box';
import Search from '../Search';
import { SxProps, Theme } from '@mui/material';
import { ActiveCategory, TreeViewDataPoints } from './dataPointsTreeView.types';
import { DataPoints } from '../../types/metrics';
import { filterDataPoints, generateActiveDataPoints } from './treeViewUtils';
import {useIsV2APIEnabled} from "../application/application-hooks";

interface DataPointsTreeViewProps {
  dataPoints: DataPoints;
  isReadOnly?: boolean;
  activeCategories?: ActiveCategory[];
  selectedDataPointId?: string;
  onClick: (paramId: string) => void;
  customStyle?: SxProps<Theme>;
  displayTextDataType?: boolean;
  disabledDataPointIds?: string[];
}

const DataPointsTreeView: React.FC<DataPointsTreeViewProps> = ({
  dataPoints,
  onClick,
  selectedDataPointId,
  activeCategories = [],
  customStyle = {},
  isReadOnly = false,
  displayTextDataType = false,
  disabledDataPointIds = [],
}) => {
  const {data: isV2Enabled} = useIsV2APIEnabled();
  const activeDataPoints = useMemo(() => generateActiveDataPoints(dataPoints, activeCategories, isV2Enabled), [dataPoints, isV2Enabled]);
  const [filteredDataPoints, setFilteredDataPoints] =
    useState<TreeViewDataPoints>(activeDataPoints);
  const [selectedId, setSelectedId] = useState<string>(selectedDataPointId ?? '');
  const [searchQuery, setSearchQuery] = useState<string>('');
  const defaultExpandedNodes = Object.keys(activeDataPoints).map((_, index) => `root-${index}`);

  useEffect(() => {
    const filteredItems = Object.keys(activeDataPoints).reduce((acc, key) => {
      return {
        ...acc,
        [key]: filterDataPoints(activeDataPoints[key], searchQuery, displayTextDataType),
      };
    }, {} as TreeViewDataPoints);
    setFilteredDataPoints(filteredItems);
  }, [searchQuery, activeDataPoints]);

  const onSearchCb = useCallback((query) => {
    setSearchQuery(query);
  }, []);

  const onTreeNodeClickCb = useCallback(
    (_, nodeId: string) => {
      if (isReadOnly) return;

      if (nodeId.includes('root')) {
        setSelectedId('');
        return;
      }

      onClick(nodeId);
      setSelectedId(nodeId);
    },
    [onClick, isReadOnly]
  );

  return (
      <Box
        sx={{
          height: 'calc(100%)',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden'
        }}
      >
        <Search onSearch={onSearchCb} searchValue="" isReadOnly={isReadOnly} />
        <Box
          sx={{
            height: '100%',
            overflowY: 'auto',
            overflowX: 'hidden'
          }}
        >
          <TreeView
            multiSelect={false}
            indicator={indicatorTreeView.OPERATOR}
            onNodeSelect={onTreeNodeClickCb}
            selected={selectedId}
            defaultExpanded={defaultExpandedNodes}
            sx={{ ...customStyle }}
          >
            {Object.entries(filteredDataPoints).map(
              ([category, dataPoints], index) =>
                dataPoints.length > 0 && (
                  <CategoryTreeItem
                    key={index}
                    index={index}
                    category={category as keyof TreeViewDataPoints}
                    dataPoints={dataPoints}
                    selectedId={selectedId}
                    isReadOnly={isReadOnly}
                    disabledDataPointIds={disabledDataPointIds}
                  />
                )
            )}
          </TreeView>
        </Box>
      </Box>
  );
};

export default DataPointsTreeView;
