import React, { useState } from 'react';
import { ActionMenu } from '../common/ActionMenu';
import { ActionMenuItem } from '../common/common.models';
import { TextEditor } from '../common/TextEditor';
import { Modal } from '../common/Modal';
import ProjectSelector from '../project/ProjectSelector';
import ProgressRing from '@hig/progress-ring';
import { Model } from '../analysis/dashboard.models';
import i18n from '../i18n';
import * as locConsts from './localization/consts';
import {
  useUpdateModelMutation,
  useDeleteModelMutation,
} from '../state/api/project-model-data-api';
import { useGetUserFeatureFlagDataQuery } from '../state/api/feature-flag-data-api';
import { useCurrentThumbnailData } from '../layout/hooks/useCurrentThumbnailData';
import { ReportEntryPoint } from '../reporting/components/ReportEntryPoint';
import { useDispatch } from 'react-redux';
import { setCurrentModelId } from '../state/slice/application-data-slice';
import { BASE_RUN_ID } from '../types/jobs';
import { useSetAnalysisRunV2 } from '../layout/hooks/v2/useAnalysisRunV2';

export type modelWidgetProps = {
  model: Model;
  onModelClick?: Function;
  onAnalysisSelected?: Function;
  modelMenuId?: number;
  currentProjectId: string;
};

const ModelWidget: React.FC<modelWidgetProps> = (props) => {
  const dispatch = useDispatch();
  const setCurrentAnalysisRun = useSetAnalysisRunV2();
  const isChangeNameVisible = useState(false);
  const isDialogVisible = useState(false);
  const isProjectsModalVisible = React.useState(false);
  const { name, id, createdAt, lmvStatus, urn } = props.model;
  const dateAddedString = new Date(createdAt).toLocaleDateString();
  const displayText = name + ' - ' + dateAddedString;
  const [loadReportingData, setLoadReportingData] = useState(false);

  //RTK
  const { data: featureFlagData } = useGetUserFeatureFlagDataQuery();
  const [deleteModelMutation] = useDeleteModelMutation();
  const [updateModelMutation] = useUpdateModelMutation();
  const { data: thumbnail } = useCurrentThumbnailData(urn, lmvStatus);
  const imgObject = thumbnail?.base64;

  const showChangeName = () => {
    isChangeNameVisible[1](true);
  };

  const showDialog = () => {
    isDialogVisible[1](!isDialogVisible[0]);
  };

  const showProjectsModal = () => {
    isProjectsModalVisible[1](!isProjectsModalVisible[0]);
  };

  const updateModelTitle = (newValue: string) => {
    const { id, projectId } = props.model;
    const name = newValue;
    updateModelMutation({ id, projectId, name });
    isChangeNameVisible[1](false);
  };

  const deleteModel = (id: string) => {
    deleteModelMutation(id);
    isDialogVisible[1](false);
  };

  const goToAnalysis = () => {
    if (props.onAnalysisSelected) {
      props.onAnalysisSelected(props.model);
    }
  };

  const getMenuItems = () => {
    let items: ActionMenuItem[] = [
      {
        key: 'edit',
        icon: 'icon-edit',
        text: i18n.t(locConsts.MODEL_WIDGET_RENAME),
        action: showChangeName,
      },
      {
        key: 'move',
        icon: 'icon-move-to-folder',
        text: i18n.t(locConsts.MODEL_WIDGET_MOVE),
        action: showProjectsModal,
      },
      {
        key: 'delete',
        icon: 'icon-trash',
        text: i18n.t(locConsts.MODEL_WIDGET_DELETE),
        action: showDialog,
      },
    ];

    if (featureFlagData['enable-aia-ddx-reporting']) {
      items.push({
        key: 'report',
        icon: 'icon-share-content',
        text: i18n.t(locConsts.MODEL_WIDGET_REPORT),
        action: () => {
          dispatch(setCurrentModelId(id));
          setCurrentAnalysisRun(BASE_RUN_ID);
          setLoadReportingData(true);
        },
      });
    }

    if (featureFlagData['display-energy-workflow']) {
      items.push({
        key: 'analysis',
        icon: 'icon-thumbnail-view',
        text: i18n.t(locConsts.MODEL_WIDGET_ANALYSIS),
        action: goToAnalysis,
      });
    }

    return items;
  };

  const onImageClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    props.onModelClick(props.model);
  };

  return (
    <div
      className="col-xs-4 col-sm-4 col-md-3 col-lg-2 widget design-option-widget"
      data-tooltip-message=""
      key={props.model.key}
    >
      <div className="design-option-thumbnail">
        <a onClick={onImageClick} href="#">
          {imgObject ? (
            <img src={imgObject} alt="" />
          ) : (
            <div className="design-option-thumbnail-progress-ring ">
              <ProgressRing />
            </div>
          )}
        </a>
      </div>

      {isChangeNameVisible[0] ? (
        <TextEditor value={name} updateValue={updateModelTitle} />
      ) : (
        <p className="name" title={displayText}>
          {displayText}
        </p>
      )}
      {props.modelMenuId && <ActionMenu menuId={props.modelMenuId} items={getMenuItems()} />}
      <div className="loader-wrapper" />
      <svg className="selected-overlay" />
      {isDialogVisible[0] && (
        <Modal
          isDialog={true}
          hideModal={showDialog}
          title={i18n.t(locConsts.MODEL_WIDGET_MODAL_DELETE)}
          subTitle={i18n.t(locConsts.MODEL_WIDGET_MODAL_DESC)}
          name={name}
          buttonText="Delete"
          buttonAction={() => deleteModel(id)}
        />
      )}
      {isProjectsModalVisible[0] && (
        <Modal hideModal={showProjectsModal}>
          <ProjectSelector
            hideModal={showProjectsModal}
            model={props.model}
            currentProjectId={props.currentProjectId}
          />
        </Modal>
      )}
      {loadReportingData && (
        <ReportEntryPoint
          modelId={id}
          variant={'AIA_DDX'}
          onClose={() => setLoadReportingData(false)}
        />
      )}
    </div>
  );
};

export default ModelWidget;
