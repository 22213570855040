import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { BASE_RUN_ID } from '../../types/jobs';
import { AdvancedFactorCurrentValue } from '../../types/metrics';

export interface ApplicationDataState {
  currentModelId: string;
  currentProjectId: string;
  currentScenarioId: string;
  currentAnalysisRunId: string;
  currentSelectedAdvancedFactors: {
    [advancedfactorId: string]: AdvancedFactorCurrentValue;
  };
}

const initialState: ApplicationDataState = {
  currentModelId: null,
  currentProjectId: null,
  currentScenarioId: null,
  currentAnalysisRunId: BASE_RUN_ID,
  currentSelectedAdvancedFactors: {},
};

export const applicationDataSlice = createSlice({
  name: 'applicationDataState',
  initialState,
  reducers: {
    setCurrentModelId(state, action: PayloadAction<string>) {
      state.currentModelId = action.payload;
      state.currentScenarioId = null;
      state.currentSelectedAdvancedFactors = {};
    },
    setCurrentProjectId(state, action: PayloadAction<string>) {
      state.currentProjectId = action.payload;
      state.currentScenarioId = null;
    },
    setCurrentScenarioId(state, action: PayloadAction<string>) {
      state.currentScenarioId = action.payload;
    },
    setCurrentAnalysisRunId(state, action: PayloadAction<string>) {
      state.currentAnalysisRunId = action.payload;
    },
    setCurrentSelectedAdvancedFactorValue(
      state,
      action: PayloadAction<{
        [advancedfactorId: string]: AdvancedFactorCurrentValue;
      }>
    ) {
      state.currentSelectedAdvancedFactors = {
        ...state.currentSelectedAdvancedFactors,
        ...action.payload,
      };
    },
    clearAdvancedFactorValue(state, action: PayloadAction<string>) {
      if (state.currentSelectedAdvancedFactors[action.payload]) {
        const { [action.payload]: _, ...rest } = state.currentSelectedAdvancedFactors;
        state.currentSelectedAdvancedFactors = rest;
      }
    },
    clearAdvancedFactorValues(state) {
      state.currentSelectedAdvancedFactors = {};
    },
  },
});

export const {
  setCurrentModelId,
  setCurrentProjectId,
  setCurrentScenarioId,
  setCurrentAnalysisRunId,
  setCurrentSelectedAdvancedFactorValue,
  clearAdvancedFactorValues,
  clearAdvancedFactorValue
} = applicationDataSlice.actions;
