import { AnalysisResult, DataPoints, DataPointsResult, FactorValue } from "../../types/metrics";
import { getDataPointResultFromDefinition } from "../utils/dataPointsUtils";


export const toVersionedDataPoints = (data: DataPointsResult, selectedAnalysisRun: string): DataPoints => {
  return {
    benchmarksData: data.definitions.benchmarks,
    metricsData: data.definitions.metrics,
    advancedFactorsData: data.definitions.advancedFactors.map(af => {
      return {
        ...af,
        dataPointValue: {
          values:  getDataPointResultFromDefinition(af, data, selectedAnalysisRun) as FactorValue[]
        }
      }
    }),
    factorsData: data.definitions.factors.map(f => {
      return {
        ...f,
        dataPointValue: {
          values: getDataPointResultFromDefinition(f, data, selectedAnalysisRun) as FactorValue[]
        }
      }
    }),
    simulationFactorsData: data.definitions.simulationFactors.map(sF => {
      return {
        ...sF,
        dataPointValue: {
          values: data.factorsData[sF.id]
        }
      }
    }),
    ecAnalysisData: data.definitions.ecAnalysis.map(ecA => {
      return {
        ...ecA,
        dataPointValue: getDataPointResultFromDefinition(ecA, data, selectedAnalysisRun) as AnalysisResult
      }
    }),
    energyAnalysisData: data.definitions.energyAnalysis.map(eA => {
      return {
        ...eA,
        dataPointValue: getDataPointResultFromDefinition(eA, data, selectedAnalysisRun) as AnalysisResult
      }
    }),
    modelData: data.definitions.model.map(m => {
      return {
        ...m,
        dataPointValue: getDataPointResultFromDefinition(m, data, selectedAnalysisRun) as AnalysisResult
      }
    })
  };
};
