import React from 'react'

const MetricsIcon = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>((props, ref) => (
  <svg data-testid='metrics-icon' { ...props } ref={ref} style={{ flexShrink: 0, cursor: 'default' }} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_2116_958)">
      <rect y="6" width="3" height="5" fill="#666666"/>
      <rect x="12" y="1" width="3" height="10" fill="#666666"/>
      <rect y="12" width="16" height="1" fill="#666666"/>
      <rect x="1" y="13" width="1" height="2" fill="#666666"/>
      <rect x="7" y="13" width="1" height="2" fill="#666666"/>
      <rect x="4" y="13" width="1" height="2" fill="#666666"/>
      <rect x="10" y="13" width="1" height="2" fill="#666666"/>
      <rect x="13" y="13" width="1" height="2" fill="#666666"/>
      <rect x="8" y="7" width="3" height="4" fill="#666666"/>
      <rect x="4" y="4" width="3" height="7" fill="#666666"/>
    </g>
    <defs>
      <clipPath id="clip0_2116_958">
        <rect width="16" height="16" fill="white"/>
      </clipPath>
    </defs>
  </svg>
));

export default MetricsIcon;
