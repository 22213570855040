import MenuList from '@weave-mui/menu-list';
import React, { useMemo } from 'react';
import TooltipMenuItem from './TooltipMenuItem';
import { ParametersTypesEnum } from '../../../../types/metrics';
import navLinks, { NavigationOption } from '../../utils/sideNavLinks';
import DataPointsIcon from '../../../DataPointsIcons/components/DataPointsIcon';
import DataPointsIconsEnum from '../../../DataPointsIcons/utils/iconEnum';

interface SideMenuProps {
  collapsed: boolean;
  onOptionClick: (navOption:ParametersTypesEnum) => void;
}

export interface SideNavigationOption extends NavigationOption {
  icon: JSX.Element;
}

type DataPointsIconsMapType = Omit<Record<ParametersTypesEnum, JSX.Element>, ParametersTypesEnum.ADVANCED_FACTORS>;

const dataPointsIconMap: DataPointsIconsMapType = {
  factors: <DataPointsIcon icon={DataPointsIconsEnum.Factors}/>,
  metrics: <DataPointsIcon icon={DataPointsIconsEnum.Metrics}/>,
  benchmarks: <DataPointsIcon icon={DataPointsIconsEnum.Benchmarks}/>
};


const SideMenu: React.FC<SideMenuProps> = ({ collapsed, onOptionClick }) => {
  const sideNavLinks: SideNavigationOption[] = useMemo(() => {
    return navLinks.map(link => {
      return {
        ...link,
        icon: dataPointsIconMap[link.modalType]
      }
    })
  }, [navLinks]);

  return (
    <MenuList data-testid='side-nav-menu-list'>
      {
        sideNavLinks.map(link => {
          return <TooltipMenuItem collapsed={collapsed} navigationOption={link} onOptionClick={onOptionClick}/>
        })
      }
    </MenuList>
  )
};

export default SideMenu;
