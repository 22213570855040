import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import {
  AdvancedFactor,
  Benchmark,
  DataPoints,
  DataPointsResult,
  DataPointType,
  Factor,
  Metric,
  ParametersModalType,
  ParametersTypesEnum
} from '../../../../types/metrics';
import NotificationModal, { IModalProps } from '../../../../shared/NotificationModal';
import i18n from '../../../../i18n';
import { IconHeader } from '../../../../analysis/ModalPopup';
import { CardDataLoadingProgress } from '../../../../layout/cards/base/CardDataLoadingProgress';
import Box from '@weave-mui/box';
import '../../../../css/metrics.css';
import DataPointsListContainer from '../DataPointsList/DataPointsListContainer';
import DataPointsInformation from '../DataPointsInformation/DataPointsInformation';
import {
  areInputsValid,
  emptyBenchmark,
  getDataPointsbyType,
  getDefaultDataPoint,
  isReferencedMetric,
  newDataPointId
} from '../../../utils/dataPointsUtils';
import { useGetImperialSystem } from '../../../../layout/hooks/useCurrentProjectData';
import { useSelector } from 'react-redux';
import { ApplicationDataState } from '../../../../state/slice/application-data-slice';
import { useGetUnitsQuery } from '../../../../state/api/data-service-api';
import { DataPointsModalUnavailable } from '../../../../shared/Error/ErrorComponent';
import { hasDuplicates } from '../../../utils/factorsUtils';
import BenchmarksInformation from '../../benchmarks/BenchmarksInformation/BenchmarksInformation';
import { useDataPointsStrategy } from '../../../hooks/useDataPointsStrategy';
import { useGetUserFeatureFlagDataQuery } from '../../../../state/api/feature-flag-data-api';
import { FeatureFlags } from '../../../../utils/constants';
import { toVersionedDataPoints } from '../../../hooks/useModalDataPoints';
import { RootState } from '../../../../state/store';
import {useVersionedDataPoints} from "../../../../shared/application/application-hooks";

interface DataPointsModalProps {
  onCloseModal: () => void;
  modalType: ParametersModalType;
  dataPointChanges?: {
    modifiedDataPoint: boolean;
    setModifiedDataPoint: Dispatch<SetStateAction<boolean>>;
  };
}

const DataPointsModal: React.FC<DataPointsModalProps> = ({
  onCloseModal,
  modalType,
  dataPointChanges,
}) => {
  const currentModelId = useSelector(
    (state: { applicationDataState: ApplicationDataState }) =>
      state.applicationDataState.currentModelId
  );

  const {
    data: useImperial,
    isLoading: isLoadingUnitSystem,
    isError: isUnitSystemError,
  } = useGetImperialSystem();

  const {
    data: dataPoints,
    isLoading: isGetDataPointsLoading,
    isFetching: isGetDataPointsFetching,
    isError: isGetDataPointsError,
  } = useVersionedDataPoints();
  const { data: featureFlagData, isSuccess: featureFlagsSuccess } = useGetUserFeatureFlagDataQuery();

  const { data: _, isLoading: isLoadingUnits, isError: isUnitsError } = useGetUnitsQuery();
  const {strategy, isLoading: isDataPointsStrategyLoading} = useDataPointsStrategy(modalType, dataPoints);
  const [dataPointsToDisplay, setDataPointsToDisplay] = useState<(Metric | Factor | Benchmark)[]>([]);
  const [selectedDataPoint, setSelectedDataPoint] = useState<Metric | Factor | AdvancedFactor |Benchmark>();
  const [originalSelectedDataPoint, setOriginalSelectedDataPoint] = useState<Metric | Factor | AdvancedFactor| Benchmark>();
  const [updatedMetricFormula, setUpdatedMetricFormula] = useState<string>();
  const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState<boolean>(false);
  const [showNotificationModal, setShowNotificationModal] = useState<boolean>(false);
  const [isPropModified, setIsPropModified] = useState<boolean>(false);
  const [isReadOnly, setIsReadOnly] = useState<boolean>(true);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [shouldCleanFormula, setShouldCleanFormula] = useState<boolean>(false);
  const [modalProps, setModalProps] = useState<IModalProps>({
    onLinkClick: () => {},
    text: '',
    title: '',
    onClose: () => {},
  });

  const usedParametersInAdvancedFactors: string[] = useMemo(() => dataPoints?.advancedFactorsData?.map(af => af.simulationFactor) ?? [], [dataPoints]);

  const isSimulationFactorEnabled = featureFlagsSuccess ? featureFlagData?.[FeatureFlags.SimulationFactors] : false;
  const cleanCreateMetric = useCallback(
    () => {
      const defaultDataPoint = getDefaultDataPoint(modalType);
      setSelectedDataPoint(defaultDataPoint);
    }, []
  );

  useEffect(() => {
    const sortedDataPoints = getDataPointsbyType(dataPoints, modalType, isSimulationFactorEnabled);
    setDataPointsToDisplay(sortedDataPoints);
  }, [dataPoints, modalType, isSimulationFactorEnabled]);

  useEffect(() => {
    if (dataPointsToDisplay.length === 0) {
      setSelectedDataPoint(emptyBenchmark);
      setOriginalSelectedDataPoint(emptyBenchmark);
    }
  }, [dataPointsToDisplay]);

  useEffect(() => {
    return () => {
      setSelectedDataPoint(undefined);
      setOriginalSelectedDataPoint(undefined);
      setIsPropModified(false);
    };
  }, []);

  useEffect(() => {
    let readOnlyCheck = selectedDataPoint?.id !== newDataPointId;

    if (modalType !== ParametersTypesEnum.BENCHMARKS) {
      readOnlyCheck = readOnlyCheck || (selectedDataPoint as Metric | Factor)?.isGlobal;
    }
    setIsReadOnly(readOnlyCheck ?? false);
    setIsDirty(false);
  }, [selectedDataPoint?.id]);

  useEffect(() => {
    if (!selectedDataPoint?.id && dataPointsToDisplay.length > 0) {
      let firstDefault: Metric | Factor | Benchmark;
      switch (modalType) {
        case ParametersTypesEnum.BENCHMARKS:
          firstDefault = dataPointsToDisplay[0];
          break;
        case ParametersTypesEnum.METRICS:
        case ParametersTypesEnum.FACTORS:
          firstDefault = dataPointsToDisplay.filter((p) => (p as Metric | Factor).isGlobal)[0];
          break;
      }

      setSelectedDataPoint(firstDefault);
      setOriginalSelectedDataPoint(firstDefault);
    }

    const isEmptyBenchmarkList =
      modalType === ParametersTypesEnum.BENCHMARKS &&
      !selectedDataPoint &&
      dataPointsToDisplay.length === 0;

    if (isEmptyBenchmarkList) {
      setSelectedDataPoint(emptyBenchmark);
      setOriginalSelectedDataPoint(emptyBenchmark);
    }

  }, [dataPointsToDisplay, selectedDataPoint, modalType]);

  useEffect(() => {
    let allowSaveParameter = selectedDataPoint?.displayName?.trim().length > 0;
    switch (modalType) {
      case ParametersTypesEnum.METRICS:
        allowSaveParameter =
          allowSaveParameter &&
          (selectedDataPoint as Metric | Factor)?.dataType?.trim().length > 0 &&
          updatedMetricFormula?.trim().length > 0 &&
          (selectedDataPoint as Metric | Factor)?.unit?.trim().length > 0;
        break;
      case ParametersTypesEnum.FACTORS:
        allowSaveParameter =
          allowSaveParameter &&
          (selectedDataPoint as Metric | Factor)?.dataType?.trim().length > 0&&
          (selectedDataPoint as Factor)?.dataPointValue.values.length > 0 &&
          !hasDuplicates((selectedDataPoint as Factor)?.dataPointValue.values) &&
          (selectedDataPoint as Metric | Factor)?.unit?.trim().length > 0;
        break;
      case ParametersTypesEnum.BENCHMARKS:
        allowSaveParameter =
          allowSaveParameter &&
          !isNaN((selectedDataPoint as Benchmark)?.value)
        break;
    }
    setIsSaveButtonEnabled(allowSaveParameter && areInputsValid(selectedDataPoint, modalType));
  },[
    selectedDataPoint?.displayName,
    (selectedDataPoint as Metric | Factor)?.dataType,
    (selectedDataPoint as Metric | Factor)?.unit,
    selectedDataPoint?.description,
    updatedMetricFormula,
    (selectedDataPoint as Factor)?.dataPointValue?.values,
    (selectedDataPoint as Benchmark)?.value
  ]);

  useEffect(() => {
    setIsPropModified(isDirty);
  }, [isDirty, setIsPropModified]);

  useEffect(() => {
    if (dataPointChanges.modifiedDataPoint) {
      if (isPropModified || selectedDataPoint?.id === newDataPointId) {
        const notificationObject = strategy.generateDataPointsNotificationObject(false);

        setModalProps({
          ...notificationObject,
          iconHeader: IconHeader.Info,

          onLinkClick: () => {
            setShowNotificationModal(false);
            dataPointChanges.setModifiedDataPoint(false);
            onCloseModal();
          },
          onClose: () => {
            setShowNotificationModal(false);
            dataPointChanges.setModifiedDataPoint(false);
          },
        });
        setShowNotificationModal(true);
      } else {
        dataPointChanges.setModifiedDataPoint(false);
        onCloseModal();
      }
    }
  }, [dataPointChanges.modifiedDataPoint, isPropModified, selectedDataPoint?.id]);

  const addFactorUnitSpec =(dataPoint: Metric | Factor | Benchmark | AdvancedFactor) => {
    const foundSimulationParameter = dataPoints.simulationFactorsData.find((sf) => sf.id === (dataPoint as AdvancedFactor).simulationFactor);
    return {
      ...dataPoint,
      unit: useImperial ? foundSimulationParameter.dataPointValue.values[0].imperialStandardValue.typeId
        : foundSimulationParameter.dataPointValue.values[0].industryStandardValue.typeId,
      dataType: foundSimulationParameter.dataType
    }
  }

  const onDataPointSelect = useCallback(
    (dataPoint: Metric | Factor | Benchmark | AdvancedFactor, isAddButtonPressed: boolean = false) => {
      if (!isAddButtonPressed && (isPropModified || selectedDataPoint?.id === newDataPointId)) {
        const notificationObject = strategy.generateDataPointsNotificationObject(true);

        setModalProps({
          ...notificationObject,
          iconHeader: IconHeader.Info,
          onLinkClick: () => {
            if (selectedDataPoint?.id === newDataPointId) {
              const updatedDataPoints = dataPointsToDisplay.filter(
                (dp) => dp.id !== newDataPointId
              );
              setDataPointsToDisplay(updatedDataPoints);
            }
            if (dataPoint.type === DataPointType.AdvancedFactor) {
              const originalAdvancedFactor = addFactorUnitSpec(dataPoint);
              setOriginalSelectedDataPoint(originalAdvancedFactor);
              setSelectedDataPoint(originalAdvancedFactor);
            }else {
              setSelectedDataPoint(dataPoint);
              setOriginalSelectedDataPoint(dataPoint);
            }
            setShowNotificationModal(false);
            setIsPropModified(false);
          },
          onClose: () => setShowNotificationModal(false),
        });

        setShowNotificationModal(true);
        return;
      }
      // For Advanced Factors, the datapoint doesn't come with a unit and a spec. They are being taken from the simulation factor that they
      // are connected to. We do this here so that the original advanced factor is set correctly in case we cancel the edit.
      if (dataPoint.type === DataPointType.AdvancedFactor) {
        const originalAdvancedFactor = addFactorUnitSpec(dataPoint);
        setOriginalSelectedDataPoint(originalAdvancedFactor);
        setSelectedDataPoint(originalAdvancedFactor);
      } else {
        setOriginalSelectedDataPoint(dataPoint);
        setSelectedDataPoint(dataPoint);
      }
    },
    [isPropModified, selectedDataPoint]
  );

  const saveDataPointHandler = useCallback(async ( modifiedDataPoint: Metric | Factor | Benchmark ) => {
    try {
      if (modifiedDataPoint.displayName.trim().length > 0) {
        if (modifiedDataPoint.id === newDataPointId) {
          const createdDataPoint = await strategy.createDataPoint({dataPoint: modifiedDataPoint, modelId: currentModelId}, updatedMetricFormula);
          setSelectedDataPoint(createdDataPoint);
          setOriginalSelectedDataPoint(createdDataPoint);
        } else {
          const updatedDataPoint = await strategy.editDataPoint(
            {
              modelId: currentModelId,
              updatedDataPoint: modifiedDataPoint,
              originalDataPoint: originalSelectedDataPoint
            },
            updatedMetricFormula
          );
          setSelectedDataPoint(updatedDataPoint);
          setOriginalSelectedDataPoint(updatedDataPoint);
        }
        setIsReadOnly(true);
        setIsDirty(false);
        setIsPropModified(false);
      } else {
        onCloseModal();
      }
    } catch (err) {
      console.log('err', err);
    }
  }, [updatedMetricFormula, currentModelId, useImperial, selectedDataPoint]);

  const renameDataPointHandler = useCallback(
    async (newName: string) => {
      try {
        if (selectedDataPoint?.id !== newDataPointId) {
          await strategy.renameDataPoint(selectedDataPoint.id, newName, currentModelId );

          const updatedDataPoint: Metric | Factor | Benchmark = {
            ...selectedDataPoint,
            displayName: newName,
          };

          setSelectedDataPoint(updatedDataPoint);
          setOriginalSelectedDataPoint(updatedDataPoint);
          setIsReadOnly(true);
        }
      } catch (error) {
        console.log('error', error);
      }
    },
    [selectedDataPoint, currentModelId]
  );

  const deleteDataPointHandler = useCallback(async () => {
    try {
      const selectedDataPointIndex = dataPointsToDisplay.findIndex(
        (dataPoint: Metric | Factor | Benchmark | AdvancedFactor) => dataPoint.id === selectedDataPoint.id
      );

      await strategy.deleteDataPoint(selectedDataPoint.id, currentModelId);

      setSelectedDataPoint(dataPointsToDisplay[selectedDataPointIndex === 0 ? 1 : 0]);
      setOriginalSelectedDataPoint(dataPointsToDisplay[selectedDataPointIndex === 0 ? 1 : 0]);
      setIsReadOnly(true);
    } catch (error) {
      console.log('error', error);
    }
  }, [selectedDataPoint, currentModelId]);

  const showDeleteModalHandler = useCallback(async () => {
    try {
      if (!selectedDataPoint.id) {
        return;
      }
      switch (selectedDataPoint.type) {
        case DataPointType.Metric:
        case DataPointType.Factor:
          tryDeleteMetricOrFactor();
          break;
        case DataPointType.Benchmark:
          setModalProps({
            title: i18n.t('analysis.dataPoints.benchmarks.deleteNotificationConfirmTitle'),
            text: i18n.t('analysis.dataPoints.benchmarks.deleteNotificationConfirmText'),
            textPrimaryBn: i18n.t('analysis.dataPoints.labels.deleteNotificationConfirmButton'),
            iconHeader: IconHeader.Warn,
            onLinkClick: () => {
              deleteDataPointHandler();
              setShowNotificationModal(false);
            },
            onClose: () => setShowNotificationModal(false),
          });
          setShowNotificationModal(true);
          break;
        case DataPointType.AdvancedFactor:
          if (isReferencedMetric(selectedDataPoint.id, dataPoints.metricsData as Metric[])) {
            setModalProps({
              title: i18n.t('analysis.dataPoints.factors.deleteNotificationDeniedTitle'),
              text: i18n.t('analysis.dataPoints.factors.deleteNotificationDeniedText'),
              hideCancel: true,
              iconHeader: IconHeader.Info,
              onLinkClick: () => setShowNotificationModal(false),
              onClose: () => setShowNotificationModal(false),
            });
          } else {
            setModalProps({
              title: i18n.t('analysis.dataPoints.factors.deleteNotificationConfirmTitleAdvanced'),
              text: i18n.t('analysis.dataPoints.factors.deleteNotificationConfirmText'),
              textPrimaryBn: i18n.t('analysis.dataPoints.labels.deleteNotificationConfirmButton'),
              iconHeader: IconHeader.Warn,
              onLinkClick: () => {
                deleteDataPointHandler();
                setShowNotificationModal(false);
              },
              onClose: () => setShowNotificationModal(false),
            });
          }
          setShowNotificationModal(true);
          break;
      }
    } catch (error) {
      console.log('error', error);
    }
  }, [selectedDataPoint]);

  const onDiscardChanges = useCallback(() => {
    if (selectedDataPoint?.id !== newDataPointId && originalSelectedDataPoint) {
      setSelectedDataPoint(originalSelectedDataPoint);
      if (originalSelectedDataPoint.type === DataPointType.Metric) {
        setUpdatedMetricFormula((originalSelectedDataPoint as Metric).formula);
        setShouldCleanFormula(true)
      }
    } else {
      cleanCreateMetric();
      setUpdatedMetricFormula('');
    }
    setIsReadOnly(true);
  }, [selectedDataPoint, originalSelectedDataPoint, useImperial, cleanCreateMetric]);

  const isModalLoading =
    isGetDataPointsLoading ||
    isLoadingUnitSystem ||
    isLoadingUnits ||
    isDataPointsStrategyLoading;

  const isModalError = isGetDataPointsError || isUnitSystemError || isUnitsError;

  const tryDeleteMetricOrFactor = () => {
    if (isReferencedMetric(selectedDataPoint.id, dataPoints.metricsData as Metric[])) {
      setModalProps({
        title:
          modalType === ParametersTypesEnum.METRICS
            ? i18n.t('analysis.dataPoints.metrics.deleteNotificationDeniedTitle')
            : i18n.t('analysis.dataPoints.factors.deleteNotificationDeniedTitle'),
        text:
          modalType === ParametersTypesEnum.METRICS
            ? i18n.t('analysis.dataPoints.metrics.deleteNotificationDeniedText')
            : i18n.t('analysis.dataPoints.factors.deleteNotificationDeniedText'),
        hideCancel: true,
        iconHeader: IconHeader.Info,
        onLinkClick: () => setShowNotificationModal(false),
        onClose: () => setShowNotificationModal(false),
      });
    } else {
      setModalProps({
        title:
          modalType === ParametersTypesEnum.METRICS
            ? i18n.t('analysis.dataPoints.metrics.deleteNotificationConfirmTitle')
            : i18n.t('analysis.dataPoints.factors.deleteNotificationConfirmTitle'),
        text:
          modalType === ParametersTypesEnum.METRICS
            ? i18n.t('analysis.dataPoints.metrics.deleteNotificationConfirmText')
            : i18n.t('analysis.dataPoints.factors.deleteNotificationConfirmText'),
        textPrimaryBn: i18n.t('analysis.dataPoints.labels.deleteNotificationConfirmButton'),
        iconHeader: IconHeader.Warn,
        onLinkClick: () => {
          deleteDataPointHandler();
          setShowNotificationModal(false);
        },
        onClose: () => setShowNotificationModal(false),
      });
    }
    setShowNotificationModal(true);
  }
  return (
    (isModalError && (
      <DataPointsModalUnavailable message={i18n.t('appError.errorDataModalMessage')} />
    )) ||
    (isModalLoading && <CardDataLoadingProgress />) || (
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'auto 3fr',
          overflowY: 'hidden',
          height: '100%',
          gap: '0.5rem',
        }}
      >
        <DataPointsListContainer
          dataType={modalType}
          dataPoints={dataPointsToDisplay}
          selectedDataPoint={selectedDataPoint}
          isReadOnly={isReadOnly}
          onDataPointSelect={onDataPointSelect}
          onDataPointDelete={showDeleteModalHandler}
          onDataPointRename={renameDataPointHandler}
          setSelectedDataPoint={setSelectedDataPoint}
          setOriginalDataPoint={setOriginalSelectedDataPoint}
          useImperial={useImperial}
          setIsDirty={setIsDirty}
          setReadOnly={setIsReadOnly}
        />
        {
          modalType === ParametersTypesEnum.BENCHMARKS
            ? (
                <BenchmarksInformation
                  selectedDataPoint={selectedDataPoint as Benchmark}
                  setSelectedDataPoint={setSelectedDataPoint}
                  metricsList={dataPoints?.metricsData ?? []}
                  imperialUnits={useImperial}
                  cancelHandler={onDiscardChanges}
                  isReadOnly={isReadOnly}
                  isSaveEnabled={isSaveButtonEnabled}
                  onCloseModal={onCloseModal}
                  saveDataPoint={saveDataPointHandler}
                  setIsDirty={setIsDirty}
                  setIsReadOnly={setIsReadOnly}
                  setIsSaveEnabled={setIsSaveButtonEnabled}
                  modalType={modalType}
                  benchmarksList={dataPointsToDisplay as Benchmark[]}
                />
              )
            : (
                <DataPointsInformation
                  shouldCleanFormula={shouldCleanFormula}
                  setShouldCleanFormula={setShouldCleanFormula}
                  selectedDataPoint={selectedDataPoint as Metric | Factor}
                  originalDataPoint={originalSelectedDataPoint as Metric | Factor}
                  saveDataPoint={saveDataPointHandler}
                  isSaveEnabled={isSaveButtonEnabled}
                  setIsSaveEnabled={setIsSaveButtonEnabled}
                  cancelHandler={onDiscardChanges}
                  setCreateDataPoint={setSelectedDataPoint}
                  onFormulaUpdate={setUpdatedMetricFormula}
                  isReadOnly={isReadOnly}
                  setIsReadOnly={setIsReadOnly}
                  setIsDirty={setIsDirty}
                  isDirty={isDirty}
                  onCloseModal={onCloseModal}
                  simulationFactors={dataPoints.simulationFactorsData}
                  usedParametersInAdvancedFactors={usedParametersInAdvancedFactors}
                />
              )
        }
        <NotificationModal showModal={showNotificationModal} modalProps={modalProps} />
      </Box>
    )
  );
};

export default DataPointsModal;
