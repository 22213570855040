import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Benchmark,
  Factor,
  FactorValue,
  Metric,
  ParametersModalType,
  ParametersTypesEnum,
  UnitSystem
} from '../../../../types/metrics';
import { emptyBenchmark, getDefaultDataPoint, newDataPointId } from '../../../utils/dataPointsUtils';
import i18n from '../../../../i18n';
import ActionIcons from '../ActionIcons/ActionIcons';
import DataPointsList from './DataPointsList';
import Box from '@weave-mui/box';
import Link from '@weave-mui/link';
import { linkVariants } from '@weave-mui/enums';
import { useGetUserDataQueryState } from '../../../../state/api/user-data-api';

interface DataPointsListContainerProps {
  isReadOnly: boolean;
  useImperial: boolean;
  dataPoints: (Metric| Factor | Benchmark)[];
  selectedDataPoint: Metric | Factor | Benchmark;
  dataType: ParametersModalType;
  onDataPointDelete: () => void;
  onDataPointRename: (newName: string) => void;
  onDataPointSelect: (dataPoint: Metric | Factor | Benchmark, isAddButtonPressed: boolean) => void;
  setSelectedDataPoint: React.Dispatch<React.SetStateAction<Metric | Factor | Benchmark>>;
  setOriginalDataPoint: React.Dispatch<React.SetStateAction<Metric | Factor | Benchmark>>;
  setIsDirty: React.Dispatch<React.SetStateAction<boolean>>;
  setReadOnly: React.Dispatch<React.SetStateAction<boolean>>;
}

const DataPointsListContainer: React.FC<DataPointsListContainerProps> = ({
  isReadOnly,
  useImperial,
  dataPoints,
  selectedDataPoint,
  dataType,
  onDataPointDelete,
  onDataPointRename,
  onDataPointSelect,
  setSelectedDataPoint,
  setOriginalDataPoint,
  setReadOnly,
  setIsDirty
}) => {
  const [shouldRename, setShouldRename] = useState<boolean>(false);
  const [dataPointsList, setDataPointsList] = useState<(Metric | Factor | Benchmark)[]>(dataPoints);

  const { data: user } = useGetUserDataQueryState();

  useEffect(() => {
    setDataPointsList(dataPoints);
  }, [dataPoints]);

  const helpTextAndLink = useMemo<{ text: string; link: string }>(() => {
    switch (dataType) {
      case ParametersTypesEnum.METRICS:
        return {
          text: i18n.t('analysis.dataPoints.metrics.helpText'),
          link: user?.componentHelpBaseUrl + 'CUSTOM_METRICS',
        };
      case ParametersTypesEnum.FACTORS:
        return {
          text: i18n.t('analysis.dataPoints.factors.helpText'),
          link: user?.componentHelpBaseUrl + 'CUSTOM_FACTORS',
        };
      case ParametersTypesEnum.BENCHMARKS:
        return {
          text: i18n.t('analysis.dataPoints.benchmarks.helpText'),
          link: user?.componentHelpBaseUrl + 'CUSTOM_BENCHMARKS',
        };
      default:
        return null;
    }
  }, [dataType, user]);

  const copyDataPointHandler = useCallback(() => {
    if (selectedDataPoint && selectedDataPoint.id === newDataPointId) {
      return;
    }
    const selectedDataPointCopyName = `${selectedDataPoint.displayName}-${i18n.t('analysis.dataPoints.labels.singleCopy')}`;
    const copiedDataPoints = dataPointsList.filter((dp) => dp.displayName.startsWith(selectedDataPointCopyName.trim()));

    const copiedName = copiedDataPoints.length === 0
      ? selectedDataPointCopyName
      : `${selectedDataPoint.displayName}-${i18n.t('analysis.dataPoints.labels.multipleCopies', { count: copiedDataPoints.length })}`;

    let copyDataPoint: Metric | Factor | Benchmark = {
      ...selectedDataPoint,
      id: newDataPointId,
      name: copiedName,
      displayName: copiedName
    };

    switch (dataType) {
      case ParametersTypesEnum.METRICS:
        const selectedMetric = selectedDataPoint as Metric;
        copyDataPoint = {
          ...copyDataPoint,
          isGlobal: false,
        }

        if (selectedMetric.isGlobal) {
          const metricUnit = useImperial
              ? selectedMetric.imperialStandardUnitId
              : selectedMetric.industryStandardUnitId;

          copyDataPoint = {
            ...copyDataPoint,
            unit: metricUnit
          }

          delete (copyDataPoint as Metric).breakdownFormulas;
        }
        break;
      case ParametersTypesEnum.FACTORS:
        const selectedFactor = selectedDataPoint as Factor;
        copyDataPoint = {
          ...copyDataPoint,
          isGlobal: false,
        }

        if (selectedFactor.isGlobal) {
          let factorUnit = '';
          const imperialUnit = selectedFactor.dataPointValue.values[0].imperialStandardValue.typeId;
          const industryUnit = selectedFactor.dataPointValue.values[0].industryStandardValue.typeId;

          if (imperialUnit !== null && industryUnit !== null) {
            factorUnit = useImperial ? imperialUnit : industryUnit;
          } else {
            factorUnit = selectedFactor.unit;
          }
          const dataPointValues: FactorValue[] =
            selectedFactor.dataPointValue.values.map(v => {
              return {
                name: v.name,
                value: (useImperial ? v.imperialStandardValue.value: v.industryStandardValue.value) ?? v.value,
                isDefault: v.isDefault
              }
            });
          copyDataPoint = {
            ...copyDataPoint,
            unit: factorUnit,
            dataPointValue: {
              values: dataPointValues
            }
          }
        }
        break;
      case ParametersTypesEnum.BENCHMARKS:
        const selectedBenchmark = selectedDataPoint as Benchmark;
        copyDataPoint = {
          ...copyDataPoint,
          value: selectedBenchmark.value,
          metrics: []
        }
        break;
    }

    const updatedDataPoints: (Metric | Factor | Benchmark)[] = [copyDataPoint, ...dataPointsList];
    setDataPointsList(updatedDataPoints);
    setSelectedDataPoint(copyDataPoint);
  }, [selectedDataPoint, dataPointsList]);

  const addDataPointHandler = useCallback(() => {
    if (selectedDataPoint && selectedDataPoint.id === newDataPointId) {
      return;
    }

    let newDataPoint: Factor | Metric | Benchmark = getDefaultDataPoint(dataType);

    switch(dataType) {
      case ParametersTypesEnum.METRICS:
        const newMetrics = dataPointsList.filter((metric) =>
          metric.displayName.startsWith(i18n.t('analysis.dataPoints.metrics.newMetric').trim())
        );
        const newMetricName = newMetrics.length === 0
          ? i18n.t('analysis.dataPoints.metrics.newMetric')
          : i18n.t('analysis.dataPoints.metrics.newMetric', { count: newMetrics.length });

        newDataPoint = {
          ...newDataPoint,
          name: newMetricName,
          displayName: newMetricName,
          unitSystem: useImperial ? UnitSystem.Imperial : UnitSystem.Metric
        };
        break;
      case ParametersTypesEnum.FACTORS:
        const newFactors = dataPointsList.filter(factor =>
          factor.displayName.startsWith(i18n.t('analysis.dataPoints.factors.newFactor'))
        );

        const newFactorName = newFactors.length === 0
          ? i18n.t('analysis.dataPoints.factors.newFactor')
          : i18n.t('analysis.dataPoints.factors.newFactor', { count: newFactors.length });

        newDataPoint = {
          ...newDataPoint,
          name: newFactorName,
          displayName: newFactorName,
          unitSystem: useImperial ? UnitSystem.Imperial : UnitSystem.Metric
        };
        break;
      case ParametersTypesEnum.BENCHMARKS:
        const newBenchmarks = dataPointsList.filter(benchmark =>
          benchmark.displayName.startsWith(i18n.t('analysis.dataPoints.benchmarks.newBenchmark'))
        );

        const newBenchmarkName = newBenchmarks.length === 0
          ? i18n.t('analysis.dataPoints.benchmarks.newBenchmark')
          : i18n.t('analysis.dataPoints.benchmarks.newBenchmark', { count: newBenchmarks.length });

        newDataPoint = {
          ...newDataPoint,
          name: newBenchmarkName,
          displayName: newBenchmarkName
        };
        break;
    }

    setDataPointsList((prevState) => [newDataPoint, ...prevState]);
    onDataPointSelect(newDataPoint, true);
  }, [dataPointsList, selectedDataPoint]);

  const toggleRenameInputHandler = useCallback(() => {
    if (!selectedDataPoint) return;
    setShouldRename((prevState) => !prevState);
  }, [selectedDataPoint]);

  const editHandler = useCallback(() =>{
    setReadOnly(false);
    setIsDirty(true);
  },[setReadOnly, setIsDirty]);

  const deleteHandler = useCallback(() => {
    if (selectedDataPoint?.id === newDataPointId) {
      const updatedDataPoints = dataPointsList.filter(m => m.id !== newDataPointId);
      setDataPointsList(updatedDataPoints);
      let firstDefault: Metric | Factor | Benchmark;
      switch (dataType) {
        case ParametersTypesEnum.BENCHMARKS:
          firstDefault = dataPointsList.length > 0
            ? updatedDataPoints[0]
            : emptyBenchmark;
          break;
        case ParametersTypesEnum.METRICS:
        case ParametersTypesEnum.FACTORS:
          firstDefault = dataPointsList.find(p => (p as Metric | Factor).isGlobal);
          break;
      }
      setSelectedDataPoint(firstDefault);
      setOriginalDataPoint(firstDefault);
    } else {
      onDataPointDelete();
    }
  }, [selectedDataPoint, dataPointsList]);

  const selectDataPointCb = useCallback((selectedParameter: Metric | Factor | Benchmark) => {
    onDataPointSelect(selectedParameter, false);
  }, [onDataPointSelect]);

  return (
    <Box
      sx={{
        height: '100%',
        width: '240px',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#f5f5f5',
        p: '0.6rem 0.6rem 0px 0.6rem',
        overflow: 'hidden',
      }}
    >
      <ActionIcons
        onAdd={addDataPointHandler}
        onCopy={copyDataPointHandler}
        onDelete={deleteHandler}
        onRename={toggleRenameInputHandler}
        onEdit={editHandler}
        selectedDataPoint={selectedDataPoint}
        isAddEnabled={dataPointsList.filter((p) => p.id === newDataPointId).length === 0}
        isReadOnly={isReadOnly}
      />
      <DataPointsList
        dataType={dataType}
        dataPoints={dataPointsList}
        onDataPointSelect={selectDataPointCb}
        shouldRename={shouldRename}
        selectedDataPoint={selectedDataPoint}
        onRenameSubmit={onDataPointRename}
        setShouldRename={setShouldRename}
      />
      <Box sx={{ flexShrink: 0 }} className="noMargin">
        <Link href={helpTextAndLink?.link} target="BLANK" variant={linkVariants.PRIMARY}>
          {helpTextAndLink?.text}
        </Link>
      </Box>
    </Box>
  );
}

export default DataPointsListContainer
