import {
  AdvancedFactor,
  DataPoint,
  DataPointBase, DataPoints,
  DataPointsDefinitionType,
  DataPointType,
  Factor,
  FormulaToken,
  isDataPointsDefinitionType,
  ISpec,
  Metric
} from '../../types/metrics';
import { getDataDefinitionUnit, getDataPointUnit } from './unitsUtils';
import { analysisBlue } from '../styles/colorCodes';

const regexToken = /#{([^}]+)}/g;

export const extractParametersFromFormula = (
  formula: string,
  dataPoints: Map<string, DataPoint | Metric | Factor | DataPointsDefinitionType | AdvancedFactor>,
  allUnits: Record<string, ISpec>,
  useImperial: boolean,
): FormulaToken[] => {
  if (!formula || !dataPoints) {
    return [];
  }
  //const regex = new RegExp(regexToken, 'g');
  let match: RegExpExecArray | null,
    result: FormulaToken[] = [];
  let lastIndex = 0;

  while ((match = regexToken.exec(formula))) {
    const matchStart = match.index;
    const matchEnd = regexToken.lastIndex;
    const nonMatchedSubstring = formula.substring(lastIndex, matchStart);

    if (nonMatchedSubstring) {
      result.push({
        isParameter: false,
        parameter: nonMatchedSubstring,
        unit: '',
      });
    }
    let parameter = dataPoints.get(match[1]);
    if (parameter) {
      result.push({
        isParameter: true,
        parameter,
        unit: isDataPointsDefinitionType(parameter) // validate if parameter is a new style data definition or old style data point
          ? getDataDefinitionUnit(parameter, allUnits, useImperial, dataPoints)?.symbol ?? ''
          : getDataPointUnit(parameter, allUnits, useImperial, dataPoints)?.symbol ?? '',
      });
    }
    lastIndex = matchEnd;
  }
  if (lastIndex < formula.length) {
    result.push({
      isParameter: false,
      parameter: formula.substring(lastIndex),
      unit: '',
    });
  }
  return result;
};

export const parseFormulaForEvaluation = (formulaContent: string) => {
  return formulaContent.replace(
    /<div[^>]+data-param-id="([^"]+)"[^>]*>.*?<\/div>/g,
    (match, paramId: string, content) => {
      return `#{${paramId.trim()}}`;
    }
  );
};

export const sanitizeToken = (formula: string): string => {
  return formula
    .replace(/\s/g, '')
    .replaceAll(':', ':'.charCodeAt(0).toString(16))
    .replaceAll('-', '-'.charCodeAt(0).toString(16))
    .replaceAll('.', '.'.charCodeAt(0).toString(16));
};

export const deSanitizeToken = (token: string): string => {
  return token
    .replace(/\s/g, '')
    .replaceAll(':'.charCodeAt(0).toString(16), ':')
    .replaceAll('-'.charCodeAt(0).toString(16), '-')
    .replaceAll('.'.charCodeAt(0).toString(16), '.');
};

export const sanitizeFormula = (formula: string): string => {
  return formula.replace(regexToken, (match, parameter) => {
    return sanitizeToken(parameter);
  });
};

export const sanitizeFormulaHtml = (formula: string) => {
  return formula?.trim().replace(/\u200B/g, '');
};

export const removeDataPointFromFormula = (formula: string, indexToRemove: number): string => {
  let tokenIndex = 0;
  const replacer = (match, parameterId) => {
    if (tokenIndex++ === indexToRemove) {
      return '';
    }
    return match;
  };
  const updatedFormula = formula.replace(regexToken, replacer);

  return updatedFormula;
};

export const colorHandler = (parameterType: DataPointType) => {
  let color = '';
  switch (parameterType) {
    case DataPointType.AnalysisResult:
      color = analysisBlue;
      break;
    case DataPointType.ModelData:
      color = 'orange';
      break;
    case DataPointType.AdvancedFactor:
    case DataPointType.Factor:
      color = 'green';
      break;
    case DataPointType.Metric:
      color = 'purple';
      break;
  }
  return {
    background: color,
    color: 'white',
    '&:hover': {
      background: color,
    },
  };
};

export const getTokenLabel = (parameter: DataPointBase, symbol: string) => {
  let paramName = '';
  if (parameter?.displayName?.length > 0) {
    paramName = parameter?.displayName;
  } else {
    paramName = parameter?.name;
  }

  return `${paramName}${symbol?.length > 0 ? ` (${symbol})` : ''}`;
};

export const validateFormulaIndex = (formula, index) => {
  const regex = /#{(.*?)}/g; // Regular expression to match #{...}
  let match;
  while ((match = regex.exec(formula)) !== null) {
    const startIndex = match.index;
    const endIndex = regex.lastIndex;
    if (index > startIndex && index < endIndex) {
      return false; // Index falls within #{...}
    }
  }
  return true; // Index does not fall within #{...}
};
