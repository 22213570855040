import React from 'react';
import { DataPointType, DataPointValue, MetricEvaluationResult } from '../../../types/metrics';
import * as conv from '../../../conversions';
import { tooltipPlacement } from '@weave-mui/tooltip';
import i18n from '../../../i18n';
import { EllipsisTypography } from '../../../shared/EllipsisTypography';
import { AlertS } from '@weave-mui/icons-weave';
import { InfoTooltipIcon } from '../../../shared/InfoTooltipIcon';

export const DataPointDisplay: React.FC<{
  dataPointValue: DataPointValue;
  enableTooltip: Boolean;
}> = ({ dataPointValue, enableTooltip }) => {
  let value: number | string | null = null;
  let unitSymbol: string = null;

  const getDataPointValue = (dataPointValue: DataPointValue): number | string | null => {
    if (dataPointValue.type === DataPointType.Factor) {
      return null;
    }

    if (dataPointValue.type === DataPointType.Metric) {
      // todo: handle multiple metric errors and breakdowns
      const metricValue = dataPointValue.value as MetricEvaluationResult;
      return formatNumericValue(metricValue.result);
    }

    if (typeof dataPointValue.value === 'string') {
      return dataPointValue?.value as string;
    }

    return formatNumericValue(dataPointValue?.value as number);
  };

  const formatNumericValue = (value: number): string | number => {
    return (
      (value &&
        conv
          .round(value, 2)
          ?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 })) ||
      0
    );
  };

  if (dataPointValue) {
    value = !dataPointValue.isComputed
      ? i18n.t('cardConfig.notApplicableText') as string
      : getDataPointValue(dataPointValue) || 0;
    unitSymbol = dataPointValue?.unitSymbol || '';
  }

  return (
    <>
      {(dataPointValue?.isPartialResult && (
        <InfoTooltipIcon
          icon={
            <AlertS
              sx={{ paddingRight: '5px', paddingBottom: '4px', cursor: 'pointer' }}
              color="warning"
            />
          }
          tooltipContent={dataPointValue?.warningMessage}
          tooltiPlacement={tooltipPlacement.BOTTOM_START}
          maxWidth={'240px'}
        />
      )) || <></>}
      <EllipsisTypography
        mainValue={value}
        secondaryValue={unitSymbol}
        enableTooltip={enableTooltip}
      />
    </>
  );
};
