import { LayoutHookReturnType } from '../../../types/layout';
import {useGetDataPointsDefinitionsQuery, useGetDataPointsV2Query} from '../../../state/api/data-service-api';
import { useSelector } from 'react-redux';
import { ApplicationDataState } from '../../../state/slice/application-data-slice';
import {useEffect, useMemo, useState} from 'react';
import {
  DataPointsDefinitions,
  DataPointsDefinitionType,
  DataPointsResult,
} from '../../../types/metrics';
import { createSelector } from '@reduxjs/toolkit';
import { dataPointDefinitionsToMap } from '../../../dataPoints/utils/dataPointsUtils';
import { useIdleTimer } from 'react-idle-timer/dist/index.legacy.esm';
import {useIsV2APIEnabled} from "../../../shared/application/application-hooks";

const DataPointsPollingInterval = 300 * 1000;
const getDataPointsV2 = (dataPoints: DataPointsDefinitions) => dataPoints;
const memoizedDataPointsToMapSelector = createSelector([getDataPointsV2], (dataPoints) =>
  dataPointDefinitionsToMap(dataPoints)
);

export const useDataPointsV2 = (): LayoutHookReturnType<DataPointsResult> => {
  const modelId = useSelector(
    (state: { applicationDataState: ApplicationDataState }) =>
      state.applicationDataState.currentModelId
  );
  const {data: isV2Enabled} = useIsV2APIEnabled();
  //const [data, setData] = useState<LayoutHookReturnType<typeof useDataPointsV2>>(null);
  // temporary polling for base run only
  // once base run is handled by ts/ACP we can use L3 events to get all runs progress
  const [poolingInterval, setPoolingInterval] = useState(DataPointsPollingInterval);
  const [userIsActive, setUserIsActive] = useState<boolean>(true);
  // prevent polling when user is idle
  const { pause, resume } = useIdleTimer({
    onIdle: () => {
      console.log(`User idle, stopped polling for data points for - ${modelId}`);
      setUserIsActive(false);
    },
    onActive: () => {
      console.log(`User active, started/resumed polling for data points for - ${modelId}`);
      setUserIsActive(false);
    },
    startOnMount: true,
    timeout: 120000, // wait 2 minutes before considering user as idle
  });
  const {
    data: dataPointsDefinitions,
    isLoading: isLoadingDefinitions,
    isError: isErrorDefinitions,
    isSuccess: isSuccessDefinitions,
    isFetching: isFetchingDefinitions
  } = useGetDataPointsDefinitionsQuery(undefined, {skip: !isV2Enabled});

  const {
    data: analysisResults,
    isLoading: isLoadingAnalysisResults,
    isError: isErrorAnalysisResults,
    isSuccess: isSuccessAnalysisResults,
    isFetching: isFetchingAnalysisResults
  } = useGetDataPointsV2Query(modelId, {
    skip: !modelId || !isV2Enabled,
    pollingInterval: poolingInterval,
  });

  const isLoading = isLoadingDefinitions || isLoadingAnalysisResults;
  const isFetching = isFetchingDefinitions || isFetchingAnalysisResults;
  const isSuccess = isSuccessDefinitions && isSuccessAnalysisResults;
  const isError = isErrorDefinitions || isErrorAnalysisResults;

  const isAnalysisCompleted =
    !isLoading && !isFetching && !!analysisResults?.analysisRuns.find((run) => run.isBaseRun);

  const data = useMemo(() => {
    if (isSuccess) {
      return  {
        definitions: {...dataPointsDefinitions.definitions},
        factorsData: dataPointsDefinitions.definitionsData.factorsData,
        ...analysisResults
      };
    }
    return null;
  }, [isSuccess, analysisResults, dataPointsDefinitions]);

  // reset polling interval when modelId changes
  useEffect(() => {
    setPoolingInterval(DataPointsPollingInterval);
    resume(); // resume idle timer when modelId changes
  }, [modelId]);

  useEffect(() => {
    if (isAnalysisCompleted || isError) {
      pause(); // pause idle timer when analysis is completed
      setPoolingInterval(0);
    }
  }, [analysisResults, isLoading, isFetching, isError]);

  return {
    data,
    isLoading,
    isError,
    isSuccess,
    isFetching,
  };
};

export const useGetDataPointMapV2 = (): LayoutHookReturnType<
  Map<string, DataPointsDefinitionType>
> => {
  const { data: dataPoints, isLoading, isSuccess, isError, isFetching } = useDataPointsV2();

  if (isLoading || !dataPoints) {
    return {
      data: null,
      isLoading,
      isError,
      isSuccess,
    };
  }
  return {
    data: memoizedDataPointsToMapSelector(dataPoints.definitions),
    isLoading,
    isError,
    isSuccess,
    isFetching,
  };
};
