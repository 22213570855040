import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface FactorDataState {
  factorOverrides: Record<string, number>;
}

const initialState: FactorDataState = {
  factorOverrides: {},
};

export const factorDataSlice = createSlice({
  name: 'factorDataState',
  initialState,
  reducers: {
    setFactorOverride(state, action: PayloadAction<{ factorId: string; value: number }>) {
      state.factorOverrides[action.payload.factorId] = action.payload.value;
    },
    removeFactorOverride(state, action: PayloadAction<string>) {
      if (state.factorOverrides[action.payload]) {
        const { [action.payload]: _, ...rest } = state.factorOverrides;
        state.factorOverrides = rest;
      }
    },
    clearAllFactorOverrides(state) {
      state.factorOverrides = {};
    },
  },
});

export const { setFactorOverride, clearAllFactorOverrides, removeFactorOverride } =
  factorDataSlice.actions;
