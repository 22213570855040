import React, { Dispatch, MutableRefObject, SetStateAction } from 'react'
import FactorsTable, { FactorsTableHandle } from './Table/FactorsTable'
import Box from '@weave-mui/box'
import {AdvancedFactor, Factor, SimulationFactor} from '../../../types/metrics'
import SimulationControl from './Simulation/SimulationControl';
import {useGetUserFeatureFlagDataQuery} from "../../../state/api/feature-flag-data-api";
import {FeatureFlags} from "../../../utils/constants";

interface FactorsInformationProps {
  selectedFactor: Factor | AdvancedFactor;
  simulationFactors: SimulationFactor[];
  isReadOnly: boolean;
  imperialUnits: boolean;
  setHasErrorInFactorsTable: Dispatch<SetStateAction<boolean>>;
  factorsTableRef: MutableRefObject<FactorsTableHandle>
  setSelectedFactor: Dispatch<SetStateAction<Factor | SimulationFactor>>;
  usedParametersInAdvancedFactors: string[]
}

const FactorsInformation: React.FC<FactorsInformationProps> =
  ({ selectedFactor, isReadOnly, imperialUnits, setHasErrorInFactorsTable, factorsTableRef, simulationFactors, setSelectedFactor, usedParametersInAdvancedFactors}) => {
    const { data: featureFlagData, isSuccess: featureFlagsSuccess } = useGetUserFeatureFlagDataQuery();
    const isSimulationFactorEnabled = featureFlagsSuccess ? featureFlagData?.[FeatureFlags.SimulationFactors] : false;
  return (
        <Box sx={{ display: 'flex', flex: '1', overflow: 'hidden', height: '100%', flexDirection: 'column'}}>
          {isSimulationFactorEnabled && <SimulationControl
            simulationFactors={simulationFactors}
            isReadOnly={isReadOnly}
            selectedFactor={selectedFactor}
            setSelectedFactor={setSelectedFactor}
            usedParametersInAdvancedFactors={usedParametersInAdvancedFactors}
          />}
          <FactorsTable
            isReadOnly={isReadOnly}
            selectedFactor={selectedFactor}
            imperialUnits={imperialUnits}
            setFactorsTableInErrorState={setHasErrorInFactorsTable}
            ref={factorsTableRef}
            simulationFactors={simulationFactors}
          />
        </Box>
    )
}

export default FactorsInformation
