import forgeUnits from "../forgeUnitsService";

export const formatNumber = (value: number, decimals: number): number | null | undefined => {
  if (value === null || value === undefined) {
    return value;
  }

  return forgeUnits.roundValue(value, decimals);
}

export const convertModelUrnToModelId = (modelUrn: string): string => {
  return modelUrn.substring(modelUrn.lastIndexOf(":") + 1, modelUrn.length);
}
