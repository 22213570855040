import {useDataPointsV2} from "./useDataPointsV2";
import {createSelector} from "@reduxjs/toolkit";
import {dataPointsMetricBenchmarksMap} from "../../../dataPoints/utils/dataPointsUtils";
import {Benchmark, DataPointsResult} from "../../../types/metrics";
import {LayoutHookReturnType} from "../../../types/layout";

const getDataPoints = (dataPoints: DataPointsResult) => dataPoints;
const memoizedMetricsWithBenchmarks = createSelector([getDataPoints], (dataPoints) =>
  dataPointsMetricBenchmarksMap(dataPoints.definitions.benchmarks)
);
export const useBenchmarksV2 = (): LayoutHookReturnType<Map<string, Benchmark[]>> => {
  const {
    data: dataPoints,
    isLoading: isLoadingDataPoints,
    isSuccess: isSuccessDataPoints,
    isError: isErrorDataPoints,
    isFetching: isFetchingDataPoints,
  } = useDataPointsV2();


  let isLoading: boolean =
    isLoadingDataPoints;
  let isError: boolean =
    isErrorDataPoints;
  const isSuccess: boolean =
    isSuccessDataPoints;
  const isFetching: boolean =
    isFetchingDataPoints;

  let result = {isSuccess, isError, isFetching, isLoading, data: null};

  if (isSuccess) {
    result = {
      ...result,
      data: memoizedMetricsWithBenchmarks(dataPoints)
    }
  }
  return result;
}
