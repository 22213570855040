import { AdvancedFactor, AdvancedFactorDefinition, DataPointType } from "../metrics";

/*
* API OBJECTS USED TO CALL DATA SERVICE API.
* DO NOT ADD INTERNAL LOGIC TO THIS OBJECTS
* */
export interface CreateAdvancedFactorsResponse {
  id: string,
  name: string;
  description: string;
  displayName: string;
  simulationFactor: string;
  isGlobal: boolean;
}

export interface OverrideAdvancedFactorResponse {
  id: string;
  name?: string;
  description?: string;
  simulationFactor: string;
}

export const toAdvancedFactorDefinition = (advancedFactor: AdvancedFactor): AdvancedFactorDefinition => {
  return {
    id: advancedFactor.id,
    name: advancedFactor.name,
    displayName: advancedFactor.displayName,
    description: advancedFactor.description,
    simulationFactor: advancedFactor.simulationFactor,
    dataType: '',
    isGlobal: advancedFactor.isGlobal,
    type: advancedFactor.type
  };
};

export const toAdvancedFactor = (response: CreateAdvancedFactorsResponse): AdvancedFactor => {
  return {
    id: response.id,
    description: response.description,
    name: response.name,
    displayName: response.displayName,
    simulationFactor: response.simulationFactor,
    isGlobal: response.isGlobal,
    dataType: '',
    type: DataPointType.AdvancedFactor,
    dataPointValue: {
      values: []
    },
  };
};
