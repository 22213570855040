import {
  AdvancedFactor,
  DataPointBase,
  DataPoints,
  DataPointType,
  Factor,
  FactorValue,
  Metric
} from '../../types/metrics';
import {FactorsTableRow, FactorTableCellErrorState} from '../components/factors/Table/FactorsTable';
import {validateFactorsTableRowValues} from './dataPointsUtils';

export const isDuplicatedFactorTableValue = (input: string, factorValues: FactorValue[], isName: boolean, currentRow: { name: string; value: string; }) => {
  if (isName){
    return factorValues.find(value => value.name === input && currentRow.name !== input);
  } else {
    return factorValues.find(value => value.value?.toString() === input && currentRow.value?.toString() !== input);
  }
}

export const hasDuplicates = (factorValues: FactorValue[]): boolean => {
  if (!factorValues || factorValues?.length === 0) {
    return false;
  }
  const nameSet = new Set<string>(factorValues?.map(fv => fv.name));
  const valuesSet = new Set<number>(factorValues?.map(fv => fv.value));
  return nameSet.size < factorValues.length || valuesSet.size < factorValues.length;
}

export const isAdvancedFactor = (dataPoint: DataPointBase): boolean => {
  return dataPoint.type === DataPointType.AdvancedFactor;
}

export const isAdvancedFactorById = (paramId:string, dataPoints: DataPoints): boolean => {
  const param = dataPoints.advancedFactorsData?.find(param => param.id === paramId);
  return !!param;
}

export const factorRowToDefaultFactorRow = (factorRow: FactorsTableRow, factor: Factor, imperialUnits: boolean): FactorValue => {
  const keyToUpdate: keyof FactorValue = imperialUnits
    ? 'imperialStandardValue'
    : 'industryStandardValue';
  const unitSystemCorrespondent = imperialUnits ? 'industryStandardValue' : 'imperialStandardValue';
  const typeId = imperialUnits
    ? factor.dataPointValue.values[0].imperialStandardValue?.typeId
    : factor.dataPointValue.values[0].industryStandardValue?.typeId;
  const unitSystemTypeIdCorrespondent = imperialUnits ?
    factor.dataPointValue.values[0].industryStandardValue?.typeId :
    factor.dataPointValue.values[0].imperialStandardValue?.typeId;
  return {
    name: factorRow.name,
    value: factorRow.value,
    [keyToUpdate]: {
      value: factorRow.value,
      typeId
    },
    [unitSystemCorrespondent]: {
      value: null,
      typeId: unitSystemTypeIdCorrespondent
    }
  }
}

export const isValidFactorsTable = ( cellErrorState: FactorTableCellErrorState,
                                     factorValuesRows: FactorsTableRow[],
                                     selectedFactor: Factor ) : boolean => {
  // verify if any cell are in error
  if (cellErrorState && !cellErrorState.obsolete){
    return false;
  }

  // verify name/value pair uniqueness
  const rowNameValueSet : Set<string> = new Set<string>();
  factorValuesRows.forEach( fvr => rowNameValueSet.add(`${fvr.name}${fvr.value}`) );
  if (factorValuesRows.length != rowNameValueSet.size){
    return false;
  }

  // verify new table data
  if( !factorValuesRows.every( fvr => validateFactorsTableRowValues(fvr)) ){
    return false;
  }

  // verify new data is different than old data
  // const oldValues = selectedFactor?.dataPointValue?.values;
  // if ( factorValuesRows.length === oldValues?.length && // different number of rows....
  //   oldValues.every( (ov) =>
  //     rowNameValueSet.has(`${ov.name}${ov.value}`)) ) // all rows from old are found in new
  //   return false;

  return true;
}
