import { useDispatch, useSelector } from 'react-redux';
import {
  setCurrentAnalysisRunId,
  setCurrentSelectedAdvancedFactorValue,
} from '../../../state/slice/application-data-slice';
import { RootState } from '../../../state/store';
import { AdvancedFactorCurrentValue } from '../../../types/metrics';
import { useDataPointsV2 } from './useDataPointsV2';
import { BASE_RUN_ID, NOT_COMPUTED_RUN_ID } from '../../../types/jobs';
import store from '../../../state/init-store';

/**
 * Set the current analysis run id
 */
export const useSetAnalysisRunV2 = (): ((runId: string) => void) => {
  const dispatch = useDispatch();

  return (runId: string) => {
    dispatch(setCurrentAnalysisRunId(runId));
  };
};


/**
 * Get the current analysis run id
 */
export const useCurrentAnalysisRunIdV2 = (): string => {
  let selectedAnalysisRun = useSelector(
    (state: RootState) => state.applicationDataState.currentAnalysisRunId
  );

  return selectedAnalysisRun;
};

/**
 * Get current advanced factor selections from state
 */
export const useCurrentAnalysisRunAdvancedFactorValueV2 = (): (() => {
  [advancedFactorId: string]: AdvancedFactorCurrentValue;
}) => {
  return () => (store.getState() as RootState).applicationDataState.currentSelectedAdvancedFactors;
};

/**
 * Set a current advanced factor selection in state
 */
export const useSetCurrentAnalysisRunAdvancedFactorValueV2 = (): ((
  advancedFactorCurrentValue: AdvancedFactorCurrentValue
) => void) => {
  const dispatch = useDispatch();

  return (advancedFactorCurrentValue: AdvancedFactorCurrentValue) => {
    dispatch(
      setCurrentSelectedAdvancedFactorValue({
        [advancedFactorCurrentValue.advancedFactorId]: advancedFactorCurrentValue,
      })
    );
  };
};

/**
 * Select the analysis run dependent on the current advanced factor selections
 */
export const useCurrentAnalysisRunSelectorV2 = (): void => {
  // TODO: improve this function to possible memoize the matching run id
  const setRunId = useSetAnalysisRunV2();
  let currentAdvancedFactorValues = useSelector(
    (state: RootState) => state.applicationDataState.currentSelectedAdvancedFactors
  );
  const { data } = useDataPointsV2();
  let matchingRunId = NOT_COMPUTED_RUN_ID;

  if (data) {
    const baseRun = data.analysisRuns.find((run) => run.isBaseRun);

    if (baseRun) {
      const currentAdvancedFactorIds = data.definitions.advancedFactors.map((x) => x.id);
      // stored advanced factor values from dashboard selection
      // any advanced factor values that are not set to revert to base
      let currentInputs = Object.values(currentAdvancedFactorValues).filter(
        (x) => !x.revertToBase && currentAdvancedFactorIds.includes(x.advancedFactorId)
      );
      const currentInputSimulationIds = currentInputs.map((x) => x.simulationfactorId);
      const baseRunInputs = baseRun.inputs;
      // do we need to pad out run search with base run inputs?
      const missingInputs = baseRunInputs.filter(
        (x) => !currentInputSimulationIds.includes(x.parameterId)
      );

      if (missingInputs.length) {
        // pad out the current inputs with the base run inputs if needed
        currentInputs = [
          ...currentInputs,
          ...missingInputs.map(
            (x) =>
              ({
                advancedFactorId: '',
                simulationfactorId: x.parameterId,
                value: {
                  imperialStandardValue: x.imperialStandardValue,
                  industryStandardValue: x.industryStandardValue,
                },
                revertToBase: false,
              } as AdvancedFactorCurrentValue)
          ),
        ];
      }

      for (const run of data.analysisRuns) {
        let matchedInputs = 0;
        let runInputs = run.inputs;
        if (runInputs.length !== currentInputs.length) {
          const runInputsSimulationIds = runInputs.map((x) => x.parameterId);
          runInputs = [
            ...runInputs,
            ...baseRunInputs.filter((x) => !runInputsSimulationIds.includes(x.parameterId)),
          ];
        }

        for (const matchInput of currentInputs) {
          const matchedInput = runInputs.find(
            (input) =>
              input.parameterId === matchInput.simulationfactorId &&
              input.imperialStandardValue.value === matchInput.value.imperialStandardValue.value &&
              input.industryStandardValue.value === matchInput.value.industryStandardValue.value
          );
          if (matchedInput) {
            matchedInputs++;
          }
        }

        if (matchedInputs === currentInputs.length) {
          matchingRunId = run.id === baseRun.id ? BASE_RUN_ID : run.id.toString();
          break; // Break out of the loop when a match is found
        }
      }
    }
  }

  setRunId(matchingRunId);
};
