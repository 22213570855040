import { LayoutHookReturnType } from '../../types/layout';
import { AnalyzeInProgress, useGetDataPointsQuery } from '../../state/api/data-service-api';
import { AdvancedFactor, DataPoint, DataPoints, Factor, Metric } from '../../types/metrics';
import { useSelector } from 'react-redux';
import { ApplicationDataState } from '../../state/slice/application-data-slice';
import { createSelector } from '@reduxjs/toolkit';
import { dataPointsToMap } from '../../dataPoints/utils/dataPointsUtils';
import { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer/dist/index.legacy.esm';
import {useIsV2APIEnabled} from "../../shared/application/application-hooks";

const DataPointsPoolingInterval = 30 * 1000;


export const useDataPoints = (): LayoutHookReturnType<DataPoints> => {
  const modelId = useSelector(
    (state: { applicationDataState: ApplicationDataState }) =>
      state.applicationDataState.currentModelId
  );
  const {data: isV2Enabled} = useIsV2APIEnabled();
  const [poolingInterval, setPoolingInterval] = useState(DataPointsPoolingInterval);
  const [userIsActive, setUserIsActive] = useState<boolean>(true);
  // prevent polling when user is idle
  const { pause, resume } = useIdleTimer({
    onIdle: () => {
      console.log(`User idle, stopped polling for data points for - ${modelId}`);
      setUserIsActive(false);
    },
    onActive: () => {
      console.log(`User active, started/resumed polling for data points for - ${modelId}`);
      setUserIsActive(true);
    },
    startOnMount: true,
    timeout: 120000, // wait 2 minutes before considering user as idle
  });

  const { data, isLoading, isError, isSuccess, isFetching } = useGetDataPointsQuery(modelId, {
    skip: !modelId || !userIsActive || isV2Enabled,
    pollingInterval: poolingInterval,
  });

  const isAnalysisCompleted = !isLoading && !isFetching && data?.status !== AnalyzeInProgress;

  // reset polling interval when modelId changes
  useEffect(() => {
    setPoolingInterval(DataPointsPoolingInterval);
    resume(); // resume idle timer when modelId changes
  }, [modelId]);

  useEffect(() => {
    if (isAnalysisCompleted || isError) {
      pause(); // pause idle timer when analysis is completed
      setPoolingInterval(0);
    }
  }, [data, isLoading, isFetching, isError]);

  return {
    data: data && isSuccess ? data : null,
    isLoading,
    isError,
    isSuccess,
    isFetching,
  };
};

const getDataPoints = (dataPoints: DataPoints) => dataPoints;
const memoizedDataPointsToMapSelector = createSelector([getDataPoints], (dataPoints) =>
  dataPointsToMap(dataPoints)
);

export const useGetDataPointMap = (): LayoutHookReturnType<
  Map<string, DataPoint | Metric | Factor | AdvancedFactor>
> => {
  const { data: dataPoints, isLoading, isSuccess, isError, isFetching } = useDataPoints();

  if (isLoading || !dataPoints) {
    return {
      data: null,
      isLoading,
      isError,
      isSuccess,
    };
  }
  return {
    data: memoizedDataPointsToMapSelector(dataPoints),
    isLoading,
    isError,
    isSuccess,
    isFetching,
  };
};
