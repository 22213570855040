/* eslint-disable react-hooks/exhaustive-deps */
import { Card, CardComponentProps } from '@adsk/wildcard';
import React, { createContext, useEffect, useRef, useState, useCallback } from 'react';
import {
  CardContentData,
  CardDataSourceSettings,
  LayoutHookReturnType,
  ParameterBaseCardData,
  UnConfiguredCardProps,
  WildCardBaseSettings,
} from '../../../types/layout';
import { CardDataFetcher } from './CardDataFetcher';
import { CardDataLoadingProgress } from './CardDataLoadingProgress';
import { CardErrorState } from './CardErrorState';
import { ErrorBoundary } from 'react-error-boundary'; // https://www.npmjs.com/package/react-error-boundary for API info
import { CardHeaderActions } from './CardHeaderActions';
import {
  baseCardCustomizeStyles,
  baseCardHeaderStyles,
  baseCardHeaderTitleStyles,
  baseCardStyles,
} from './Card.stylesheet';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationDataState, clearAdvancedFactorValue } from '../../../state/slice/application-data-slice';
import { getCardTypeFromCardId, populateNewCardInitialSettings } from '../../utils/layout-setup';
import { CardRegistry } from '../card-registry';
import { ModelViewData } from '../../../types/Lmv';
import { BaseCardConfigurationModal } from '../configuration/BaseCardConfigurationModal';
import { v4 as uuidv4 } from 'uuid';
import { CardEmptyState } from './CardEmptyState';
import { DataPointType, DataPointValue } from '../../../types/metrics';
import Box from '@weave-mui/box';
import { InfoS } from '@weave-mui/icons-weave';
import { tooltipPlacement } from '@weave-mui/enums';
import { InfoTooltipIcon } from '../../../shared/InfoTooltipIcon';
import isequal from 'lodash.isequal';
import DataPointsIconsEnum from '../../../shared/DataPointsIcons/utils/iconEnum';
import DataPointsIcon from '../../../shared/DataPointsIcons/components/DataPointsIcon';
import { removeFactorOverride } from '../../../state/slice/factor-data-slice';

export const CardContentContext = createContext<CardContentData>(null);

export const BaseCard: React.FC<CardComponentProps> = (props) => {
  const { customizeMode, i, settings, saveSetting, cardId, removeCard } = props;
  const cardType = getCardTypeFromCardId(cardId);
  const cardRegistryEntry = CardRegistry[cardType];
  const {
    cardConfigurationSettings: { supportsEditMode, configurationComponent },
    cardDataSourceSettings,
    cardLibrarySettings,
  } = cardRegistryEntry;
  const dispatch = useDispatch();

  // is card newly added from the card library
  const isNewCard = !Object.keys(settings).length && customizeMode;
  const [editModeTriggered, setEditModeTriggered] = useState<boolean>(false);
  const currentCardSettings = useRef<ParameterBaseCardData>();
  const [renderKey, setRenderKey] = useState<string>(uuidv4()); // use to force re-render of card component on re-configuration
  const [erroredCard, setErroredCard] = useState(false);
  const [customCardTitleInfo, setCustomCardTitleInfo] = useState<{
    title: string;
    type: DataPointType;
    tooltip: { title: string; description: string };
  }>(null);
  const cardTitle = settings?.title || 'Card Requires Configuration';
  const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>(false);
  const currentModelId = useSelector(
    (state: { applicationDataState: ApplicationDataState }) =>
      state.applicationDataState.currentModelId
  );
  const iconUrl = cardLibrarySettings?.icon;

  const handleOnMouseEnter = useCallback(() => {
    setIsTooltipVisible(true);
  }, []);

  const handleTooltipClose = useCallback(() => {
    setIsTooltipVisible(false);
  }, []);

  // make sure triggered edit mode is cancelled when grid goes back to view mode
  if (!customizeMode && editModeTriggered) {
    setEditModeTriggered(false);
  }

  const updateCardTitleInfo = (title: string, tooltipTitle: string, tooltipDescription: string, type: DataPointType) => {
    setCustomCardTitleInfo({
      title,
      type,
      tooltip: { title: tooltipTitle, description: tooltipDescription },
    });
    setErroredCard(false);
  };

  useEffect(() => {
    if (isNewCard) {
      // push initial settings to card
      const cardSettings = populateNewCardInitialSettings(cardType);
      Object.entries(cardSettings).forEach(([key, value]) => {
        saveSetting({ i, key, value });
      });
    }
  }, []);

  // handle card switching to a new configuration or reverting an edit operation that has been cancelled
  useEffect(() => {
    const cardSettings = settings as ParameterBaseCardData;
    if (!isequal(currentCardSettings.current, cardSettings)) {
      currentCardSettings.current = cardSettings;
      setRenderKey(uuidv4()); // force re-render of card component
      setErroredCard(false);
    }
  }, [settings]);

  useEffect(() => {
    setRenderKey(uuidv4()); // force re-render of card component
    setErroredCard(false);
  }, [currentModelId]);

  const renderCardComponent = () => {
    // if new card from library just wait for next render cycle with initial settings populated
    if (isNewCard) {
      return <></>;
    }

    const cardHasRequiredSettings =
      cardRegistryEntry?.cardConfigurationSettings.cardHasRequiredSettingsToDisplay(
        settings as WildCardBaseSettings,
        customizeMode
      );

    return (
      (!cardHasRequiredSettings && (
        <UnConfiguredCard currentSettings={settings as WildCardBaseSettings} iconUrl={iconUrl} />
      )) || (
        <ErrorBoundary
          fallback={<CardErrorState />}
          onError={(error, componentStack) => {
            console.error('Card Unhandled Error', error, componentStack);
            setErroredCard(true);
          }}
        >
          <ConfiguredCard
            key={renderKey}
            updateCardTitleInfo={updateCardTitleInfo}
            cardDataSourceSettings={cardDataSourceSettings}
            onCardError={() => {
              setErroredCard(true);
            }}
            {...props}
          />
        </ErrorBoundary>
      )
    );
  };

  const displayCardConfigurator = () => {
    const updateCardSettings = (settings: WildCardBaseSettings) => {
      console.log('updateCardSettings', settings as WildCardBaseSettings);
      Object.entries(settings).forEach(([key, value]) => {
        saveSetting({ i, key, value });
      });
      setEditModeTriggered(false);
      setErroredCard(false);
    };

    return (
      (!isNewCard && editModeTriggered && (
        <BaseCardConfigurationModal
          open={editModeTriggered}
          onClose={() => setEditModeTriggered(false)}
          updateCardSettings={updateCardSettings}
          configComponent={configurationComponent}
          currentSettings={settings as WildCardBaseSettings}
        />
      )) || <></>
    );
  };

  const getIconByType = useCallback((dataPointType: DataPointType): JSX.Element => {
    switch (dataPointType) {
      case DataPointType.Factor:
        return (
          <span>
            <DataPointsIcon icon={DataPointsIconsEnum.BasicFactors} />
          </span>
        );
      case DataPointType.AdvancedFactor: {
        return (
          <span>
            <DataPointsIcon icon={DataPointsIconsEnum.AdvancedFactors} />
          </span>
        );
      }
      default:
        return <InfoS sx={{ cursor: 'default' }} color="action" />;
    }
  }, []);


  return (
    <Card
      className={`h-full card-type-${cardType} ${customizeMode ? 'card-type-edit-mode' : ''}`}
      isCustomizeMode={customizeMode}
      isFullPage={false}
      key={i}
      renderHeaderAction={() =>
        (customizeMode && (
          <CardHeaderActions
            cardSupportsEditMode={supportsEditMode}
            removeCard={() => {
              if ((settings as WildCardBaseSettings)?.type === 'factor') {
                const parameterId = (settings as WildCardBaseSettings).parameterId;
                dispatch(removeFactorOverride(parameterId));
                dispatch(clearAdvancedFactorValue(parameterId));
              }
              removeCard(i);
            }}
            editCard={() => setEditModeTriggered(true)}
          />
        )) || <></>
      }
      title={
        <Box sx={baseCardHeaderStyles}>
          {!erroredCard && customCardTitleInfo?.tooltip ? (
            <InfoTooltipIcon
              icon={getIconByType(customCardTitleInfo?.type)}
              tooltipTitle={customCardTitleInfo?.tooltip?.title}
              tooltipContent={customCardTitleInfo?.tooltip?.description}
              tooltiPlacement={tooltipPlacement.TOP}
              maxWidth={'240px'}
            />
          ) : (
            ''
          )}
          <Box sx={baseCardHeaderTitleStyles}>{customCardTitleInfo?.title || cardTitle}</Box>
        </Box>
      }
      style={!customizeMode ? baseCardStyles : baseCardCustomizeStyles}
    >
      {displayCardConfigurator()}
      {renderCardComponent()}
    </Card>
  );
};

const UnConfiguredCard: React.FC<UnConfiguredCardProps> = ({ currentSettings, iconUrl }) => (
  <CardEmptyState currentSettings={currentSettings} iconUrl={iconUrl}></CardEmptyState>
);

const ConfiguredCard: React.FC<
  CardComponentProps & {
    updateCardTitleInfo: (title: string, tooltipTitle: string, tooltipDescription: string, type: DataPointType) => void;
    cardDataSourceSettings: CardDataSourceSettings;
    onCardError?: () => void;
  }
> = (props) => {
  const {
    settings,
    children,
    updateCardTitleInfo,
    cardDataSourceSettings,
    i,
    saveSetting,
    onCardError,
  } = props;
  const [currentContextData, setCurrentContextData] = useState<CardContentData>({
    data: null,
    settings: settings as WildCardBaseSettings,
    cardId: i,
  });

  // state vars to determine render correct components based on CardDataFetcher results
  const [dataFetcherComplete, setDataFetcherComplete] = useState<boolean>(
    !!cardDataSourceSettings ? false : true
  );

  const [dataFetcherHasError, setDataFetcherHasError] = useState<boolean>(false);
  const currentDataFetcherResults = useRef<LayoutHookReturnType<unknown>>(null);

  // call back function so CardDataFetcher component can pass back results
  const outputResultFunc = (result: LayoutHookReturnType<unknown>) => {
    currentDataFetcherResults.current = result;
    const { isSuccess, isError, isLoading, isFetching, data } = result;

    if ((isLoading || isFetching) && !isError) {
      setDataFetcherComplete(false);
      setDataFetcherHasError(false);
    }

    if (isError) {
      setDataFetcherComplete(true);
      setDataFetcherHasError(true);
      onCardError?.();
    }

    if (isSuccess) {
      const cardSettings = settings as WildCardBaseSettings;
      setCurrentContextData({ settings: cardSettings, data, cardId: i });

      if (cardSettings.type === 'modelView') {
        const { modelName } = data as ModelViewData;
        updateCardTitleInfo(modelName, modelName, '', DataPointType.ModelData);
      }

      if (
        cardSettings.type === 'dataDisplay' ||
        cardSettings.type === 'metric' ||
        cardSettings.type === 'factor'
      ) {
        if (data) {
          const { name, description, error, type } = data as DataPointValue;
          updateCardTitleInfo(name, name, description, type);
          setDataFetcherHasError(!!error);
          // seems counter intuitive, handle where metrics data have loaded but are waiting for some of the
          // parts that make up the metric to be ready e.g. metric could be a mixture of EC & OC data
          // that is complete at different times
          setDataFetcherComplete(!isLoading);
          if (!!error) {
            onCardError?.();
          }
        } else {
          setDataFetcherHasError(true);
          setDataFetcherComplete(true);
          onCardError?.();
        }
      } else {
        setDataFetcherComplete(true);
      }
    }
  };

  const extractCardDataSourceParam = (): string | null => {
    const input =
      cardDataSourceSettings.dataFetcherSupportsInput &&
      cardDataSourceSettings.dataFetcherInputProperty;

    if (!input) {
      return null;
    } else {
      return settings?.[input] || null;
    }
  };

  const renderDataLoadingProgress = () => {
    return (
      (!dataFetcherComplete && (
        <>
          <CardDataLoadingProgress />
        </>
      )) || <></>
    );
  };

  const renderDataLoadingComponent = () => {
    return (
      (!!cardDataSourceSettings && (
        <CardDataFetcher
          hook={
            cardDataSourceSettings.dataFetcherHook
          }
          hookParam={extractCardDataSourceParam()}
          outputResult={outputResultFunc}
        />
      )) || <></>
    );
  };

  function renderCardErrorState() {
    // render error state if data fetcher has completed and has error
    return (dataFetcherComplete && dataFetcherHasError && <CardErrorState />) || <></>;
  }

  const saveCardSetting = (key, value) => {
    saveSetting({ i, key, value });
  };

  const renderCardContent = () => {
    return (
      (dataFetcherComplete && !dataFetcherHasError && (
        <CardContentContext.Provider value={{ ...currentContextData, saveCardSetting }}>
          {children}
        </CardContentContext.Provider>
      )) || <></>
    );
  };

  return (
    <>
      {renderDataLoadingProgress()}
      {renderDataLoadingComponent()}
      {renderCardContent()}
      {renderCardErrorState()}
    </>
  );
};
