import { createApi } from '@reduxjs/toolkit/query/react';
import { BaseQuery, QueryType } from './base-queries';
import {
  POST, simulationsTagType,
  simulationsTagTypes
} from './api-constants';
import { IndustryStandardValue } from '../../types/metrics';
import { AcpEventServiceManager } from '../../notifications/acpEventServiceManager';

export type TaskInputs = {
  parameterId: string;
  industryStandardValue?: IndustryStandardValue;
  imperialStandardValue?: IndustryStandardValue;
  type: string; //'lpd' | 'building-orientation' | 'plug-loads'
};
export type TaskRequest = {
  modelId: string;
  isBaseRun: boolean;
  inputs?: Array<TaskInputs>;
}

export type RequestStatus = 'ACCEPTED' | 'REJECTED' | 'FAILED' | 'UNKNOWN' | 'CANCELED' | 'FULFILLED';
export type JobStatus = 'COMPLETED' | 'FAILED' | 'RUNNING' | 'UNKNOWN' | 'CANCELED' | 'TIMED_OUT';
export type ComputeJobStatus = {
  request: {
    base64RawURLEncodedUrn: string;
    status: RequestStatus;
  };
  job?: {
    urn: string;
    status: JobStatus;
  };
};

export const taskServiceApi = createApi({
  reducerPath: 'taskServiceApi',
  baseQuery: BaseQuery(QueryType.TaskService),
  keepUnusedDataFor: 3600,
  tagTypes: simulationsTagTypes,
  endpoints: (builder) => ({
    submitTask: builder.mutation<void, TaskRequest>({
      query: (body) => ({
        url: `/v1/task/:energy-simulation`,
        method: POST,
        body,
      }),
    }),
    getTasksForModelId: builder.query<Array<ComputeJobStatus>, string>({
      query: (modelId) => `/v1/task/${modelId}`,
      providesTags: (_, __, modelId) => [
        { type: simulationsTagType, id: modelId }
      ],
      onQueryStarted: async (modelId, {queryFulfilled}) => {
        const { data } = await queryFulfilled;
        if (data && data.length > 0) {
          // event service credentials should be already in place, from application start
          AcpEventServiceManager.instance().subscribeToEventsForModelId(modelId, data.length);
        }
      },
    }),
  }),
});

export const {
  useSubmitTaskMutation,
  useGetTasksForModelIdQuery
} = taskServiceApi;
