import React, { useCallback, useEffect, useState } from 'react';
import { DataPointType, DataPointValue, FactorValue } from '../../../types/metrics';
import FormControl from '@weave-mui/form-control';
import MenuItem from '@weave-mui/menu-item';
import Select, { selectVariants } from '@weave-mui/select';
import Box from '@weave-mui/box';
import { formatNumber } from '../../../utils/format';
import { getFactorValueForCurrentUnitSystem } from '../../utils/layout-setup';
import { EllipsisTypography } from '../../../shared/EllipsisTypography';

export const FactorDisplay: React.FC<{
  dataPointValue: DataPointValue;
  onFactorValueChange?: (value: number) => void;
  currentValue?: number;
  configuratorModal?: boolean;
}> = ({ dataPointValue, onFactorValueChange, currentValue, configuratorModal = false }) => {
  const getDefaultValue = useCallback(
    () => currentValue ?? (dataPointValue.value as FactorValue[])[0]?.industryStandardValue.value,
    [currentValue, dataPointValue]
  );

  const [selectedFactorValue, setSelectedFactorValue] = useState<number>(getDefaultValue());

  useEffect(() => {
    setSelectedFactorValue(getDefaultValue());
  }, [currentValue, dataPointValue, getDefaultValue]);

  const handleChange = (e) => {
    const target: number = e.target.value;
    setSelectedFactorValue(target);
    if (onFactorValueChange) {
      onFactorValueChange(target);
    }
  };

  return (
    (((dataPointValue?.type === DataPointType.Factor) || (dataPointValue?.type === DataPointType.AdvancedFactor)) && (
      <Box sx={{ px: '6px' }}>
        <Box sx={{ display: 'flex', mb: 2 }}>
          <EllipsisTypography
            mainValue={formatNumber(getFactorValueForCurrentUnitSystem(selectedFactorValue, dataPointValue), 2)}
            secondaryValue={dataPointValue?.unitSymbol}
            enableTooltip={true} />
        </Box>
        <FormControl sx={{ width: !configuratorModal ? '97%' : '15rem' }}>
          <Select
            variant={selectVariants.FILLED}
            value={selectedFactorValue ?? ''}
            onChange={handleChange}
            displayEmpty
            MenuProps={{
              style: {
                zIndex: 10000,
              },
            }}
            sx={
              configuratorModal
                ? {
                  pointerEvents: 'none',
                }
                : {}
            }
          >
            {(dataPointValue.value as FactorValue[]).map((option) => (
              <MenuItem
                key={option.industryStandardValue.value}
                value={option.industryStandardValue.value}
                style={{ textTransform: 'initial' }}
              >
                {`${option.name}: ${formatNumber(
                  getFactorValueForCurrentUnitSystem(option.industryStandardValue.value, dataPointValue), // when imperial only the display is changed, values are still output as inductry standard
                  2
                )} ${dataPointValue?.unitSymbol}`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    )) || <></>
  );
};
