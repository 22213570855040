import { v4 as uuidv4 } from "uuid";
import { WebTracerProvider } from '@opentelemetry/sdk-trace-web';
import { ConsoleSpanExporter, SimpleSpanProcessor } from '@opentelemetry/sdk-trace-base';
import { trace, Tracer, SpanStatusCode } from '@opentelemetry/api';
import { OTLPTraceExporter } from "@opentelemetry/exporter-trace-otlp-http";

/**
 * Log errors to Dynatrace
 * @class ErrorLogger
 */
class ErrorLogger {
  private _logErrorsToDynatrace = false;
  private _tracer: Tracer;
  private _errorIdLabel = "insightsErrorId";

  constructor() {
    this.setup();
  }

  private setup = (): void => {
    this._logErrorsToDynatrace = true;
    const provider = new WebTracerProvider();
    if (this._logErrorsToDynatrace) {
      provider.addSpanProcessor(new SimpleSpanProcessor(new OTLPTraceExporter()));
    } else {
      provider.addSpanProcessor(new SimpleSpanProcessor(new ConsoleSpanExporter()));
    }
    provider.register();
    this._tracer = trace.getTracer('web-error-tracer');
  };

  /**
   * Log errors to Dynatrace when available or to the console in local environments
   * @param {Error} error
   * @memberof ErrorLogger
   */
  public logError = (error: Error): void => {
    const errorId = uuidv4();
    const span = this._tracer.startSpan(`error-${errorId}`);
    span.recordException(error);
    span.setStatus({ code: SpanStatusCode.ERROR, message: error.message });
    span.end();
    console.error(`An unhandled exception has been logged, ${this._errorIdLabel} = ${errorId}`);
  };
}

const errorLogger = new ErrorLogger();
export default errorLogger;
