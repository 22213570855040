import { GridColDef } from "@weave-mui/data-grid";
import {Benchmark, ISpec, Metric, PostBenchmarkRequest} from "../../types/metrics";
import { BenchmarkMetricsListRow, ReorderedRows } from "../types/benchmarks";
import { getDataPointUnit } from "./unitsUtils";

export const BENCHMARK_NAME_LIMIT = 32;

const METRICS_ASSIGNED_LIMIT = 4;

export const intOnlyExp = /^-?\d*$/;
export const generateCreateBenchmarkRequest = (benchmark: Benchmark, modelId: string): PostBenchmarkRequest => {
  return {
    modelId: modelId,
    name: benchmark.displayName,
    description: benchmark.description,
    metrics: benchmark.metrics,
    value: benchmark.value
  }
};

export const benchmarksMetricsListColumns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Name',
    flex: 1,
  },
  {
    field: 'unit',
    headerName: 'Unit',
    width: 150,
    minWidth: 100
  }
];

export const generateRowsFromMetrics = (metricsList: Metric[], units: Record<string, ISpec>, imperialUnits: boolean): BenchmarkMetricsListRow[] => {
  return metricsList.map(m => {
    return {
      id: m.id,
      name: m.displayName,
      unit: getDataPointUnit(m as Metric, units, imperialUnits).symbol ?? ''
    }
  }).sort((a,b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
};

export const reorderRows = (rows: BenchmarkMetricsListRow[], selectedIds: string[], shouldReturnArray: boolean = true): BenchmarkMetricsListRow[] | ReorderedRows => {
  const splitRows = rows.reduce<ReorderedRows>((acc, currentRow) => {
    selectedIds.includes(currentRow.id)
      ? acc.selected.push(currentRow)
      : acc.unselected.push(currentRow)

    return acc;
  },{selected: [], unselected: []});

  if (shouldReturnArray) return [...splitRows.selected, ...splitRows.unselected]

  return splitRows;
};

export const generateDisabledMetricIds = (benchmarksList: Benchmark[]): string[] => {
  const metricIdOccurances = benchmarksList.reduce<{ [key: string]: number }>((acc, currentBenchmark) => {
    currentBenchmark.metrics?.forEach(metricId => {
      if (acc[metricId]) {
        acc[metricId]++
      } else {
        acc[metricId] = 1;
      }
    });
    return acc;
  },{});

  return Object
    .keys(metricIdOccurances)
    .filter(metricId =>
      metricIdOccurances[metricId] >= METRICS_ASSIGNED_LIMIT
    ) ?? [];
};

export const filterMetricRows = (rows: BenchmarkMetricsListRow[], query: string): BenchmarkMetricsListRow[] => {
  if (!query) return rows;

  return rows.filter((row: BenchmarkMetricsListRow) =>
    row.name?.toLowerCase().includes(query.toLowerCase()) ||
    row.unit?.toLowerCase().includes(query.toLowerCase())
  )
};

export const generateRemovedDisabledMetricsIds = (currentSelectedMetricIds: string[], disabledMetricIds: string[], newSelection: string[]) => {
 const disabledSelectedMetrics = currentSelectedMetricIds.filter(id => disabledMetricIds.includes(id));
 return disabledSelectedMetrics.filter(id => !newSelection.includes(id));
}

