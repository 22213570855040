import { SxProps, Theme } from "@weave-mui/styles";

const styles: Record<string, SxProps<Theme>> = {
  navContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingBottom: '1vh',
    zIndex: '3',
    backgroundColor: '#F5F5F5'
  },
  menuItem: {
    height: '40px',
    columnGap: '0.5rem',
    '& .MuiMenuItemWeave-content': {
      display: 'flex !important',
      flexDirection: 'row',
      alignItems: 'center'
    }
  },
  toggleButtonContainer: {
    widht: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  toggleButtonDivider: {
    alignSelf: 'center',
    width: '90%',
  },
  toggleButtonIcon: {
    alignSelf: 'start',
    display: 'flex',
    justifyContent: 'start',
    width: '30px',
    px: '0.75rem',
    py: '1rem',
    '&:focus': {
      outline: 'none',
      boxShadow: 'none'
    }

  }
};

export default styles;
