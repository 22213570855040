import React from 'react'

const BasicFactorsIcon = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>((props, ref) => (
  <svg data-testid='basic-factors-icon' { ...props } ref={ref} style={{ flexShrink: 0, cursor: 'default' }} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M15 4.16667L7.99999 0L1 4V5L7.99999 8.83333L15 5V4.16667Z" fill="#F3F3F3"/>
    <path d="M8 16L1 11.9048V4L8 8V16Z" fill="#D9D9D9"/>
    <path d="M8 16L15 11.9048V4L8 8V16Z" fill="#BBBBBB"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.99999 1.15175L2 4.58032V11.3312L8 14.8414L14 11.3312V4.58032L7.99999 1.15175ZM15 4L7.99999 0L1 4V11.9048L8 16L15 11.9048V4Z" fill="#666666"/>
  </svg>
));

export default BasicFactorsIcon;
