import React, { useState, useRef, useEffect, useCallback, useMemo, useLayoutEffect } from 'react';
import { useBlocker, useNavigate, useParams } from 'react-router-dom';
import TabContext from '@weave-mui/tab-context';
import TabList from '@weave-mui/tab-list';
import Tab from '@weave-mui/tab';
import Box from '@weave-mui/box';
import useResizeObserver from 'use-resize-observer';
import { v4 as uuidv4 } from 'uuid';
import {
  LayoutTabsConfig,
  LayoutTab,
  DashboardEditMenuOptions,
  StandardDashboard,
  Dashboard,
} from '../../types/layout';
import { LayoutGrid } from '../LayoutGrid';
import {
  layoutBoxStyles,
  layoutTabsContainerBoxStyles,
  layoutTabsContainerFixedBoxStyles,
  layoutTabsWrapperBoxStyles,
  layoutTabListStyles,
  layoutTabPanelWrapperStyles,
  layoutTabPanelStyles,
  layoutTabStyles,
  layoutAddDashboardStyles,
  layoutTabsRightArrowBoxStyles,
  layoutTabsLeftArrowBoxStyles,
  layoutTabsWrapperWithArrowsBoxStyles,
  layoutTabsHiddenArrowBoxStyles,
} from './LayoutTabs.stylesheet';
import { AddS, MoreVerticalS, CaretLeftS, CaretRightS, InfoS } from '@weave-mui/icons-weave';
import { Typography, typographyVariants } from '@weave-mui/material';
import Button, { buttonVariants } from '@weave-mui/button';
import MenuList from '@weave-mui/menu-list';
import MenuItem from '@weave-mui/menu-item';
import Popper, { popperPlacement } from '@weave-mui/popper';

import {
  useCreateDashboardMutation,
  useDeleteDashboardMutation,
  useUpdateDashboardMutation,
} from '../../state/api/dashboard-api';
import {
  createNewDashboard,
  getDuplicatedName,
  getExistingName,
  getNewDashboardName,
} from '../../utils/dashboard';
import { LayoutTabsConfigurationModal } from './LayoutTabsConfigurationModal';
import IconButton from '@weave-mui/icon-button';
import { useLayoutContext } from '../LayoutContext';
import i18n from '../../i18n';
import { dashboardToLayoutTabConfig } from '../utils/layout-setup';
import { getMenuItemLabel } from '../utils/get-menu-item-label';
import Modal from '@weave-mui/modal';
import { footerButtonStyle, headerIconStyle } from '../LayoutGrid.stylesheet';
import { clearAdvancedFactorValues } from '../../state/slice/application-data-slice';
import { useDispatch } from 'react-redux';

export const RENAME_TEXT_INPUT_CHARACTERS_MAX_LIMIT: number = 70;
export const RENAME_TEXT_INPUT_CHARACTERS_MIN_LIMIT: number = 1;
export const MAX_DASHBOARD_LIMIT: number = 10;

export const LayoutTabs: React.FC<LayoutTabsConfig & { initialActiveTab: LayoutTab | null }> = (props) => {
  const { tabs, initialActiveTab = null } = props;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const routeParams = useParams();

  const dashBoardEditMenuOptions: DashboardEditMenuOptions[] = [
    DashboardEditMenuOptions.Rename,
    DashboardEditMenuOptions.Duplicate,
    DashboardEditMenuOptions.Delete,
  ];
  const previousDashboardOperationRef = useRef<{ op: DashboardEditMenuOptions, tabsCount: number } | null>(null);

  const {
    isDirty: layoutStateIsDirty,
    reset: resetLayoutState,
    setCurrentState: setLayoutCurrentState,
    setIsDirty,
    isEditModeActive
  } = useLayoutContext();

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      layoutStateIsDirty && currentLocation.pathname !== nextLocation.pathname
  );

  //Note: this needs to be further arranged based on future active tabs coming from the ds service
  const [currentTabValue, setCurrentTabValue] = useState(initialActiveTab?.value);
  const [currentTab, setCurrentTab] = useState<LayoutTab>(initialActiveTab);
  const [currentSelectedTabName, setCurrentSelectedTabName] = useState(initialActiveTab?.label);
  const anchorElemRef = useRef(null);

  const [popperConfig, setPopperConfig] = useState<{
    open: boolean;
    placement: any;
  }>({
    open: false,
    placement: popperPlacement.RIGHT_START,
  });
  const [launchEditMode, setlaunchEditMode] = useState(false);
  const [forceLoadingOnEditModal, setforceLoadingOnEditModal] = useState(false);
  const [dashboardEditMode, setDashboardEditMode] = useState<DashboardEditMenuOptions | null>(null);

  useEffect(() => {
    if (dashboardEditMode) {
      previousDashboardOperationRef.current = { op: dashboardEditMode, tabsCount: tabs.length };
    }
  }, [dashboardEditMode, tabs]);

  const [createDashboard, { isLoading: isDashboardCreating }] = useCreateDashboardMutation();
  const [deleteDashboard, { isLoading: isDashboardDeleting }] = useDeleteDashboardMutation();
  const [updateDashboard, { isLoading: isDashboardUpdating }] = useUpdateDashboardMutation();

  const tabsContainerRef = useRef<HTMLDivElement>(null);
  const { width: tabsContainerWidth = 0 } = useResizeObserver<HTMLDivElement>({
    ref: tabsContainerRef,
  });
  const tabsListWrapperRef = useRef<HTMLDivElement>(null);
  const { width: tabsListWrapperWidth = 0 } =
    useResizeObserver<HTMLDivElement>({
      ref: tabsListWrapperRef
    });

  const containerScrollRef = useRef<HTMLDivElement>(null);

  const shouldShowArrows = useMemo(
    () => tabsContainerWidth < tabsListWrapperWidth,
    [tabsContainerWidth, tabsListWrapperWidth]
  );

  const [shouldHideLeftArrow, setShouldHideLeftArrow] = useState(true);
  const [shouldHideRightArrow, setShouldHideRightArrow] = useState(false);
  const [namePromptState, setNamePromptState] = useState<{
    value?: string;
    op: DashboardEditMenuOptions;
  } | null>(null);

  const preventLeaveDashboardRef = useRef<{
    next: Function;
    param: any;
  } | null>(null);
  const [shouldPreventLeaveDashboard, setShouldPreventLeaveDashboard] = useState(false);

  useLayoutEffect(() => {
    if (shouldShowArrows &&
      previousDashboardOperationRef.current &&
      previousDashboardOperationRef.current.tabsCount !== tabs.length
    ) {
      switch (previousDashboardOperationRef.current.op) {
        case DashboardEditMenuOptions.Add:
        case DashboardEditMenuOptions.Duplicate:
          containerScrollRef.current?.scrollBy({
            left: tabsListWrapperRef.current?.scrollWidth,
            behavior: 'smooth',
          });
          previousDashboardOperationRef.current = null;

          const scrollLeft = tabsListWrapperRef.current?.scrollWidth;
          checkAndSetArrowsStates(scrollLeft);
          break;
        case DashboardEditMenuOptions.Delete:
          containerScrollRef.current?.scrollTo({
            left: 0,
            behavior: 'smooth',
          });
          previousDashboardOperationRef.current = null;

          checkAndSetArrowsStates(0);
          break;
      }
    }
  }, [tabs, shouldShowArrows]);

  useEffect(() => {
    if (tabsContainerWidth > 0) {
      const selectedTabEl = (document.querySelector('.MuiButtonBase-root.MuiTab-root[aria-selected="true"]') as HTMLDivElement);
      const scrollLeft = selectedTabEl?.offsetLeft;
      if (scrollLeft + selectedTabEl.offsetWidth + 50 >= tabsContainerWidth) {
        checkAndSetArrowsStates(scrollLeft + selectedTabEl.offsetWidth - tabsContainerWidth);
        containerScrollRef.current?.scrollTo({
          left: scrollLeft + selectedTabEl.offsetWidth,
          behavior: 'smooth',
        });
      } else {
        checkAndSetArrowsStates(0);
      }
    }
  }, [tabsContainerWidth]);

  useEffect(() => {
    if (currentTabValue && currentTab && currentTab.value !== currentTabValue) {
      const currentTab = tabs.find((tab) => tab.value === currentTabValue);
      if (currentTab) {
        resetAddDuplicateDashboardState();
        resetLayoutState();
        setCurrentTab(currentTab);
        setCurrentSelectedTabName(currentTab.label);
      }
    }
  }, [tabs, currentTab, currentTabValue]);

  useEffect(() => {
    if (blocker.state === 'blocked') {
      preventLeaveDashboardRef.current = {
        next: (blc) => {
          blc.proceed();
        },
        param: blocker,
      };
    }
  }, [blocker]);

  const resetAddDuplicateDashboardState = () => {
    setNamePromptState(null);
  };

  const addDuplicateDashboardOperation = (op: DashboardEditMenuOptions) => {
    const dashboardNames = tabs.map((tab) => tab.wildCardConfig.dashboard.name);
    if (op === DashboardEditMenuOptions.Add) {
      setNamePromptState({
        op: DashboardEditMenuOptions.Add,
        value: getNewDashboardName(dashboardNames),
      });
    }
    if (op === DashboardEditMenuOptions.Duplicate) {
      const newName = getDuplicatedName(currentSelectedTabName, dashboardNames);
      setNamePromptState({
        op: DashboardEditMenuOptions.Duplicate,
        value: newName,
      });
    }
    setDashboardEditMode(DashboardEditMenuOptions.NamePrompt);
    setlaunchEditMode(true);
  };

  const validateAddDuplicateDashboardOperation = (op: DashboardEditMenuOptions) => {
    if (tabs.length >= MAX_DASHBOARD_LIMIT) {
      setDashboardEditMode(DashboardEditMenuOptions.MaxLimit);
      setlaunchEditMode(true);
      return;
    }

    if (layoutStateIsDirty) {
      preventLeaveDashboardRef.current = {
        next: addDuplicateDashboardOperation,
        param: op,
      };
      setShouldPreventLeaveDashboard(true);
      return;
    }

    addDuplicateDashboardOperation(op);
  };

  const hideModal = () => {
    setlaunchEditMode(false);
    setforceLoadingOnEditModal(false);
  };

  const hideModalAndResetOperation = () => {
    hideModal();
    setDashboardEditMode(null);
    resetAddDuplicateDashboardState();
  };

  const changeActiveTab = async (tab: LayoutTab, cb?: Function) => {
    setCurrentTab(null);
    setTimeout(() => {
      const { projectId, modelId } = routeParams;
      navigate(`/projects/${projectId}/model/${modelId}/layouts/${tab.value}`);
      resetAddDuplicateDashboardState();
      resetLayoutState();
      setCurrentTabValue(tab.value);
      setCurrentTab(tab);
      setCurrentSelectedTabName(tab.label);
      cb?.();
    }, 0);
  };

  const onTabChange = (event: React.SyntheticEvent, newTabValue: string) => {
    const currentTab = tabs.find((tab) => tab.value === newTabValue);
    if (!layoutStateIsDirty) {
      setShouldPreventLeaveDashboard(false);
      // clear all set adv factors with runs between tab changes
      dispatch(clearAdvancedFactorValues());
      changeActiveTab(currentTab);
    } else {
      preventLeaveDashboardRef.current = {
        next: changeActiveTab,
        param: currentTab,
      };
      setShouldPreventLeaveDashboard(true);
    }
  };

  const checkAndSetArrowsStates = (scrollLeft) => {
    const scrollerPaddingLeft = +(containerScrollRef.current
      ? window
        .getComputedStyle(containerScrollRef.current, null)
        .getPropertyValue('padding-left')
        .replace('px', '')
      : 0);

    const scrollerPaddingRight = +(containerScrollRef.current
      ? window
        .getComputedStyle(containerScrollRef.current, null)
        .getPropertyValue('padding-right')
        .replace('px', '')
      : 0);

    setShouldHideLeftArrow(scrollLeft <= 0);
    setShouldHideRightArrow(
      Math.ceil(
        containerScrollRef.current?.offsetWidth +
        scrollLeft -
        scrollerPaddingLeft -
        scrollerPaddingRight || 0
      ) >= (containerScrollRef.current?.children[0] as HTMLDivElement)?.offsetWidth
    );
  };

  const moveTabs = useCallback(
    (direction) => () => {
      const scrollAmount = 200;
      const scrollLeft = Math.max(
        containerScrollRef.current?.scrollLeft + scrollAmount * (direction === 'left' ? -1 : 1),
        0
      );
      checkAndSetArrowsStates(scrollLeft);

      containerScrollRef.current?.scrollBy({
        left: scrollAmount * (direction === 'left' ? -1 : 1),
        behavior: 'smooth',
      });
    },
    []
  );

  const handleClick = (e) => {
    const svgRect = anchorElemRef.current.getBoundingClientRect();
    const tabsWrapperWidth = tabsContainerRef.current.offsetWidth;
    const shouldAppendToTheRight = svgRect.x + 110 < tabsWrapperWidth;
    setPopperConfig({
      open: true,
      placement: shouldAppendToTheRight ? popperPlacement.RIGHT_START : popperPlacement.LEFT_START,
    });
  };

  const onConfigModelButtonClick = async (editMode, tabname?) => {
    if (editMode === DashboardEditMenuOptions.NamePrompt) {
      if (namePromptState.op === DashboardEditMenuOptions.Add) {
        addNewDashboard(tabname);
      }
      if (namePromptState.op === DashboardEditMenuOptions.Duplicate) {
        duplicateDashboard(tabname);
      }
      return;
    }
    if (editMode === DashboardEditMenuOptions.Delete) {
      setforceLoadingOnEditModal(true);
      try {
        const res: any = await deleteDashboard(currentTabValue);
        if (res.error) {
          throw res.error;
        }
        hideModalAndResetOperation();
        changeActiveTab(tabs[0]);
      } catch (e) {
        hideModalAndResetOperation();
      }
    }
    if (editMode === DashboardEditMenuOptions.Rename && currentSelectedTabName !== tabname) {
      setforceLoadingOnEditModal(true);
      const currentTab = tabs.find((tab) => tab.value === currentTabValue);
      const { isStandardDashboard, ...userDashboard } = currentTab.wildCardConfig
        .dashboard as StandardDashboard;
      let updateTabName;
      if (tabname && tabname !== '') {
        updateTabName = tabname;
      } else {
        updateTabName = 'Missing dashboard title';
      }
      try {
        const res: any = await updateDashboard({
          ...userDashboard,
          name: updateTabName
        });
        if (res.error) {
          throw res.error;
        }
        hideModal();
        setCurrentSelectedTabName(updateTabName);
        setCurrentTab({
          ...currentTab,
          label: updateTabName,
          wildCardConfig: {
            ...currentTab.wildCardConfig,
            dashboard: {
              ...currentTab.wildCardConfig.dashboard,
              name: updateTabName,
            },
          },
        });
      } catch (e) {
        hideModal();
      }
    } else {
      setlaunchEditMode(false);
    }
    resetLayoutState();
    setDashboardEditMode(null);
  };

  const handleConfigMenuItemClick = async (operation: DashboardEditMenuOptions) => {
    setPopperConfig({
      open: false,
      placement: popperConfig.placement,
    });
    setDashboardEditMode(operation);
    if (operation === DashboardEditMenuOptions.Duplicate) {
      validateAddDuplicateDashboardOperation(DashboardEditMenuOptions.Duplicate);
      return;
    }
    setlaunchEditMode(true);
  };

  const duplicateDashboard = async (name?: string) => {
    const { isStandardDashboard, ...userDashboard } = currentTab.wildCardConfig
      .dashboard as StandardDashboard; // optomistic cast to StandardDashboard
    const dashboardNames = tabs.map((tab) => tab.wildCardConfig.dashboard.name);
    const newName = name || getDuplicatedName(userDashboard.name, dashboardNames);
    setforceLoadingOnEditModal(true);
    setDashboardEditMode(DashboardEditMenuOptions.Duplicate);
    setlaunchEditMode(true);
    const dashboardData = {
      ...userDashboard,
      name: newName,
      dashboardId: uuidv4(),
    };
    try {
      const res: any = await createDashboard(dashboardData);
      if (res.error) {
        throw res.error;
      }
      const newestLayoutTab = dashboardToLayoutTabConfig(dashboardData);
      changeActiveTab(newestLayoutTab);
      hideModalAndResetOperation();
    } catch (e) {
      hideModalAndResetOperation();
    }
  };

  const addNewDashboard = async (name?: string) => {
    setPopperConfig({
      open: false,
      placement: popperConfig.placement,
    });
    const dashboardNames = tabs.map((tab) => tab.wildCardConfig.dashboard.name);
    const newName = name
      ? getExistingName(name, dashboardNames)
      : getNewDashboardName(dashboardNames);
    // create a new dashboard object
    const newDashboard = createNewDashboard({
      name: newName,
    });
    setforceLoadingOnEditModal(true);
    setDashboardEditMode(DashboardEditMenuOptions.Add);
    setlaunchEditMode(true);
    try {
      const res: any = await createDashboard(newDashboard);
      if (res.error) {
        throw res.error;
      }
      hideModalAndResetOperation();
      changeActiveTab(dashboardToLayoutTabConfig(newDashboard));
    } catch (e) {
      hideModalAndResetOperation();
    }
  };

  const handleAddDashboardClick = () => {
    validateAddDuplicateDashboardOperation(DashboardEditMenuOptions.Add);
  };

  const onCustomSaveAction = async (updatedDashboard: Dashboard) => {
    const updatedLayout = dashboardToLayoutTabConfig(updatedDashboard);
    setCurrentTab(updatedLayout);

    setDashboardEditMode(DashboardEditMenuOptions.Update);
    setlaunchEditMode(true);
    try {
      setLayoutCurrentState(null);
      await updateDashboard(updatedDashboard).unwrap();
      setIsDirty(false);
    } catch (err) {
      console.log('err', err);
      setlaunchEditMode(false);
      setDashboardEditMode(null);
    }
    setDashboardEditMode(null);
    setlaunchEditMode(false);
  };

  const onCloseLayoutTabsConfigurationModal = () => {
    setlaunchEditMode(false);
    setNamePromptState(null);
    setDashboardEditMode(null);
  };

  const renderTabsList = () => {
    return (
      <>
        <TabList
          align="left"
          onChange={onTabChange}
          orientation="horizontal"
          sx={layoutTabListStyles}
          variant="fullWidth"
          showTabDivider={true}
        >
          {tabs.map((tab: LayoutTab) => {
            return (
              <Tab
                icon={
                  tab.value === currentTab?.value && (
                    <MoreVerticalS
                      onClick={handleClick}
                      ref={anchorElemRef}
                      sx={{
                        'visibility': isEditModeActive ? 'hidden' : 'visible'
                      }}
                    />
                  )
                }
                iconPosition="end"
                label={tab.label}
                value={tab.value}
                sx={layoutTabStyles}
              />
            );
          })}
          <Button
            variant="text"
            onClick={handleAddDashboardClick}
            sx={{
              ...layoutAddDashboardStyles,
              '&:hover': {
                boxShadow: 'none'
              },
            }}
          >
            <AddS />
          </Button>
        </TabList>
      </>
    );
  };
  const renderTabsEditMenu = () => {
    // Options to be disabled on standard dashboard
    const disableOptionsOnStandard = [
      DashboardEditMenuOptions.Rename,
      DashboardEditMenuOptions.Delete,
    ];
    return (
      <>
        <Popper
          anchorEl={anchorElemRef.current}
          arrow={true}
          onClose={() =>
            setPopperConfig({
              open: false,
              placement: popperConfig.placement,
            })
          }
          placement={popperConfig.placement}
          open={popperConfig.open}
          sx={{ zIndex: '20000' }}
        >
          <MenuList sx={{ padding: '0px !important' }}>
            {dashBoardEditMenuOptions?.map((item, index) => (
              <MenuItem
                id={item}
                onClick={(e) => handleConfigMenuItemClick(item)}
                sx={{ padding: '2px 0 2px 2px' }}
                disabled={
                  currentTab?.isStandardDashboard &&
                  disableOptionsOnStandard.includes(item as DashboardEditMenuOptions)
                }
              >
                <Typography variant={typographyVariants.BODY_REGULAR}>
                  {getMenuItemLabel(item)}
                </Typography>
              </MenuItem>
            ))}
          </MenuList>
        </Popper>
        <LayoutTabsConfigurationModal
          open={launchEditMode}
          onClose={onCloseLayoutTabsConfigurationModal}
          editMode={dashboardEditMode}
          tabName={currentSelectedTabName}
          onConfigModelButtonClick={onConfigModelButtonClick}
          dashboardOperationStatus={
            isDashboardUpdating ||
            isDashboardDeleting ||
            isDashboardCreating ||
            dashboardEditMode === DashboardEditMenuOptions.Duplicate ||
            forceLoadingOnEditModal
          }
          tabs={tabs}
          isDashboardDeleting={isDashboardDeleting}
          namePromptState={namePromptState}
          currentTab={currentTab}
        />
      </>
    );
  };
  const renderTabsPanel = () => {
    return (
      <Box sx={layoutTabPanelWrapperStyles}>
        <Box sx={layoutTabPanelStyles}>
          {(currentTab?.wildCardConfig && (
            <LayoutGrid
              isStandardDashboard={currentTab.isStandardDashboard}
              {...currentTab.wildCardConfig}
              onCustomSaveAction={onCustomSaveAction}
              isDashboardUpdating={isDashboardUpdating}
            />
          )) || <></>}
        </Box>
      </Box>
    );
  };

  const aboutToLeaveCancelHandler = () => {
    setShouldPreventLeaveDashboard(false);

    setTimeout(() => {
      (tabsContainerRef.current?.querySelector(':focus') as HTMLElement)?.blur();
    }, 0);
  };

  const aboutToLeaveOkHandler = () => {
    preventLeaveDashboardRef.current?.next(preventLeaveDashboardRef.current.param);
    preventLeaveDashboardRef.current = null;
    setShouldPreventLeaveDashboard(false);
    setTimeout(() => {
      (tabsContainerRef.current?.querySelector(':focus') as HTMLElement)?.blur();
    }, 0);
  };

  const renderFooter = () => {
    return (
      <Box
        display="flex"
        justifyContent="end"
        gap={3}>
        <Button
          onClick={aboutToLeaveCancelHandler}
          variant={buttonVariants.OUTLINED}
        >
          {i18n.t('dashboard.modal.preventLeaveDashboardModal.cancel')}
        </Button>
        <Button sx={{ footerButtonStyle }} size='large' onClick={aboutToLeaveOkHandler}>
          {i18n.t('dashboard.modal.preventLeaveDashboardModal.ok')}
        </Button>
      </Box>
    )
  };

  const renderAboutToLeaveModal = () => {
    return (
      shouldPreventLeaveDashboard && (
        <Modal
          actions={renderFooter()}
          sx={{ '& .MuiModalWeave-box': { width: '400px', } }}
          open={true}
          onClose={aboutToLeaveCancelHandler}
          header={
            <Typography variant={typographyVariants.H3_MEDIUM}>
              <InfoS color="info" style={headerIconStyle} />
              {i18n.t('dashboard.modal.preventLeaveDashboardModal.title')}
            </Typography>
          }
        >
          <Box>
            <Typography variant={typographyVariants.BODY_REGULAR}>
              {i18n.t('dashboard.modal.preventLeaveDashboardModal.body')}
            </Typography>
            <Typography variant={typographyVariants.BODY_REGULAR} sx={{ mt: 1, mb: 2 }}>
              {i18n.t('dashboard.modal.preventLeaveDashboardModal.body2')}
            </Typography>
          </Box>
        </Modal>
      )
    );
  };

  const renderTabs = () => {
    return (
      <Box sx={layoutTabsContainerBoxStyles} ref={tabsContainerRef}>
        <Box sx={layoutTabsContainerFixedBoxStyles}>
          {shouldShowArrows && (
            <IconButton
              variant="dynamic"
              sx={{
                ...layoutTabsLeftArrowBoxStyles,
                ...(shouldHideLeftArrow ? layoutTabsHiddenArrowBoxStyles : {}),
              }}
              onClick={moveTabs('left')}
            >
              <CaretLeftS />
            </IconButton>
          )}
          <Box
            sx={{ overflowX: 'hidden', ...(shouldShowArrows ? layoutTabsWrapperWithArrowsBoxStyles : {}) }}
            ref={containerScrollRef}
          >
            <Box sx={layoutTabsWrapperBoxStyles} ref={tabsListWrapperRef}>
              {renderTabsList()}
            </Box>
          </Box>
          {shouldShowArrows && (
            <IconButton
              variant="dynamic"
              sx={{
                ...layoutTabsRightArrowBoxStyles,
                ...(shouldHideRightArrow ? layoutTabsHiddenArrowBoxStyles : {}),
              }}
              onClick={moveTabs('right')}
            >
              <CaretRightS />
            </IconButton>
          )}
        </Box>
      </Box>
    );
  };

  return (
    <Box sx={layoutBoxStyles}>
      <TabContext value={currentTabValue}>
        {renderTabs()}
        {renderTabsPanel()}
        {renderTabsEditMenu()}
        {renderAboutToLeaveModal()}
      </TabContext>
    </Box>
  );
};
