import { createApi } from '@reduxjs/toolkit/query/react';
import { User } from '../../analysis/dashboard.models';
import { BaseQuery, QueryType } from './base-queries';
import { AcpEventServiceManager } from '../../notifications/acpEventServiceManager';

export const userDataApi = createApi({
  reducerPath: 'userDataApi',
  baseQuery: BaseQuery(QueryType.UserData),
  endpoints: (builder) => ({
    getUserData: builder.query<User, void>({
      query: () => `/userinformation`,
      onCacheEntryAdded: async (_, { cacheDataLoaded, getCacheEntry, cacheEntryRemoved }) => {
        await cacheDataLoaded;

        const cacheEntry = getCacheEntry();
        if (cacheEntry.isSuccess && !cacheEntry.isError && cacheEntry.data) {
          AcpEventServiceManager.instance(cacheEntry.data.acpEventCredentials);
        }

        await cacheEntryRemoved;
      }
    }),
    getUserEntitlements: builder.query<{ isAuthorized: boolean }, void>({
      query: () => `/entitlements`,
      transformResponse: (_, meta) => ({ isAuthorized: meta.response.status === 200 }),
    })
  }),
});

export const {
  useGetUserDataQuery,
  useLazyGetUserDataQuery,
  useGetUserEntitlementsQuery,
  useLazyGetUserEntitlementsQuery,
} = userDataApi;
export const useGetUserDataQueryState = userDataApi.endpoints.getUserData.useQueryState;
