import React, { useCallback, useState } from 'react';
import MenuItem from '@weave-mui/menu-item';
import { Collapse, ListItemText } from '@mui/material';
import { ParametersTypesEnum } from '../../../../types/metrics';
import { SideNavigationOption } from './SideMenu';
import styles from '../../styles/sideNavigation.styles';
import SideMenuIcon from './SideMenuIcon';
import Box from '@weave-mui/box';
import Tooltip, { tooltipPlacement, tooltipSize } from '@weave-mui/tooltip';
import Typography, { typographyVariants } from '@weave-mui/typography';

interface TooltipMenuItemProps {
  collapsed: boolean;
  navigationOption: SideNavigationOption;
  onOptionClick: (navOption: ParametersTypesEnum) => void;
}

const TooltipMenuItem: React.FC<TooltipMenuItemProps> = ({ collapsed,  navigationOption, onOptionClick }) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  
  const handleOnMouseEnter = useCallback(() => {
    setShowTooltip(true);
  },[]);

  const handleTooltipClose = useCallback(() => {
    setShowTooltip(false);
  },[]);
  const optionClickHandler = useCallback(() => onOptionClick(navigationOption.modalType), []);

  return (
    <Tooltip
      arrow
      enterDelay={500}
      enterNextDelay={1000}
      leaveDelay={0}
      title={
        <Box>
          <Typography
            data-testid={`menu-item-tooltip-title-${navigationOption.modalType}`}
            variant={typographyVariants.CAPTION_BOLD}
          >
            {navigationOption.descriptionTitle}
            </Typography>
        </Box>
      }
      description={
        <Box>
          <div style={{ marginTop: '14px' }}></div>
          <Typography 
            data-testid={`menu-item-tooltip-description-${navigationOption.modalType}`}
            variant={typographyVariants.CAPTION_REGULAR}
          >
            {navigationOption.descriptionBody}
          </Typography>
        </Box>
      }
      open={showTooltip}
      size={tooltipSize.MEDIUM}
      onClose={handleTooltipClose}
      placement={tooltipPlacement.RIGHT}
      PopperProps={{
        style: {
          zIndex: 9000
        },
      }}
    >
      <MenuItem 
        key={navigationOption.title}
        onClick={optionClickHandler}
        onMouseEnter={handleOnMouseEnter}
        data-testid={`menu-item-${navigationOption.modalType}`}
        sx={{
          ...styles.menuItem,
          padding: `0.5rem 0.75rem 0.5rem 0.75rem !important`,
        }}
      >
        <SideMenuIcon navigationOption={navigationOption}/>
        <Collapse
          in={!collapsed}
          orientation='horizontal'
          timeout={1}
          unmountOnExit
        >
          <ListItemText
            data-testid={`menu-item-text-${navigationOption.modalType}`}
          >
            {navigationOption.title}
          </ListItemText>
        </Collapse>
      </MenuItem>
    </Tooltip>
  );
};

export default TooltipMenuItem;
