import { LayoutHookReturnType } from '../../../types/layout';
import { AnalysisResult, DataPointsResult, DataPointType, FactorValue, UnitSystem } from '../../../types/metrics';
import { useDataPointsV2 } from './useDataPointsV2';
import { createSelector } from '@reduxjs/toolkit';
import {
  flattenDataPointDefinitions,
  getDataPointResultFromDefinition
} from '../../../dataPoints/utils/dataPointsUtils';
import { sanitizeToken } from '../../../dataPoints/utils/formulaUtils';
import { RootState } from '../../../state/store';
import { useSelector } from 'react-redux';

const getDataPoints = (result: { dataPoints: DataPointsResult; runId: string }) => result;
const memoizedFlattenDataPoints = createSelector([getDataPoints], (result) =>
  flattenDataPointDefinitions(result.dataPoints?.definitions)
);

export const useValuesFromDataPointsV2 = (): LayoutHookReturnType<
  Map<UnitSystem, Map<string, number>>
> => {
  const { data: dataPoints, isLoading, isSuccess, isFetching, isError } = useDataPointsV2();
  const selectedAnalysisRun = useSelector(
    (state: RootState) => state.applicationDataState.currentAnalysisRunId
  );

  if (isLoading || isError) {
    return {
      data: null,
      isLoading,
      isSuccess,
      isError,
      isFetching,
    };
  }

  const flat = memoizedFlattenDataPoints({ dataPoints, runId: selectedAnalysisRun });

  const params = flat
    .filter(
      (dp) =>
        dp.type === DataPointType.AnalysisResult ||
        dp.type === DataPointType.ModelData ||
        dp.type === DataPointType.Factor ||
        dp.type === DataPointType.AdvancedFactor
    )
    .reduce(
      (acc, dp) => {
        const dpValue = getDataPointResultFromDefinition(dp, dataPoints, selectedAnalysisRun);
        if (!dpValue) {
          return acc;
        }
        let metricValue: number = null;
        let imperialValue: number = null;

        switch (dp.type) {
          case DataPointType.AnalysisResult:
          case DataPointType.ModelData:
            const analysisResult = dpValue as AnalysisResult;
            metricValue = analysisResult?.industryStandardValue?.value ?? analysisResult.value;
            imperialValue = analysisResult?.imperialStandardValue?.value ?? analysisResult.value;
            // value = (useImperial ?
            //   analysisResult?.imperialStandardValue?.value :
            //   analysisResult?.industryStandardValue?.value) ?? analysisResult.value;
            break;
          case DataPointType.AdvancedFactor:
          case DataPointType.Factor:
            const factorValue = (dpValue as FactorValue[])?.[0];
            metricValue = factorValue?.industryStandardValue?.value ?? factorValue?.value ?? 0;
            imperialValue = factorValue?.imperialStandardValue?.value ?? factorValue?.value ?? 0;
            // value = (useImperial ?
            //   factorValue?.imperialStandardValue?.value :
            //   factorValue?.industryStandardValue?.value) ?? factorValue.value;
            break;
        }
        metricValue = metricValue ?? 0;
        imperialValue = imperialValue ?? 0; //if value is NULL set 0 as default so that formula evaluation doesn't fail
        // if (value === null || value === 0) {
        //   value = 0; //if value is NULL set 0 as default so that formula evaluation doesn't fail
        // }
        const sanitizedFormulaToken = sanitizeToken(dp.id);
        acc.get(UnitSystem.Metric).set(sanitizedFormulaToken, metricValue);
        acc.get(UnitSystem.Imperial).set(sanitizedFormulaToken, imperialValue);
        //acc.set(sanitizeToken(dp.id), value);
        return acc;
      },
      new Map<UnitSystem, Map<string, number>>([
        [UnitSystem.Metric, new Map<string, number>()],
        [UnitSystem.Imperial, new Map<string, number>()],
      ])
    );
  return {
    data: params,
    isError: false,
    isLoading: false,
    isSuccess: true,
    isFetching: false,
  };
};
