import React from 'react'

const AdvancedFactorsIcon = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>((props, ref) => (
  <svg data-testid='advanced-factors-icon' { ...props } ref={ref} style={{ flexShrink: 0, cursor: 'default' }} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_2064_14112)">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M7.99999 0L15 4.16663V4.99996L11.2442 7.05669C10.0547 7.23706 9.00274 7.83652 8.2437 8.69979L7.99999 8.83325L1 4.99995V3.99996L7.99999 0Z" fill="#F3F3F3"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M1 11.9048L8 16V15.0005C7.37209 14.1647 7 13.1258 7 12C7 10.8742 7.37209 9.83526 8 8.99951V8L1 4V11.9048Z" fill="#D9D9D9"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M8.58871 15.6556L8 16V15.0005C8.17682 15.2358 8.37392 15.4551 8.58871 15.6556ZM15 7.99963C14.1643 7.37194 13.1256 7 12 7C10.3644 7 8.91223 7.78534 8 8.99951V8L15 4V7.99963Z" fill="#BBBBBB"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M2 4.58032L8 1.15186L14 4.58032V7.41602C14.357 7.57202 14.6923 7.76831 15 7.99951V4L8 0L1 4V11.9048L8 16L8.58868 15.6555C8.29388 15.3804 8.03247 15.0698 7.81104 14.731L2 11.3313V4.58032Z" fill="#666666"/>
      <path d="M12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16Z" fill="#F3F3F3"/>
      <path d="M12 10H11V13H14V12H12V10Z" fill="#666666"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M12 8C11.2089 8 10.4355 8.2346 9.77772 8.67412C9.11993 9.11365 8.60723 9.73836 8.30448 10.4693C8.00173 11.2002 7.92252 12.0044 8.07686 12.7804C8.2312 13.5563 8.61216 14.269 9.17157 14.8284C9.73098 15.3878 10.4437 15.7688 11.2196 15.9231C11.9956 16.0775 12.7998 15.9983 13.5307 15.6955C14.2616 15.3928 14.8864 14.8801 15.3259 14.2223C15.7654 13.5645 16 12.7911 16 12C16 10.9391 15.5786 9.92172 14.8284 9.17157C14.0783 8.42143 13.0609 8 12 8ZM12 9C12.5933 9 13.1734 9.17595 13.6667 9.50559C14.1601 9.83524 14.5446 10.3038 14.7716 10.8519C14.9987 11.4001 15.0581 12.0033 14.9424 12.5853C14.8266 13.1672 14.5409 13.7018 14.1213 14.1213C13.7018 14.5409 13.1672 14.8266 12.5853 14.9424C12.0033 15.0581 11.4001 14.9987 10.852 14.7716C10.3038 14.5446 9.83524 14.1601 9.50559 13.6667C9.17595 13.1734 9 12.5933 9 12C9 11.2044 9.31607 10.4413 9.87868 9.87868C10.4413 9.31607 11.2044 9 12 9Z" fill="#666666"/>
    </g>
    <defs>
      <clipPath id="clip0_2064_14112">
        <rect width="16" height="16" fill="white"/>
      </clipPath>
    </defs>
  </svg>
));

export default AdvancedFactorsIcon;
