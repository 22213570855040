import React, { useContext, useEffect, useRef } from 'react';
import { CardContentContext } from './base/BaseCard';
import { DataPointType, DataPointValue, FactorValue } from '../../types/metrics';
import { factorCardDataStyles } from './base/Card.stylesheet';
import { FactorDisplay } from './shared/FactorDisplay';
import { FactorCardData } from '../../types/layout';
import { useCardGrid } from '@adsk/wildcard';
import { useDispatch } from 'react-redux';
import { setFactorOverride } from '../../state/slice/factor-data-slice';
import Box from '@weave-mui/box';
import { getFactorValueForCurrentUnitSystem } from '../utils/layout-setup';
import { useSetCurrentAnalysisRunAdvancedFactorValueV2 } from '../hooks/v2/useAnalysisRunV2';

/**
 * Used to locate the factor value in the factor data point
 * TODO: This should be handled by an id field on the factor value
 */
export type FactorValueLocator = {
  posn: number;
  value: number;
  name: string;
  simulationParameterId?: string;
  advancedFactorId?: string;
};

export const FactorCard: React.FC = () => {
  const { data, settings, cardId } = useContext(CardContentContext);
  const { state, saveSetting } = useCardGrid();
  const factorSettings = settings as FactorCardData;
  const factorValueLocatorRef = useRef<FactorValueLocator>(null);
  const factorDataPoint = data as DataPointValue;
  const dispatch = useDispatch();
  const setCurrentAdvancedFactorValueAndRun = useSetCurrentAnalysisRunAdvancedFactorValueV2();
  const isAdvancedFactor = factorDataPoint.type === DataPointType.AdvancedFactor;

  const dispatchFactorOverride = (value: number) => {
    dispatch(
      setFactorOverride({
        factorId: factorSettings.parameterId,
        value: getFactorValueForCurrentUnitSystem(value, factorDataPoint),
      })
    );
  };

  const getFactorLocatorFromValue = (factorValue: number): FactorValueLocator => {
    const factorDataPointValues = factorDataPoint?.value as FactorValue[];
    const factorPosn = factorDataPointValues.findIndex(
      (fv) => fv.industryStandardValue.value === factorValue
    );
    const posn = factorPosn > -1 ? factorPosn : 0;
    const {
      name,
      industryStandardValue: { value },
    } = factorDataPointValues[posn];
    const simulationParameterId = factorDataPoint.simulationFactor;
    if (isAdvancedFactor) {
      setCurrentAdvancedFactorValueAndRun({
        advancedFactorId: factorDataPoint.id,
        simulationfactorId: simulationParameterId,
        value: factorDataPointValues[posn],
        revertToBase: false,
      });
    }
    return {
      posn,
      value,
      name,
      simulationParameterId,
      advancedFactorId: factorDataPoint.id,
    };
  };

  const checkFactorLocatorChanges = (factorLocator: FactorValueLocator) => {
    const factorDataPointValues = factorDataPoint?.value as FactorValue[];
    const factorNameMatchPosn = factorDataPointValues.findIndex(
      (x) => x.name === factorLocator.name
    );
    const factorValueMatchPosn = factorDataPointValues.findIndex(
      (x) => x.value === factorLocator.value
    );

    if (factorNameMatchPosn > -1) {
      factorLocator.value = factorDataPointValues[factorNameMatchPosn].industryStandardValue.value;
      factorLocator.posn = factorNameMatchPosn;
    } else if (factorValueMatchPosn > -1) {
      factorLocator.value = factorDataPointValues[factorValueMatchPosn].industryStandardValue.value;
      factorLocator.name = factorDataPointValues[factorValueMatchPosn].name;
      factorLocator.posn = factorValueMatchPosn;
    } else {
      factorLocator.posn = 0;
      factorLocator.value = factorDataPointValues[0].industryStandardValue.value;
      factorLocator.name = factorDataPointValues[0].name;
    }

    dispatchFactorOverride(factorLocator.value);
  };

  useEffect(() => {
    factorValueLocatorRef.current = getFactorLocatorFromValue(factorSettings?.value);
    dispatchFactorOverride(factorValueLocatorRef.current.value);
  }, [settings]);

  useEffect(() => {
    checkFactorLocatorChanges(factorValueLocatorRef.current);
  }, [factorDataPoint]);

  useEffect(() => {
    if (state.customizeMode) {
      saveSetting({ i: cardId, key: 'value', value: factorValueLocatorRef.current.value });
    }
  }, [state.customizeMode]);

  // this is unique behaviour to the factor card
  // not really best practice to have the card content updating it's settings
  const onFactorValueChange = (factorValue: number) => {
    const locator = getFactorLocatorFromValue(factorValue);
    factorValueLocatorRef.current = locator;
    if (state.customizeMode) {
      saveSetting({ i: cardId, key: 'value', value: factorValue }); // factor values saved in settings as industry standard values
    }

    dispatchFactorOverride(factorValue);
  };

  return (
    <Box sx={factorCardDataStyles}>
      <FactorDisplay
        dataPointValue={factorDataPoint}
        currentValue={factorValueLocatorRef.current?.value}
        onFactorValueChange={onFactorValueChange}
      />
    </Box>
  );
};
