import React, { useCallback } from 'react';
import i18n from '../i18n';
import { ToolTipButtonWrapper } from './ToolTipButtonWrapper';
import { useDataPointsV2 } from '../layout/hooks/v2/useDataPointsV2';
import { getAdvancedFactorDefinitionAndValuesFromSimulationFactorId } from '../layout/utils/dataPointsResultsUtils';
import { addNotification } from '../state/slice/notifications-slice';
import { useDispatch } from 'react-redux';
import { taskServiceApi, useSubmitTaskMutation } from '../state/api/task-service-api';
import { simulationsTagType } from '../state/api/api-constants';

export const SubmitSimulation: React.FC<{ modelId: string }> = ({ modelId }) => {
  const dispatch = useDispatch();
  const [submitSimulationRequest] = useSubmitTaskMutation();
  const { data: dataPointsResult, isSuccess: isGetDataPointsResultSuccess } = useDataPointsV2();

  const onSimulateChange = useCallback(async () => {
    // POC submit runs for LPD
    const simulationInfo = dataPointsResult.definitions?.advancedFactors
      ?.filter((definition) => definition?.simulationFactor)
      .map((definition) =>
        getAdvancedFactorDefinitionAndValuesFromSimulationFactorId(
          definition?.simulationFactor,
          dataPointsResult
        )
      )
      .find(
        ({ simulationFactorDefinition }) =>
          simulationFactorDefinition.simulationParameter.type === 'lpd'
      );

    if (
      !simulationInfo ||
      !simulationInfo?.simulationFactorDefinition ||
      !simulationInfo?.values?.length
    ) {
      dispatch(
        addNotification({
          message: 'Error Submitting Simulation Task: Invalid simulation parameter',
          type: 'error',
          autoHideDuration: 3500,
        })
      );
      return;
    }
    // notify start of simulation
    dispatch(
      addNotification({
        message: 'Submitted Simulation Task.',
        type: 'info',
        autoHideDuration: 3500,
      })
    );

    //isolate the ModelId string
    const split = modelId.split('model:');
    const strippedModelId = split.length > 1 ? split[1] : modelId;

    const submitError = () => {
      dispatch(
        addNotification({
          message: 'Error Submitting Simulation Task.',
          type: 'warning',
          autoHideDuration: 3500,
        })
      );
    };

    const { simulationFactorDefinition, values: factorValues } = simulationInfo;
    const { simulationParameter } = simulationFactorDefinition;

    try {
      const simulationRequests = factorValues.map((factorValue) => {
        return submitSimulationRequest({
          modelId: strippedModelId,
          isBaseRun: false,
          inputs: [
            {
              parameterId: simulationFactorDefinition.id, //send the underlying simulation param id (not the advanced factor as this can be removed by the user)
              industryStandardValue: factorValue.industryStandardValue,
              imperialStandardValue: factorValue.imperialStandardValue,
              type: simulationParameter.type,
            },
          ],
        })
          .unwrap()
          .catch((error) => submitError());
      });

      await Promise.all(simulationRequests);
      dispatch(taskServiceApi.util.invalidateTags([{ type: simulationsTagType, id: strippedModelId }]));
    } catch (err) {
      submitError();
    }
  }, [modelId, dataPointsResult]);

  return (
    (isGetDataPointsResultSuccess && (
      <ToolTipButtonWrapper
        toolTipTitle={i18n.t('topnav.buttons.simulate')}
        description={i18n.t('topnav.buttons.descriptionSimulation')}
        onButtonClickCallBack={onSimulateChange}
        buttonTitle={i18n.t('topnav.buttons.simulate')}
        customStyle={{ marginLeft: '8px' }}
      ></ToolTipButtonWrapper>
    )) || <> </>
  );
};
