import React, { useContext } from 'react';
import { CardContentContext } from './base/BaseCard';
import { simpleCardDataStyles } from './base/Card.stylesheet';
import { DataPointValue } from '../../types/metrics';
import { DataPointDisplay } from './shared/DataPointDisplay';
import Box from '@weave-mui/box';
import { EllipsisTypography } from '../../shared/EllipsisTypography';
import { InfoTooltipIcon } from '../../shared/InfoTooltipIcon';
import i18n from '../../i18n';
import { InfoS } from '@weave-mui/icons-weave';
import { tooltipPlacement } from '@weave-mui/enums';


export const DataDisplayCard: React.FC = () => {
  const { data } = useContext(CardContentContext);

  return (
    <Box sx={{ ...simpleCardDataStyles, display: 'flex', paddingRight: '4px' }}>
      {(!(data as DataPointValue).isComputed && (
        <>
          <InfoTooltipIcon
            icon={
              <InfoS
                sx={{ paddingRight: '5px', paddingBottom: '4px', cursor: 'pointer' }}
                color="info"
              />
            }
            tooltipContent={i18n.t('cardConfig.requireSimulationText')}
            tooltipTitle={i18n.t('cardConfig.requireSimulation')}
            tooltiPlacement={tooltipPlacement.BOTTOM_START}
            maxWidth={'300px'}
          />
          <EllipsisTypography
            mainValue={i18n.t('cardConfig.notApplicableText') as string}
            secondaryValue={''}
            enableTooltip={false}
          />
        </>
      )) || <DataPointDisplay dataPointValue={data as DataPointValue} enableTooltip={true} />}
    </Box>
  );
};
