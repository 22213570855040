
import {DataPointsDefinitionsResult, DataPointsResult, DataPointType, FactorDefinition} from "../../types/metrics";
import {MaybeDrafted} from "@reduxjs/toolkit/dist/query/core/buildThunks";
import { UpdateMetricRequest } from "../../types/requests/parameters";
import { UpdateBenchmarkRequest } from "../../types/requests/benchmarks";

const datapointTypeFieldMap = new Map<DataPointType, string>([
  [DataPointType.Metric, "metricsData"],
  [DataPointType.Factor, "factorsData"],
  [DataPointType.Benchmark, "benchmarksData"],
  [DataPointType.AdvancedFactor, "advancedFactorsData"]
]);

const datapoinTypeDefinitionFieldMap = new Map<DataPointType, string>([
  [DataPointType.Metric, "metrics"],
  [DataPointType.Factor, "factors"],
  [DataPointType.Benchmark, "benchmarks"],
  [DataPointType.AdvancedFactor, "advancedFactors"]
]);

export const removeDataPointFromCache = (parameterId: string, draft: MaybeDrafted<any>, dataType: DataPointType) => {
  const field = datapointTypeFieldMap.get(dataType);
  if(!field) return;
  const dataPointIndex = draft[field].findIndex((m) => m.id === parameterId);
  if (dataPointIndex > -1) {
    draft[field].splice(dataPointIndex, 1);
  } else {
    console.log(`${dataType} not found ${parameterId}`);
  }
}

export const removeDataPointDefinitionFromCache = (
  parameterId: string,
  draft: MaybeDrafted<DataPointsDefinitionsResult>,
  dataType: DataPointType
) => {
  const field = datapoinTypeDefinitionFieldMap.get(dataType);
  if (!field) return;

  const dataPointIndex = draft.definitions[field].findIndex(d => d.id === parameterId);
  if (dataPointIndex > -1) {
    draft.definitions[field].splice(dataPointIndex, 1);
    if (dataType === DataPointType.Factor || dataType === DataPointType.AdvancedFactor) {
      delete draft.definitionsData.factorsData[parameterId];
    }
  }
};

const editMetricFunction = (body: any, draft: MaybeDrafted<any>, dataPointIndex: number) => {
  // custom metrics apply updated unit (if present) to both imperial and industry standard units
  const applyUnitFields = !draft.metricsData[dataPointIndex].isGlobal && body?.unit;
  Object.assign(draft.metricsData[dataPointIndex], {
    ...draft.metricsData[dataPointIndex],
    ...body,
    ...(applyUnitFields && {
      imperialStandardUnitId: body.unit,
      industryStandardUnitId: body.unit,
    }),
  });
}

const editMetricDefinitionFunction  = (
  body: UpdateMetricRequest,
  draft: MaybeDrafted<DataPointsDefinitionsResult>,
  dataPointIndex: number
) => {
  const applyUnitFields = !draft.definitions.metrics[dataPointIndex].isGlobal && body?.unit;
  Object.assign(draft.definitions.metrics[dataPointIndex], {
    ...draft.definitions.metrics[dataPointIndex],
    ...body,
    ...(applyUnitFields && {
      imperialStandardUnitId: body.unit,
      industryStandardUnitId: body.unit
    })
  });
};

const editFactorFunction = (body: any, draft: MaybeDrafted<any>, datapointIndex: number, data: any) => {
  Object.assign(draft.factorsData[datapointIndex], { ...body });
  draft.factorsData[datapointIndex].dataPointValue.values =
    data?.values ?? body.values ?? draft.factorsData[datapointIndex].dataPointValue.values;
}

const editAdvancedFactorFunction = (body: any, draft: MaybeDrafted<any>, datapointIndex: number, data: any) => {
  Object.assign(draft.advancedFactorsData[datapointIndex], { ...body, ...(body.name?.length > 0 && {displayName: body.name}) });

}

const editFactorDefinitionFunction = (
  body: any,
  draft: MaybeDrafted<DataPointsDefinitionsResult>,
  dataPointIndex: number,
  data: any
) => {
  const factorDefinition = { ...body };

  delete factorDefinition.values;
  Object.assign(draft.definitions.factors[dataPointIndex], { ... factorDefinition });
  draft.definitionsData.factorsData[factorDefinition.id] =
    data?.values ?? body.values ?? draft.definitionsData.factorsData[factorDefinition.id];
}

const editAdvancedFactorDefinitionFunction = (
  body: any,
  draft: MaybeDrafted<DataPointsDefinitionsResult>,
  dataPointIndex: number,
  data: any
) => {
  const factorDefinition = {
    ...body,
    ...(body.name?.length > 0 && {displayName: body.name})
  };
  if (factorDefinition?.description?.length < 0 ) {
    factorDefinition.description = data.description;
  }
  delete factorDefinition.values;
  Object.assign(draft.definitions.advancedFactors[dataPointIndex], { ...factorDefinition });
}


const editBenchmarkFunction = (body: any, draft: MaybeDrafted<any>, datapointIndex: number) => {
  Object.assign(draft.benchmarksData[datapointIndex], { ...body });
  draft.benchmarksData[datapointIndex].metrics =
    body.metrics ?? draft.benchmarksData[datapointIndex].metrics;
}

const editBenchmarkDefinitionFunction = (
  body: UpdateBenchmarkRequest,
  draft: MaybeDrafted<DataPointsDefinitionsResult>,
  datapointIndex: number
) => {
  Object.assign(draft.definitions.benchmarks[datapointIndex], { ...body });
  draft.definitions.benchmarks[datapointIndex].metrics =
    body.metrics ?? draft.definitions.benchmarks[datapointIndex].metrics;
}

export const updateDataPointDefinitionFromCache = (parameterId: string, draft: MaybeDrafted<DataPointsDefinitionsResult>, dataType: DataPointType, body: any, data?: any) => {
  const field = datapoinTypeDefinitionFieldMap.get(dataType);
  if (!field) return;

  const dataPointIndex = draft.definitions[field].findIndex(d => d.id === parameterId);
  if (dataPointIndex > -1) {
    switch (dataType) {
      case DataPointType.Metric:
        editMetricDefinitionFunction(body, draft, dataPointIndex);
        break;
      case DataPointType.Factor:
        editFactorDefinitionFunction(body, draft, dataPointIndex, data);
        break;
      case DataPointType.Benchmark:
        editBenchmarkDefinitionFunction(body, draft, dataPointIndex);
        break;
      case DataPointType.AdvancedFactor:
        editAdvancedFactorDefinitionFunction(body, draft, dataPointIndex, data);
        break;
      default:
        break;
    }
  }
};

export const updateDatapointFromCache = (parameterId: string, draft: MaybeDrafted<any>, dataType: DataPointType, body: any, data?: any) => {
  const field = datapointTypeFieldMap.get(dataType);
  if(!field) return;
  const dataPointIndex = draft[field].findIndex((m) => m.id === parameterId);
  if (dataPointIndex > -1) {
    switch (dataType){
      case DataPointType.Metric:
        editMetricFunction(body, draft, dataPointIndex);
        break;
      case DataPointType.Factor:
        editFactorFunction(body, draft, dataPointIndex, data);
        break;
      case DataPointType.Benchmark:
        editBenchmarkFunction(body, draft, dataPointIndex);
        break;
      case DataPointType.AdvancedFactor:
        editAdvancedFactorFunction(body, draft, dataPointIndex, data);
    }
  }
}


