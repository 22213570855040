import { CreateMetricRequest, UpdateMetricRequest } from './requests/parameters';
import { CreateFactorRequest, OverrideFactorRequest, UpdateFactorRequest } from './requests/factors';
import {CreateBenchmarkRequest, UpdateBenchmarkRequest} from "./requests/benchmarks";
import { AnalysisRun } from './jobs';
import {
  CreateAdvancedFactorRequest,
  OverrideAdvancedFactorRequest,
  UpdateAdvancedFactorRequest
} from './requests/advancedFactors';

export enum ParametersTypesEnum {
  METRICS = 'metrics',
  FACTORS = 'factors',
  BENCHMARKS = 'benchmarks',
  ADVANCED_FACTORS = 'advancedFactors'
}

export type ParametersModalType = ParametersTypesEnum.METRICS | ParametersTypesEnum.FACTORS | ParametersTypesEnum.BENCHMARKS | ParametersTypesEnum.ADVANCED_FACTORS;

export type DataPoints = {
  modelData: DataPoint[];
  ecAnalysisData: DataPoint[];
  energyAnalysisData: DataPoint[];
  metricsData: Metric[];
  factorsData: Factor[];
  benchmarksData: Benchmark[];
  simulationFactorsData: SimulationFactor[];
  advancedFactorsData: AdvancedFactor[];
};

export enum DataPointType {
  Factor,
  AnalysisResult,
  ModelData,
  Metric,
  Benchmark,
  SimulationParameter,
  AdvancedFactor,
}

export enum UnitSystem {
  Metric,
  Imperial,
}

export interface DataPointCommonBase {
  readonly id: string;
  readonly name: string;
  displayName: string;
  description: string;
  type: DataPointType;
}

export interface DataPointBase extends DataPointCommonBase {
  readonly dataType?: string;
  unit?: string;
  industryStandardUnitId?: string;
  imperialStandardUnitId?: string;
  isGlobal: boolean;
  isHidden?: boolean;
}

export interface AnalysisResult {
  value: number;
  industryStandardValue?: IndustryStandardValue;
  imperialStandardValue?: IndustryStandardValue;
  isLoading: boolean;
  isPartial?: boolean;
  isError?: boolean;
}

export interface FactorValues {
  values: FactorValue[];
}

export interface Factor extends DataPointBase {
  dataPointValue: FactorValues;
  unitSystem?: UnitSystem; //used for custom Factors
}

export interface SimulationFactor extends Factor {
  simulationParameter: SimulationParameter;
}

export interface SimulationParameter {
  name: string;
  type: string;
  useImperial?: boolean;
}

export interface AdvancedFactor extends Factor {
  simulationFactor: string;
}

export interface FactorValue {
  name: string;
  value: number;
  isDefault?: boolean;
  imperialStandardValue?: IndustryStandardValue;
  industryStandardValue?: IndustryStandardValue;
}

export interface IndustryStandardValue {
  value: number;
  typeId: string;
}

export type Unit = {
  id: string;
  name: string;
  useSI: boolean;
  symbol: string;
};

export interface ISpec {
  name: string;
  disciplineId?: string;
  applicableUnits?: Record<string, Unit>;
  storageUnitId?: string;
}

export interface Metric extends DataPointBase {
  formula: string;
  breakdownFormulas?: FormulaBreakdownResult[];
  unitSystem?: UnitSystem; //used for custom Metrics
}

export type PostMetricRequest = CreateMetricRequest & { modelId: string }
export type PatchMetricRequest = UpdateMetricRequest & { modelId?: string }
export type PostFactorRequest = CreateFactorRequest & { modelId: string }
export type PatchFactorRequest = UpdateFactorRequest & { modelId: string; }
export type PatchAdvancedFactorRequest = UpdateAdvancedFactorRequest & { modelId?: string}
export type PatchDefaultFactorRequest = OverrideFactorRequest & { modelId: string }
export type PatchDefaultAdvancedFactorRequest = OverrideAdvancedFactorRequest & { modelId?: string}
export type PostBenchmarkRequest = CreateBenchmarkRequest & { modelId: string}
export type PatchBenchmarkRequest = UpdateBenchmarkRequest & { modelId: string}
export type PostAdvancedFactorRequest = CreateAdvancedFactorRequest & { modelId: string}

export interface RenameDataPointRequest {
  id: string;
  displayName: string;
  modelId: string;
}

export interface FormulaBreakdownResult {
  expression: string;
  name?: string;
}

export interface DataPoint extends DataPointBase {
  dataPointPath: string;
  dataPointValue: AnalysisResult;
}

export interface Benchmark extends DataPointCommonBase {
  value: number;
  metrics: string[];
}

export type FormulaToken = {
  isParameter: boolean;
  parameter: DataPointBase | string;
  unit: string;
};

export type DataPointValue = {
  id: string;
  simulationFactor?: string;
  name: string;
  description?: string;
  value:
    | string
    | number
    | MetricEvaluationResult
    | FactorValue[];
  breakdown?: FormulaBreakdownItem[];
  unitSymbol: string;
  type: DataPointType;
  isPartialResult?: boolean;
  isComputed?: boolean;
  warningMessage?: string;
  error?: {
    reason: string;
  };
  isImperial: boolean;
  benchmarks?: Benchmark[];
};

export enum EnumEvaluationStatus {
  Success,
  GeneralError,
  EmptyFormula,
  CircularReference,
  DivideByZero,
  SyntaxError,
}

export type MetricEvaluationResult = {
  result: number;
  isError: boolean;
  isWarning?: boolean;
  evaluationStatus: EnumEvaluationStatus;
  evaluationError: string;
  arguments: string[];
};

export type MetricEvaluationFormatedResult = MetricEvaluationResult & { formattedResult: string };

export type FormulaBreakdownItem = {
  name: string;
  value: number | null | undefined;
};

export type FormulaSelectionChangedEvent = {
  formula: string;
  cursorPositionInText: number;
  previousNodes: string;
};

export type FormulaCalculationFunction = (
  formula: string,
  formulaOwnerMetricId: string,
  useImperial?: boolean,
  factorOverrides?: Record<string, number>,
  extractMetricArguments?: boolean
) => MetricEvaluationResult;

export interface DataPointsNotification {
  title: string;
  text: string;
  textPrimaryBn: string;
  textSecondaryBn: string;
}

// data points v2 ******************************************************************************************************
export interface AnalysisDataPointDefinition extends DataPointBase {
  dataPointPath: string;
  sourceUnitId?: string;
}

export interface MetricDefinition extends DataPointBase {
  formula: string;
  breakdownFormulas?: FormulaBreakdownResult[];
  unitSystem?: UnitSystem;
}

export interface FactorDefinition extends DataPointBase {
  unitSystem?: UnitSystem; //used for custom Factors
}

export interface SimulationFactorDefinition extends FactorDefinition {
  simulationParameter: SimulationParameter;
}

export interface AdvancedFactorDefinition extends FactorDefinition {
  simulationFactor: string;
}

export type DataPointsDefinitionType =
  | AnalysisDataPointDefinition
  | MetricDefinition
  | FactorDefinition
  | SimulationFactorDefinition
  | AdvancedFactorDefinition;

export type DataPointsDefinitions = {
  model: AnalysisDataPointDefinition[];
  ecAnalysis: AnalysisDataPointDefinition[];
  energyAnalysis: AnalysisDataPointDefinition[];
  metrics: MetricDefinition[];
  factors: FactorDefinition[];
  benchmarks: Benchmark[];
  simulationFactors: SimulationFactorDefinition[];
  advancedFactors: AdvancedFactorDefinition[];
};

export type Results = {
  [parameterId: string]: Results
};

export type Result = {
  [runId: string]: AnalysisResult;
}

export type AnalysisDataResult = {
  analysisRuns: AnalysisRun[];
  analysisResults: Results;
}

export type DataPointsResult = {
  definitions: DataPointsDefinitions;
  factorsData: Record<string, FactorValue[]>;
} & AnalysisDataResult;


export type DefinitionsData = {
  factorsData: Record<string, FactorValue[]>;
};

export type DataPointsDefinitionsResult = {
  definitions: DataPointsDefinitions;
  definitionsData: DefinitionsData;
};

export type AdvancedFactorCurrentValue = {
  advancedFactorId: string;
  simulationfactorId: string;
  value: FactorValue;
  revertToBase: boolean;
};

// type guard
export const isDataPointsDefinitionType = (obj: any): obj is DataPointsDefinitionType => {
  return (
    typeof obj === 'object' && obj !== null && obj.dataPointValue === undefined
  );
};
