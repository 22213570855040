import PubNub, { CryptoModule } from 'pubnub';
import { dataPointsServiceApi } from '../state/api/data-service-api';
import { JobStatus } from '../state/api/task-service-api';
import store from '../state/init-store';
import { userDataApi } from '../state/api/user-data-api';
import { EventServiceCredentials } from '../analysis/dashboard.models';
import { dataPointsV2TagType } from '../state/api/api-constants';

type PubNubMessageResponse = {
  id: string;
  source: string;       // "urn:com.autodesk.forge:moniker:autodesk.acp.dev"
  specversion: string;  // "1.0"
  type: string;         // "autodesk.acp.dev:execution.updated-1.0.0"
  subject: string;      // "urn:autodesk.acp.dev:execution.updated:dXJuOmF1dG9kZXNrOmNvbXB1dGU6ZGV2OmpvYnM6ZXhlY3V0aW9uczpIOEZxT3dZWnhHYWM2UE9xNGRUTE5wNVdIWEhHV09iOTplYWE5MGZkMy03ZDZlLTQ4ZTQtOWUzMy1mOWMwODI0YmFmOTg"
  time: string;         //"2024-11-27T16:31:53Z"
  data:
  {
    payload:
    {
      XXX_executionArn: string; // ""
      XXX_machineFlowchart: string; // ""
      allowedCancelation: string; // 0
      base64RawURLEncodedUrn: string; // "dXJuOmF1dG9kZXNrOmNvbXB1dGU6ZGV2OmpvYnM6ZXhlY3V0aW9uczpIOEZxT3dZWnhHYWM2UE9xNGRUTE5wNVdIWEhHV09iOTplYWE5MGZkMy03ZDZlLTQ4ZTQtOWUzMy1mOWMwODI0YmFmOTg",
      childExecutionRequests: {} // null,
      definition:
      {
        JobDefinitionMetadata:
        {
          urn: string; // "urn:autodesk:compute:dev:definitions:jobs:insightx:energysimulation",
          base64RawURLEncodedUrn: string; // "dXJuOmF1dG9kZXNrOmNvbXB1dGU6ZGV2OmRlZmluaXRpb25zOmpvYnM6aW5zaWdodHg6ZW5lcmd5c2ltdWxhdGlvbg"
        }
      },
      duration:
      {
        seconds: number; // 76,
        nanos: number; // 832000000
      },
      environmentVariables: string[]; // null,
      forgeVersionId: string;
      hurl: string;
      isInteractive: boolean,
      payload:
      {
        XXX_input:
        {
          RunTask:
          {
            heartbeatSeconds: number;
            index: number; // -1,
            inputPath: string; // "$",
            jobUrn: string; // "urn:autodesk:compute:dev:jobs:executions:H8FqOwYZxGac6POq4dTLNp5WHXHGWOb9:eaa90fd3-7d6e-48e4-9e33-f9c0824baf98",
            resource: string; // "urn:autodesk:compute:dev:definitions:workers:insightx:energysimulation",
            taskName: string; // "RunTask"
          }
        },
        isBaseRun: boolean; // true,
        modelId: string; // "f491cac6-e401-4d06-b0c4-03afffae3dd8"
      },
      requestInfo:
      {
        RequestMetadata:
        {
          urn: string; // "urn:autodesk:compute:dev:jobs:requests:H8FqOwYZxGac6POq4dTLNp5WHXHGWOb9:ca457e26-975d-40e2-919d-34d3adf3b1d7",
          base64RawURLEncodedUrn: string; // "dXJuOmF1dG9kZXNrOmNvbXB1dGU6ZGV2OmpvYnM6cmVxdWVzdHM6SDhGcU93WVp4R2FjNlBPcTRkVExOcDVXSFhIR1dPYjk6Y2E0NTdlMjYtOTc1ZC00MGUyLTkxOWQtMzRkM2FkZjNiMWQ3"
        }
      },
      startedAt:
      {
        seconds: number; // 1732724981,
        nanos: number; // 983000000
      },
      status: JobStatus; // "COMPLETED",
      timestamps:
      {
        createdAt:
        {
          seconds: number; // 1732724981,
          nanos: number; // 983000000
        },
        lastUpdatedAt:
        {
          seconds: number; // 1732725113,
          nanos: number; // 506761737
        },
        createdAtUnix: number; // 1732724981,
        lastUpdatedAtUnix: number; // 1732725113
      },
      urn: string; // "urn:autodesk:compute:dev:jobs:executions:H8FqOwYZxGac6POq4dTLNp5WHXHGWOb9:eaa90fd3-7d6e-48e4-9e33-f9c0824baf98"
    }
  }
}

export class AcpEventServiceManager {
  ////////////////////////////////////////////////////////////
  /// singleton area
  private static _instance: AcpEventServiceManager;
  public static instance(credentials?: EventServiceCredentials) {
    this._instance = this._instance ?? new AcpEventServiceManager(credentials);
    return this._instance;
  }
  ////////////////////////////////////////////////////////////

  private _moniker = '';
  private _pubNubInstance: PubNub;
  private _cryptoModule: PubNub.NodeCryptoModule;
  private _listenedJobs = 0;
  private constructor(credentials: EventServiceCredentials) {
    const { data} = userDataApi.endpoints.getUserData.select()(store.getState())
    this._moniker = data.appMoniker;
    const userId = `${btoa(data.autodeskId).replace(/={1,2}$/, '')}`;

    // initialisation
    this._pubNubInstance = new PubNub({
      subscribeKey: credentials.pubNubSubscriptionKey,
      userId: userId
    })

    this._cryptoModule = CryptoModule.aesCbcCryptoModule({cipherKey: credentials.pubNubCipherKey});
  }

  private onMessage = (messageEvent: PubNub.Subscription.Message) => {
    const decryptedMessage = this._cryptoModule.decrypt(messageEvent.message.toString()) as unknown as PubNubMessageResponse;
    this._listenedJobs--;
    console.log(`Completed job ${decryptedMessage.data.payload.base64RawURLEncodedUrn} with status ${decryptedMessage.data.payload.status}, ${this._listenedJobs} remaining`);
    /// TO DO: in the next iteration, log the status of failed jobs per modelId in store as a map of modelId to { analysisType, errorMessage }
    if ( this._listenedJobs <= 0 ){
      console.log(`Completed task ${decryptedMessage.data.payload.requestInfo.RequestMetadata.base64RawURLEncodedUrn}`);
      store.dispatch(dataPointsServiceApi.util.invalidateTags([{type: dataPointsV2TagType, id: `${decryptedMessage.data.payload.payload.modelId}` }]));

      this._pubNubInstance.destroy();
      this._pubNubInstance.unsubscribeAll();
      if (this._listenedJobs < 0) {
        this._listenedJobs = 0;
      }
    }
  }

  subscribeToEventsForModelId(modelId: string, jobNumbers: number) {
    this._pubNubInstance.destroy();
    this._pubNubInstance.unsubscribeAll(); // necessary. otherwise we'll get messages twice, if we subscribe to the same modelId again.

    console.log(`Following channel: ${this._moniker}-${modelId}`);
    const channel = this._pubNubInstance.channel(`${this._moniker}-${modelId}`);
    const subscription = channel.subscription();
    subscription.onMessage = this.onMessage;
    subscription.subscribe();
    this._listenedJobs = jobNumbers; // don't add jobs to current jobs; from tasks endpoint, we already get the real number of jobs running
  }
}
