import {
  DataPoint,
  DataPointsDefinitionType,
  EnumEvaluationStatus,
  Factor,
  ISpec,
  Metric,
  MetricEvaluationResult,
} from '../../types/metrics';
import * as mathJs from 'mathjs';
import { SymbolNode } from 'mathjs';
import {
  deSanitizeToken,
  extractParametersFromFormula,
  sanitizeFormula,
  sanitizeToken,
} from '../utils/formulaUtils';
import i18n from '../../i18n';

export class FormulaParser {
  private readonly _allDataPoints: Map<
    string,
    DataPoint | Metric | Factor | DataPointsDefinitionType
  >;
  private readonly _allUnits: Record<string, ISpec>;
  private readonly _useImperial: boolean;

  constructor(
    allDataPoints: Map<string, DataPoint | Metric | Factor | DataPointsDefinitionType>,
    allUnits: Record<string, ISpec>,
    useImperial: boolean
  ) {
    this._allDataPoints = allDataPoints;
    this._allUnits = allUnits;
    this._useImperial = useImperial;
  }

  public calculateFormula = (
    formula: string,
    formulaOwnerMetricId: string,
    evaluatedParams: Map<string, number>,
    extractMetricArguments: boolean = false
  ): MetricEvaluationResult => {
    const failedResult: MetricEvaluationResult = {
      result: null,
      isError: true,
      evaluationStatus: EnumEvaluationStatus.GeneralError,
      evaluationError: i18n.t('analysis.dataPoints.metrics.errorFormula'),
      arguments: undefined,
    };

    if (!formula) {
      return {
        ...failedResult,
        evaluationStatus: EnumEvaluationStatus.EmptyFormula,
        evaluationError: '',
      };
    }

    const { isValidFormula, formulaTree } = this.tryCreatedDependencyTree(
      formula,
      formulaOwnerMetricId
    );
    if (!isValidFormula) {
      return {
        ...failedResult,
        evaluationStatus: EnumEvaluationStatus.CircularReference,
        evaluationError: i18n.t('analysis.dataPoints.metrics.circularReference'),
      };
    }

    let metricArguments: string[] = undefined;
    if (extractMetricArguments) {
      const formulas = [formula, ...formulaTree.map((m) => m.formula)];
      metricArguments = this.extractMetricsArguments(formulas);
    }

    const updatedEvaluatedParams = this.calculateMetricDependencies(formulaTree, evaluatedParams);
    const parsedFormula = sanitizeFormula(formula);
    let returnValue: number | null = null;
    try {
      returnValue = mathJs.evaluate(parsedFormula, updatedEvaluatedParams);
    } catch (ex) {
      return {
        ...failedResult,
        evaluationStatus: EnumEvaluationStatus.SyntaxError,
        evaluationError: i18n.t('analysis.dataPoints.metrics.invalidFormula'),
      };
    }

    if (!Number.isFinite(returnValue)) {
      return {
        ...failedResult,
        isError: false,
        isWarning: true,
        evaluationStatus: EnumEvaluationStatus.DivideByZero,
        evaluationError: i18n.t('analysis.dataPoints.metrics.divideByZero'),
      };
    }

    return {
      result: +returnValue,
      isError: false,
      evaluationStatus: EnumEvaluationStatus.Success,
      evaluationError: '',
      arguments: metricArguments,
    };
  };

  //Recursively create the dependency tree from the metric: case when we have metrics that depend on other metrics and so on.
  //Returns array containing the metrics in the order that need to be evaluated.
  //If there is a circular metric dependency and the tree cannot be created the method reruns isValidFormula false and null for results.
  //For not the method relies on stackoveflow exception to detect circular dependencies
  private tryCreatedDependencyTree = (
    formula: string,
    formulaOwnerMetricId: string
  ): { isValidFormula: boolean; formulaTree: Metric[] } => {
    const metrics = this.extractMetricParameters(formula);
    const result: Metric[] = [];
    try {
      for (const metric of metrics) {
        const cleanDependency = this.fillMetrics(metric.formula, result, formulaOwnerMetricId);
        if (!cleanDependency) {
          return { isValidFormula: false, formulaTree: null };
        }

        result.push(metric);
      }
      return { isValidFormula: true, formulaTree: result };
    } catch (e) {
      console.error('Invalid Formula. Could not create dependency tree', e);
      return { isValidFormula: false, formulaTree: null };
    }
  };
  private extractMetricsArguments = (formulas: string[]): string[] => {
    const result = formulas.reduce((acc, formula) => {
      const parsed = mathJs.parse(sanitizeFormula(formula));
      parsed
        .filter((n) => (n as SymbolNode).isSymbolNode)
        .map((symbol: SymbolNode) => acc.add(deSanitizeToken(symbol.name)));
      return acc;
    }, new Set<string>());
    return Array.from(result);
  };
  private calculateMetricDependencies = (
    metrics: Metric[],
    evaluatedParams: Map<string, number>
  ): Map<string, number> => {
    const updatedEvaluatedParams = new Map(evaluatedParams);
    return metrics.reduce((acc, metric) => {
      const sFormula = sanitizeFormula(metric.formula);
      //add metric result evaluation into the params map
      acc.set(sanitizeToken(metric.id), +mathJs.evaluate(sFormula, acc));
      return acc;
    }, updatedEvaluatedParams);
  };

  private fillMetrics = (
    formula: string | undefined,
    result: any[],
    formulaOwnerMetricId: string
  ): boolean => {
    if (!formula) {
      return false;
    }
    const metrics = this.extractMetricParameters(formula);
    for (const metric of metrics) {
      if (metric.id === formulaOwnerMetricId) {
        // breaker: parent, original id was found again, circular dependency certain
        return false;
      }

      this.fillMetrics(metric.formula, result, formulaOwnerMetricId);
      result.push(metric);
    }

    return true;
  };

  private extractMetricParameters = (formula: string): Metric[] => {
    const tokens = extractParametersFromFormula(
      formula,
      this._allDataPoints,
      this._allUnits,
      this._useImperial
    );
    const metrics = tokens
      .filter((t) => t && (t.parameter as Metric)?.formula)
      .map((t) => t.parameter as Metric);
    if (metrics.length === 0) {
      return [];
    }
    return metrics;
  };
}
