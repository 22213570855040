import {useDataPoints} from "./useDataPoints";
import {createSelector} from "@reduxjs/toolkit";
import {dataPointsMetricBenchmarksMap} from "../../dataPoints/utils/dataPointsUtils";
import {Benchmark, DataPoints} from "../../types/metrics";
import {LayoutHookReturnType} from "../../types/layout";

const getDataPoints = (dataPoints: DataPoints) => dataPoints;
const memoizedMetricsWithBenchmarks = createSelector([getDataPoints], (dataPoints) =>
  dataPointsMetricBenchmarksMap(dataPoints.benchmarksData)
);
export const useBenchmarks = (): LayoutHookReturnType<Map<string, Benchmark[]>> => {
  const {
    data: dataPoints,
    isLoading: isLoadingDataPoints,
    isSuccess: isSuccessDataPoints,
    isError: isErrorDataPoints,
    isFetching: isFetchingDataPoints,
  } = useDataPoints();


  let isLoading: boolean =
    isLoadingDataPoints;
  let isError: boolean =
    isErrorDataPoints;
  const isSuccess: boolean =
    isSuccessDataPoints;
  const isFetching: boolean =
    isFetchingDataPoints;

  let result = {isSuccess, isError, isFetching, isLoading, data: null};

  if (isSuccess) {
    result = {
      ...result,
      data: memoizedMetricsWithBenchmarks(dataPoints)
    }
  }
  return result;
}
