import React, {Dispatch, SetStateAction, useCallback, useEffect, useState} from 'react';
import {
  AdvancedFactor,
  DataPointType,
  Factor,
  ISpec,
  Metric,
  SimulationFactor
} from '../../../../types/metrics';
import {useGetUnitsQuery} from '../../../../state/api/data-service-api';
import i18n from '../../../../i18n';
import FormControl from '@weave-mui/form-control';
import FormLabel, {formLabelVariants} from '@weave-mui/form-label';
import Dropdown from '@weave-design/dropdown';
import {unitsDropdownStylesheet} from '../../../styles/metrics.stylesheet';
import {newDataPointId, UNIT_DISPLAY_CUSTOM_CHARACTER_LIMIT} from '../../../utils/dataPointsUtils';
import TextField, {textFieldVariants} from '@weave-mui/text-field';
import {CardDataLoadingProgress} from '../../../../layout/cards/base/CardDataLoadingProgress';
import {CustomUnitId, findSpec, findUnit, hasCustomUnit} from '../../../utils/unitsUtils';
import {ErrorS, InfoS} from '@weave-mui/icons-weave';
import Box from '@weave-mui/box';
import {isLongerThanAllowed} from "../../../../analysis/EmbodiedCarbon/AddECDefinition/helper";
import {
  errorIconStyles,
  maximumCharactersErrorStyles
} from "../../../../analysis/EmbodiedCarbon/AddECDefinition/ECDefinition.stylesheet";
import Typography, {typographyVariants} from "@weave-mui/typography";
import { isAdvancedFactor } from '../../../utils/factorsUtils';

interface UnitsContainerProps {
  selectedDataPoint: Metric | Factor | AdvancedFactor |undefined;
  simulationFactors: SimulationFactor[];
  isReadOnly: boolean;
  setCreateDataPoint: Dispatch<SetStateAction<Metric | Factor>>;
  useImperial: boolean;
}
const { BODY_REGULAR } = typographyVariants;

const UnitsContainer: React.FC<UnitsContainerProps> = ({
  selectedDataPoint,
  isReadOnly,
  setCreateDataPoint,
  useImperial,
  simulationFactors
}) => {
  const { data: allUnits, isLoading: isLoadingUnits } = useGetUnitsQuery();
  const [ unitsInfo, setUnitsInfo ] = useState<Record<string, ISpec>>();
  const [ filter, setFilter ] = useState<string>('');
  const checkSimulationFactor: boolean = isAdvancedFactor(selectedDataPoint);

  useEffect(() => {
    if (checkSimulationFactor) {
      const selectedSimulationFactor = selectedDataPoint as AdvancedFactor;
      const foundParameter = simulationFactors.find(f => f.id === selectedSimulationFactor.simulationFactor);
      if (foundParameter) {
        setCreateDataPoint(prevFactor => {
          return {
            ...prevFactor,
            dataType: foundParameter.dataType,
            unit: useImperial ? foundParameter.dataPointValue.values[0].imperialStandardValue.typeId : foundParameter.dataPointValue.values[0].industryStandardValue.typeId
          }
        })
      }
    }
  }, [selectedDataPoint.id, checkSimulationFactor, simulationFactors, (selectedDataPoint as AdvancedFactor)?.simulationFactor, useImperial])

  useEffect(() => {
    if (isLoadingUnits) {
      return;
    }
    let newUnits = Object.entries(allUnits);
    newUnits.splice(0,0,[CustomUnitId, {
      name: i18n.t('analysis.dataPoints.units.customSpec'),
      applicableUnits: {}
    }]);
    setUnitsInfo(Object.fromEntries(newUnits));
  }, [allUnits, isLoadingUnits]);

  const onChangeSpecId = useCallback((option: string) => {
    setFilter('');
    setCreateDataPoint( (prevState) => {
      return { ...prevState,
        dataType: option,
        unit: ''
      }});

  }, [setCreateDataPoint]);

  const onChangeUnitId = useCallback((option: string) => {
    setCreateDataPoint( (prevState) => {
      return { ...prevState,
        unit: option
      }});
  }, [setCreateDataPoint]);

  // upon keyboard press
  const onChangeInputSpecId = useCallback( (option) => {
    setFilter(option.currentTarget.value);
    setCreateDataPoint( (prevState) => {
      return { ...prevState,
        dataType: '',
        unit: '',
      }});
  }, [setCreateDataPoint]);

  // used to reset text content and filter upon leaving controls focus
  const onTextLeaveFocus = useCallback( (option) => {
    setFilter('');
  }, []);

  // upon keyboard press
  const onChangeInputCustomSpecId = useCallback( (option) => {
    setCreateDataPoint( (prevState) => {
      return { ...prevState,
        unit: option?.currentTarget?.value ?? prevState.unit
      }});
  }, []);

  const formatUnitOption = (unitId: string) => {
    const spec = findSpec(selectedDataPoint.dataType, unitsInfo);
    return !unitId ? '' :
      spec?.applicableUnits[findUnit(unitId, spec.applicableUnits)]?.symbol ??
      spec?.applicableUnits[findUnit(unitId, spec.applicableUnits)]?.name
  };

  const formatSpecOption = ( specId: string) => {
    if (!specId || !unitsInfo)
      return '';
    return unitsInfo[specId]?.name ?? specId;
  };

  const industryStandardUnit = () => {
    switch (selectedDataPoint.type) {
      case DataPointType.Factor:
      case DataPointType.AdvancedFactor:
        const factorValue = (selectedDataPoint as Factor)?.dataPointValue?.values[0];
        return (useImperial ? factorValue?.imperialStandardValue?.typeId : factorValue?.industryStandardValue?.typeId) ?? selectedDataPoint.unit;
      case DataPointType.Metric:
        return useImperial ? selectedDataPoint.imperialStandardUnitId : selectedDataPoint.industryStandardUnitId;
    }
  }

  if (!selectedDataPoint) return <></>;
  const isDisabledUnitType = (
    selectedDataPoint?.id !== newDataPointId ||
    checkSimulationFactor
  ) ?? false;
  const isDisabledDisplayUnit = !selectedDataPoint.dataType || isReadOnly || selectedDataPoint.isGlobal || checkSimulationFactor;

  return isLoadingUnits || !unitsInfo ? (
    <CardDataLoadingProgress />
  ) : (
    <>
      <FormControl
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '0.5rem',
        }}
      >
        <FormLabel
          variant={formLabelVariants.SIDE}
          sx={{
            alignSelf: 'center',
            p: '0px',
            width: '30%',
          }}
        >
          {`${i18n.t('analysis.dataPoints.labels.unitType')} *`}
        </FormLabel>
        <Dropdown
          stylesheet={unitsDropdownStylesheet(isDisabledUnitType)}
          onChange={onChangeSpecId}
          options={
            unitsInfo
              ? Object.keys(unitsInfo).filter((specId) =>
                  unitsInfo[specId].name.toLowerCase().includes(filter.toLowerCase())
                )
              : []
          }
          formatOption={formatSpecOption}
          value={filter || findSpec(selectedDataPoint.dataType, unitsInfo)?.name || ''}
          typable={true}
          onInputChange={onChangeInputSpecId}
          onBlur={onTextLeaveFocus}
          placeholder={i18n.t('analysis.dataPoints.placeholders.unitType')}
          disabled={isDisabledUnitType}
        />
      </FormControl>
      <FormControl
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '0.5rem',
        }}
      >
        <FormLabel
          variant={formLabelVariants.SIDE}
          sx={{
            alignSelf: 'center',
            p: 0,
            width: '30%',
          }}
        >
          {`${i18n.t('analysis.dataPoints.labels.displayUnit')} *`}
        </FormLabel>
        {hasCustomUnit(selectedDataPoint) ? (
          <Box sx={
            {
              display: 'flex',
              flexDirection: 'column',
              width: '100%'
            }
          }>
            <Box className={'inputWithError'}>
              <TextField
                name="custom-unit-input"
                id="custom-unit-input"
                value={selectedDataPoint.unit}
                placeholder={i18n.t('analysis.dataPoints.placeholders.unitInput')}
                disabled={isReadOnly || selectedDataPoint.isGlobal || checkSimulationFactor}
                onChange={onChangeInputCustomSpecId}
                error={Boolean(isLongerThanAllowed(selectedDataPoint.unit, UNIT_DISPLAY_CUSTOM_CHARACTER_LIMIT))}
                variant={textFieldVariants.STANDARD}
                sx={{textOverflow: 'ellipsis'}}
              />
            </Box>
            <div className={'errorMessageAndIcon'}>
              {isLongerThanAllowed(selectedDataPoint.unit, UNIT_DISPLAY_CUSTOM_CHARACTER_LIMIT) && <ErrorS sx={{...errorIconStyles}}/>}
              {isLongerThanAllowed(selectedDataPoint.unit, UNIT_DISPLAY_CUSTOM_CHARACTER_LIMIT) && (
                <Typography variant={BODY_REGULAR} sx={{...maximumCharactersErrorStyles}}>
                  {`${i18n.t('analysis.ec.ecDefinition.components.maximumCharactersErrorMessage')} ${
                    selectedDataPoint.unit.length - UNIT_DISPLAY_CUSTOM_CHARACTER_LIMIT
                  }`}
                </Typography>
              )}
            </div>
          </Box>
        ) : (
          <Dropdown
            stylesheet={unitsDropdownStylesheet(isDisabledDisplayUnit)}
            onChange={onChangeUnitId}
            options={
              selectedDataPoint.dataType
                ? Object.keys(findSpec(selectedDataPoint.dataType, unitsInfo)?.applicableUnits ?? {})
                : []
            }
            formatOption={formatUnitOption}
            value={selectedDataPoint.isGlobal ? industryStandardUnit() : selectedDataPoint.unit}
            placeholder={
              selectedDataPoint.dataType
                ? i18n.t('analysis.dataPoints.placeholders.unit')
                : i18n.t('analysis.dataPoints.placeholders.disabledUnit')
            }
            disabled={isDisabledDisplayUnit}
          />
        )}
      </FormControl>
      {(
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '0.5rem',
          }}
        >
          <FormLabel
            variant={formLabelVariants.SIDE}
            sx={{
              alignSelf: 'center',
              p: 0,
              width: '100%',
            }}
            disabled={true}
          >
            <InfoS
              style={{ paddingRight: '4px', paddingBottom: '3px' }}
              color="primary"
            />
            {`${i18n.t('analysis.dataPoints.units.unitInformation')}`}
          </FormLabel>
        </Box>
      )}
    </>
  );
}

export default UnitsContainer
