import {
  AdvancedFactorDefinition,
  DataPointsResult,
  FactorValue,
  SimulationFactorDefinition,
} from '../../types/metrics';

export const getAdvancedFactorDefinitionAndValuesFromSimulationFactorId = (
  simulationFactorId: string,
  dataPointsResult: DataPointsResult
): {
  simulationFactorDefinition: SimulationFactorDefinition;
  advancedFactorDefinition: AdvancedFactorDefinition;
  values: FactorValue[];
} => {
  const simulationFactorDefinition = dataPointsResult?.definitions?.simulationFactors.find(
    (x) => x.id === simulationFactorId
  );
  const advancedFactorDefinition = dataPointsResult?.definitions?.advancedFactors.find(
    (x) => x.simulationFactor === simulationFactorId
  );
  const values = dataPointsResult?.factorsData[advancedFactorDefinition?.id];
  return { simulationFactorDefinition, advancedFactorDefinition, values };
};
