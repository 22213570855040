import React from 'react'

const BenchmarksIcon = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>((props, ref) => (
  <svg data-testid='benchmarks-icon' { ...props } ref={ref} style={{ flexShrink: 0, cursor: 'default' }} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_2116_996)">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M13.5 2C13.7761 2 14 1.77614 14 1.5C14 1.22386 13.7761 1 13.5 1C13.2239 1 13 1.22386 13 1.5C13 1.77614 13.2239 2 13.5 2ZM13.5 3C14.3284 3 15 2.32843 15 1.5C15 0.671573 14.3284 0 13.5 0C12.6716 0 12 0.671573 12 1.5C12 2.32843 12.6716 3 13.5 3Z" fill="#666666"/>
      <rect y="7" width="3" height="5" fill="#666666"/>
      <rect x="12" y="4" width="3" height="8" fill="#666666"/>
      <rect y="13" width="16" height="1" fill="#666666"/>
      <rect x="1" y="14" width="1" height="2" fill="#666666"/>
      <rect x="7" y="14" width="1" height="2" fill="#666666"/>
      <rect x="4" y="14" width="1" height="2" fill="#666666"/>
      <rect x="10" y="14" width="1" height="2" fill="#666666"/>
      <rect x="13" y="14" width="1" height="2" fill="#666666"/>
      <rect x="8" y="8" width="3" height="4" fill="#666666"/>
      <rect x="4" y="6" width="3" height="6" fill="#666666"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M9.5 5C9.77614 5 10 4.77614 10 4.5C10 4.22386 9.77614 4 9.5 4C9.22386 4 9 4.22386 9 4.5C9 4.77614 9.22386 5 9.5 5ZM9.5 6C10.3284 6 11 5.32843 11 4.5C11 3.67157 10.3284 3 9.5 3C8.67157 3 8 3.67157 8 4.5C8 5.32843 8.67157 6 9.5 6Z" fill="#666666"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M5.5 2C5.77614 2 6 1.77614 6 1.5C6 1.22386 5.77614 1 5.5 1C5.22386 1 5 1.22386 5 1.5C5 1.77614 5.22386 2 5.5 2ZM5.5 3C6.32843 3 7 2.32843 7 1.5C7 0.671573 6.32843 0 5.5 0C4.67157 0 4 0.671573 4 1.5C4 2.32843 4.67157 3 5.5 3Z" fill="#666666"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.5 5C1.77614 5 2 4.77614 2 4.5C2 4.22386 1.77614 4 1.5 4C1.22386 4 1 4.22386 1 4.5C1 4.77614 1.22386 5 1.5 5ZM1.5 6C2.32843 6 3 5.32843 3 4.5C3 3.67157 2.32843 3 1.5 3C0.671573 3 0 3.67157 0 4.5C0 5.32843 0.671573 6 1.5 6Z" fill="#666666"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M6.93174 1.94873C6.82828 2.27914 6.6136 2.56037 6.33154 2.74858L8.06832 4.05116C8.17179 3.72076 8.38647 3.43952 8.66853 3.25132L6.93174 1.94873ZM10.3315 3.25133C10.6136 3.43954 10.8283 3.72077 10.9317 4.05118L12.6685 2.74859C12.3865 2.56039 12.1718 2.27915 12.0683 1.94875L10.3315 3.25133ZM4.66853 2.74859C4.38647 2.56039 4.17179 2.27915 4.06832 1.94875L2.33154 3.25133C2.6136 3.43954 2.82828 3.72077 2.93174 4.05118L4.66853 2.74859Z" fill="#666666"/>
    </g>
    <defs>
      <clipPath id="clip0_2116_996">
        <rect width="16" height="16" fill="white"/>
      </clipPath>
    </defs>
  </svg>
));

export default BenchmarksIcon;
