import { LayoutHookReturnType } from '../../../types/layout';
import {
  EnumEvaluationStatus,
  FormulaCalculationFunction,
  MetricEvaluationResult,
  UnitSystem
} from '../../../types/metrics';
import { useGetUnitsQuery } from '../../../state/api/data-service-api';
import { useValuesFromDataPointsV2 } from './useValuesFromDataPointsV2';
import { useGetDataPointMapV2 } from './useDataPointsV2';
import { useGetImperialSystem } from '../useCurrentProjectData';
import { FormulaParser } from '../../../dataPoints/services/FormulaParser';
import { sanitizeToken } from '../../../dataPoints/utils/formulaUtils';
import i18n from '../../../i18n';

export const useFormulaEvaluatorV2 = (): LayoutHookReturnType<FormulaCalculationFunction> => {
  let {
    data: dataPointsWithValues,
    isLoading: dataPointsWithValuesLoading,
    isError: dataPointsWithValuesIsError,
    isSuccess: dataPointsWithValuesIsSuccess,
    isFetching: dataPointsWithValuesIsFetching,
  } = useValuesFromDataPointsV2();

  const {
    data: dataPointsMap,
    isLoading: isLoadingDataPointsMap,
    isError: isErrorDataPointsMap,
    isFetching: isFetchingDataPointsMap,
    isSuccess: isSuccessDataPointsMap,
  } = useGetDataPointMapV2();

  const {
    data: units,
    isLoading: isLoadingUnits,
    isSuccess: isSuccessUnits,
    isError: isErrorUnits,
    isFetching: isFetchingUnits,
  } = useGetUnitsQuery();

  const {
    data: useImperial,
    isLoading: isLoadingImperialSystem,
    isSuccess: isSuccessImperialSystem,
    isFetching: isFetchingImperialSystem,
    isError: isErrorImperialSystem,
  } = useGetImperialSystem();

  const isLoading: boolean =
    dataPointsWithValuesLoading ||
    isLoadingDataPointsMap ||
    isLoadingUnits ||
    isLoadingImperialSystem;
  let isError: boolean =
    dataPointsWithValuesIsError || isErrorDataPointsMap || isErrorUnits || isErrorImperialSystem;
  const isSuccess: boolean =
    dataPointsWithValuesIsSuccess &&
    isSuccessDataPointsMap &&
    isSuccessUnits &&
    isSuccessImperialSystem;
  const isFetching: boolean =
    dataPointsWithValuesIsFetching ||
    isFetchingDataPointsMap ||
    isFetchingUnits ||
    isFetchingImperialSystem;
  let formulaEvaluator: FormulaCalculationFunction = null;

  if (isSuccess && dataPointsWithValues && dataPointsMap) {
    const formulaParser = new FormulaParser(dataPointsMap, units, useImperial);
    formulaEvaluator = (
      formula: string,
      formulaOwnerMetricId: string,
      useImperial: boolean = false,
      factorOverrides: Record<string, number> = {},
      extractMetricArguments: boolean = false
    ): MetricEvaluationResult => {
      try {
        const unitSystemKey = useImperial ? UnitSystem.Imperial : UnitSystem.Metric;
        const evaluatedParameters = dataPointsWithValues.has(unitSystemKey)
          ? dataPointsWithValues.get(unitSystemKey)
          : dataPointsWithValues.get(UnitSystem.Metric); //default to metric
        // apply factor overrides
        const factorOverrideEntities = Object.entries(factorOverrides);
        if (factorOverrideEntities.length) {
          factorOverrideEntities.forEach(([factorId, value]) => {
            evaluatedParameters.set(sanitizeToken(factorId), value);
          });
        }
        return formulaParser.calculateFormula(formula, formulaOwnerMetricId, evaluatedParameters, extractMetricArguments);
      } catch (e) {
        return {
          result: null,
          arguments: null,
          isError: true,
          evaluationStatus: EnumEvaluationStatus.GeneralError,
          evaluationError: i18n.t('analysis.dataPoints.metrics.errorFormula')
        };
      }
    };
  }

  return {
    data: formulaEvaluator,
    isFetching,
    isSuccess,
    isError,
    isLoading,
  };
};
