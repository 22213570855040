import React from 'react';
import DataPointsIconsEnum from '../utils/iconEnum';
import { iconMap } from '../utils/iconMap';

interface DataPointsIconProps {
  icon: DataPointsIconsEnum;
}

const DataPointsIcon: React.FC<DataPointsIconProps> = ({ icon }) => {
  const IconComponent = iconMap[icon];

  if (!IconComponent) {
    return null;
  }

  return <IconComponent/>;
}

export default DataPointsIcon;
