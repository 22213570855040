import React, { ChangeEvent, Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'
import { AdvancedFactor, Benchmark, Factor, Metric, ParametersModalType, ParametersTypesEnum } from '../../../../types/metrics'
import MenuItem, { menuItemRole, useSelected } from '@weave-mui/menu-item';
import Search from '../../../../shared/Search';
import Box from '@weave-mui/box';
import MenuList from '@weave-mui/menu-list';
import i18n from '../../../../i18n';
import TextField from '@weave-mui/text-field';
import { DISPLAY_NAME_INPUT_CHARACTERS_LIMIT } from '../../../utils/dataPointsUtils';
import Typography from '@weave-mui/typography';
import { BENCHMARK_NAME_LIMIT } from '../../../utils/benchmarksUtils';
import { ListItemIcon } from '@mui/material';
import DataPointsIconsEnum from '../../../../shared/DataPointsIcons/utils/iconEnum';
import DataPointsIcon from '../../../../shared/DataPointsIcons/components/DataPointsIcon';
import { isAdvancedFactor } from '../../../utils/factorsUtils';
import EllipsisContent from '../../../../shared/EllipsisContent';

interface DataPointsListProps {
  dataPoints: (Metric | Factor | Benchmark)[],
  dataType: ParametersModalType;
  onDataPointSelect: (dataPoint: Metric | Factor | Benchmark) => void;
  shouldRename: boolean;
  selectedDataPoint: Metric | Factor | Benchmark;
  onRenameSubmit: (newName: string) => void;
  setShouldRename: Dispatch<SetStateAction<boolean>>;
}

const filterMetrics = (parameters: (Metric | Factor | Benchmark)[], query: string): (Metric | Factor | Benchmark)[] => {
  if (!query) {
    return parameters;
  }
  return parameters.filter((m) =>
    m.displayName.toLocaleLowerCase().includes(query.toLocaleLowerCase())
  );
}

const DataPointsList: React.FC<DataPointsListProps> = ({
  dataPoints, onDataPointSelect, shouldRename, selectedDataPoint, onRenameSubmit, setShouldRename, dataType
}) => {
  const [renameValue, setRenameValue] = useState<string>('');
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [filteredDataPoints, setFilteredDataPoints] = useState<(Metric | Factor | Benchmark)[]>([]);
  const { selectItem, handleMenuItemClick } = useSelected({ defaultId: selectedDataPoint?.id, multiple: false, unSelected: true });
  const { PRESENTATION } = menuItemRole;

  useEffect(() => {
    setFilteredDataPoints(filterMetrics(dataPoints, searchQuery));
  }, [dataPoints, searchQuery]);

  useEffect(() => {
    if (selectedDataPoint) {
      setRenameValue(selectedDataPoint.displayName);
    }
  }, [selectedDataPoint]);

  const submitHandler = useCallback(
    (e) => {
      e.preventDefault();
      if (renameValue.length <= 0) return;
      if (renameValue === selectedDataPoint.displayName) {
        setShouldRename(false);
        return;
      }
      onRenameSubmit(renameValue);
    },
    [renameValue, selectedDataPoint]
  );

  const onEnterPress = useCallback((e: React.KeyboardEvent) => {
    e.stopPropagation();
    const keyPressed = e.key;
    if (keyPressed !== 'Enter') return;
    submitHandler(e);
  }, [submitHandler]);

  const searchHandler = (query: string) => {
    setSearchQuery(query);
  };

  const handleRenameChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const newName = e.target.value;

    const nameLimit = dataType === ParametersTypesEnum.BENCHMARKS
      ? BENCHMARK_NAME_LIMIT
      : DISPLAY_NAME_INPUT_CHARACTERS_LIMIT;

    if (newName.length <= nameLimit) {
      setRenameValue(newName);
    } else {
      setRenameValue(newName.substring(0, nameLimit))
    }
  }, [setRenameValue, dataType]);

  const clickCb = useCallback((e: any, dataPoint: Metric | Factor | Benchmark) => {
    e.stopPropagation();
    if (dataPoint.id === selectedDataPoint?.id) return;

    onDataPointSelect(dataPoint);
    handleMenuItemClick(e, dataPoint.id);
  }, [handleMenuItemClick, selectedDataPoint?.id]);

  const isItemSelected = (parameter: Metric | Factor | Benchmark | AdvancedFactor): boolean => selectedDataPoint?.id === parameter.id;

  const generateListTitle = () => {
    switch (dataType) {
      case ParametersTypesEnum.METRICS:
        return i18n.t('analysis.dataPoints.metrics.myMetrics');
      case ParametersTypesEnum.FACTORS:
        return i18n.t('analysis.dataPoints.factors.myFactors');
      case ParametersTypesEnum.BENCHMARKS:
        return i18n.t('analysis.dataPoints.benchmarks.myBenchmarks');
    }
  }

  const generateListContent = useCallback((isGlobal: boolean = false): (Metric | Factor | Benchmark)[] => {
    if (dataType === ParametersTypesEnum.BENCHMARKS) {
      return filteredDataPoints;
    } else {
      return filteredDataPoints.filter(dp => (dp as Metric | Factor).isGlobal === isGlobal);
    }
  }, [filteredDataPoints, dataType]);

  const getIconByParameter = useCallback((parameter: Metric | Factor | Benchmark): DataPointsIconsEnum  => {
    const factor = parameter as Factor | AdvancedFactor;
        return isAdvancedFactor(factor)
          ? DataPointsIconsEnum.AdvancedFactors
          : DataPointsIconsEnum.BasicFactors;
  }, []);


  return (
    <Box sx={{
      height: '100%',
      backgroundColor: 'white',
      flex: '1',
      overflow: 'hidden'
    }}>
      <Search onSearch={searchHandler} />
      <Box sx={{
        overflow: 'hidden',
        height: 'calc(100% - 3rem)',
        color: 'red',
        '& ul div:first-of-type li:first-of-type': {
          color: 'black',
          fontSize: '12px'
        },
        '$ li > div': {
          width: '200px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap'
        }
      }}>
        <Box sx={{
          height: '100%',
          overflowY: 'auto'
        }}>
          {dataType !== ParametersTypesEnum.BENCHMARKS &&
            (
              <MenuList sx={{ p: '0px !important' }} divider dense>
                <MenuItem
                  disabled
                  role={PRESENTATION}
                  sx={{ mt: '0.75rem' }}
                >
                  <Typography style={{ textTransform: 'none' }}>{i18n.t('analysis.dataPoints.getStartedSection')}</Typography>
                </MenuItem>
                {generateListContent(true)
                  .map((parameter) => (
                    <MenuItem
                      id={parameter.id}
                      key={parameter.id}
                      sx={{
                        textTransform: 'none',
                        '& .MuiMenuItemWeave-content': {
                          display: 'flex !important',
                          alignItems: 'center',
                          gap: '1px',
                          overflow: 'hidden'
                        }
                      }}
                      selected={isItemSelected(parameter)}
                      onClick={(e) => clickCb(e, parameter)}
                    >
                      {shouldRename && isItemSelected(parameter) ? (
                        <TextField
                          variant="box"
                          value={renameValue}
                          onChange={handleRenameChange}
                          onBlur={submitHandler}
                          onKeyDown={onEnterPress}
                          autoFocus
                        />
                      ) : (
                        <>
                        {
                          dataType === ParametersTypesEnum.FACTORS && (
                            <ListItemIcon
                              sx={
                                {
                                  '&.MuiListItemIcon-root': {
                                    minWidth: '20px !important'
                                  }
                                }
                              }
                            >
                              <DataPointsIcon icon={getIconByParameter(parameter)}/>
                            </ListItemIcon>
                          )
                        }
                          <EllipsisContent content={parameter.displayName} />
                        </>
                      )}
                    </MenuItem>
                  ))}
              </MenuList>
            )
          }
          <MenuList sx={{ p: '0px !important' }} dense>
            <MenuItem
              disabled
              role={PRESENTATION}
              sx={dataType === ParametersTypesEnum.BENCHMARKS && { mt: '0.75rem' }}
            >
              <Typography style={{ textTransform: 'none' }}>
                {generateListTitle()}
              </Typography>
            </MenuItem>
            {generateListContent()
              .map((parameter) => (
                <MenuItem
                  id={parameter.id}
                  key={parameter.id}
                  sx={{
                    textTransform: 'none',
                    '& .MuiMenuItemWeave-content': {
                      display: 'flex !important',
                      alignItems: 'center',
                      gap: '1px',
                      overflow: 'hidden'
                    }
                  }}
                  selected={isItemSelected(parameter)}
                  onClick={(e) => clickCb(e, parameter)}
                >
                  {shouldRename && isItemSelected(parameter) ? (
                    <TextField
                      variant="box"
                      value={renameValue}
                      onChange={handleRenameChange}
                      onBlur={submitHandler}
                      onKeyDown={onEnterPress}
                      autoFocus
                    />
                  ) : (
                    <>
                      {
                        dataType === ParametersTypesEnum.FACTORS && (
                          <ListItemIcon
                            sx={
                              {
                                '&.MuiListItemIcon-root': {
                                  minWidth: '20px !important'
                                }
                              }
                            }
                          >
                            <DataPointsIcon icon={getIconByParameter(parameter)}/>
                          </ListItemIcon>
                        )
                      }
                      <EllipsisContent content={parameter.displayName} />
                    </>
                  )}
                </MenuItem>
              ))}
          </MenuList>
        </Box>
      </Box>
    </Box>
  )
}

export default DataPointsList;
