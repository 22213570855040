import React, { Fragment, useCallback, useState } from 'react';
import Box from '@weave-mui/box';
import { ListItemIcon } from '@mui/material';
import Typography, { typographyVariants } from '@weave-mui/typography';
import { SideNavigationOption } from './SideMenu';
import Tooltip, { tooltipPlacement, tooltipSize } from '@weave-mui/tooltip';

interface TooltipMenuIconProps {
  navigationOption: SideNavigationOption;
}

const SideMenuIcon: React.FC<TooltipMenuIconProps> = ({ navigationOption }) => {
  return (
    <ListItemIcon 
      data-testid={`menu-item-icon-${navigationOption.modalType}`}
      sx={
        { 
          '&.MuiListItemIcon-root': {
            minWidth: '20px'
          }
        }
      }
    >
      {navigationOption.icon}
    </ListItemIcon>
  )
}

export default SideMenuIcon;
