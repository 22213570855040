import { DataPointType, Factor, FactorDefinition } from '../metrics';

/*
* API OBJECTS USED TO CALL DATA SERVICE API.
* DO NOT ADD INTERNAL LOGIC TO THIS OBJECTS
* */

export interface CreateFactorResponse {
  id: string,
  name: string,
  description: string,
  dataType: string,
  unit: string,
  values: {
    name: string;
    value: number;
  }[];
  isImperial: boolean;
}

export interface OverrideFactorResponse {
  id: string;
  values?: {
    name: string;
    value: number;
    imperialStandardValue?: {
      value: number;
      typeId: string;
    },
    industryStandardValue?: {
      value: number;
      typeId: string;
    }
  }[];
}

export const toFactorDefinition = (factor: Factor): FactorDefinition => {
  return {
    dataType: factor.dataType,
    description: factor.description,
    displayName: factor.name,
    id: factor.id,
    isGlobal: false,
    unit: factor.unit,
    name: factor.name,
    type: factor.type,
  }
}

export const toFactor = (response: CreateFactorResponse): Factor => {
  return {
    dataPointValue: {
      values: [...response.values],
    },
    dataType: response.dataType,
    description: response.description,
    displayName: response.name,
    id: response.id,
    isGlobal: false,
    unit: response.unit,
    name: response.name,
    type: DataPointType.Factor,
  };
};
